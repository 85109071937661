<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="12" md="3">
        <v-switch v-model="sendSms.value" @change="updateSettingBool(sendSms)" :label="sendSms.description"></v-switch>
      </v-col>
      <v-col cols="12" md="3">
        <v-switch v-model="licenseSms.value" @change="updateSettingBool(licenseSms)" :label="licenseSms.description"></v-switch>
      </v-col>
      <v-col cols="12" md="3">
        <v-text-field v-model="typeSms.value" :label="typeSms.description"></v-text-field>
      </v-col>
      <v-col cols="12" md="1">
        <v-btn color="primary" @click="updateSettingText(typeSms)">Guardar</v-btn>
      </v-col>
    </v-row>
    <!--Nos-->
    <v-row align="center" justify="center">
      <v-col cols="12" md="10">
        <h3>Nos</h3>
      </v-col>
      <v-col cols="12" md="9">
        <v-text-field v-model="nos_user_name.value" :label="nos_user_name.description"></v-text-field>
      </v-col>
      <v-col cols="12" md="1">
        <v-btn color="primary" @click="updateSettingText(nos_user_name)">Guardar</v-btn>
      </v-col>
      <v-col cols="12" md="9">
        <v-text-field v-model="nos_pasword.value" :label="nos_pasword.description"></v-text-field>
      </v-col>
      <v-col cols="12" md="1">
        <v-btn color="primary" @click="updateSettingText(nos_pasword)">Guardar</v-btn>
      </v-col>
    </v-row>
    <!--Pushbullet-->
    <v-row align="center" justify="center">
      <v-col cols="12" md="10">
        <h3>Pushbullet</h3>
      </v-col>
      <v-col cols="12" md="9">
        <v-text-field v-model="pushbullet_access_token.value" :label="pushbullet_access_token.description"></v-text-field>
      </v-col>
      <v-col cols="12" md="1">
        <v-btn color="primary" @click="updateSettingText(pushbullet_access_token)">Guardar</v-btn>
      </v-col>
      <v-col cols="12" md="9">
        <v-text-field v-model="pushbullet_device_id.value" :label="pushbullet_device_id.description"></v-text-field>
      </v-col>
      <v-col cols="12" md="1">
        <v-btn color="primary" @click="updateSettingText(pushbullet_device_id)">Guardar</v-btn>
      </v-col>
    </v-row>
    <!--Bulkgate-->
    <v-row align="center" justify="center">
      <v-col cols="12" md="10">
        <h3>Bulkgate</h3>
      </v-col>
      <v-col cols="12" md="10">
        <v-switch v-model="unicode.value" @change="updateSettingBool(unicode)" :label="unicode.description"></v-switch>
      </v-col>
      <v-col cols="12" md="9">
        <v-text-field v-model="bulkgate_application_id.value" :label="bulkgate_application_id.description"></v-text-field>
      </v-col>
      <v-col cols="12" md="1">
        <v-btn color="primary" @click="updateSettingText(bulkgate_application_id)">Guardar</v-btn>
      </v-col>
      <v-col cols="12" md="9">
        <v-text-field v-model="bulkgate_application_token.value" :label="bulkgate_application_token.description"></v-text-field>
      </v-col>
      <v-col cols="12" md="1">
        <v-btn color="primary" @click="updateSettingText(bulkgate_application_token)">Guardar</v-btn>
      </v-col>
      <v-col cols="12" md="9">
        <v-text-field v-model="bulkgate_sender_id.value" :label="bulkgate_sender_id.description"></v-text-field>
      </v-col>
      <v-col cols="12" md="1">
        <v-btn color="primary" @click="updateSettingText(bulkgate_sender_id)">Guardar</v-btn>
      </v-col>
      <v-col cols="12" md="9">
        <v-text-field v-model="bulkgate_sender_id_value.value" :label="bulkgate_sender_id_value.description"></v-text-field>
      </v-col>
      <v-col cols="12" md="1">
        <v-btn color="primary" @click="updateSettingText(bulkgate_sender_id_value)">Guardar</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ServiceSettingsSms from "@/services/ServiceSettingsSms";
export default {
  name: "Settings-Edit",
  data: () => ({
    valid: true,
    loading: false,

    sendSms: {},
    licenseSms: {},
    typeSms: {},
    nos_user_name: {},
    nos_pasword: {},
    pushbullet_access_token: {},
    pushbullet_device_id: {},
    bulkgate_application_id: {},
    bulkgate_application_token: {},
    unicode: {},
    bulkgate_sender_id: {},
    bulkgate_sender_id_value: {}
  }),
  methods: {
    async updateSettingBool(setting) {
      try {
        this.loading = true;
        await ServiceSettingsSms.update({name: setting.name,value: setting.value})

        this.$store.dispatch("setSnackbar", {});

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    async updateSettingText(setting) {
      try {
        this.loading = true;

        (await ServiceSettingsSms.update({name: setting.name, value: setting.value})).data;

        this.$store.dispatch("setSnackbar", {});

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
  },
  async mounted() {
    try {
      if (this.$store.state.user.role === 'infordio') {
        this.loading = true;

        this.sendSms = (await ServiceSettingsSms.show('sendSms')).data;
        this.licenseSms = (await ServiceSettingsSms.show('licenseSms')).data;
        this.typeSms = (await ServiceSettingsSms.show('typeSms')).data;
        this.nos_user_name = (await ServiceSettingsSms.show('nos_user_name')).data;
        this.nos_pasword = (await ServiceSettingsSms.show('nos_pasword')).data;
        this.pushbullet_access_token = (await ServiceSettingsSms.show('pushbullet_access_token')).data;
        this.pushbullet_device_id = (await ServiceSettingsSms.show('pushbullet_device_id')).data;
        this.bulkgate_application_id = (await ServiceSettingsSms.show('bulkgate_application_id')).data;
        this.bulkgate_application_token = (await ServiceSettingsSms.show('bulkgate_application_token')).data;
        this.unicode = (await ServiceSettingsSms.show('unicode')).data;
        this.bulkgate_sender_id = (await ServiceSettingsSms.show('bulkgate_sender_id')).data;
        this.bulkgate_sender_id_value = (await ServiceSettingsSms.show('bulkgate_sender_id')).data;

        if (this.sendSms.value == 1) {
          this.sendSms.value = true
        } else {
          this.sendSms.value = false
        }

        if (this.licenseSms.value == 1) {
          this.licenseSms.value = true
        } else {
          this.licenseSms.value = false
        }

        if (this.unicode.value == 1) {
          this.unicode.value = true
        } else {
          this.unicode.value = false
        }

        this.loading = false;
      } else {
        this.$router.push(this.$route.query.redirect || "home");
      }

    } catch (error) {
      this.loading = false;
      this.$store.dispatch("setSnackbar", {
        color: "error",
        text: error
      });
    }
  }
};
</script>
<style></style>
