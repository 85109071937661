<template>
  <v-container v-if="$store.state.user.articles_invoiced_module === true">
    <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false" class="d-md-none">
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark large fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark large color="green" @click.stop="filterDialog = true">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-speed-dial>
    <receipts @handleReceipt="handleReceipt" ref="receipts" />
    <receiptsInvoice ref="receiptsinvoice" />
    <v-row>
      <v-col cols="1" class="d-none d-md-block"></v-col>
      <v-col cols="4" class="d-none d-md-block">
        <v-text-field prepend-icon="mdi-account-tie" label="Cliente" clearable v-model="client_select"></v-text-field>
      </v-col>
      <v-col cols="3" class="d-none d-md-block">
        <v-dialog ref="toggleDataInicio" v-model="toggleDataInicio" :return-value.sync="data_inicio_select"
          persistent width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field clearable v-model="data_inicio_select" label="Data Inicio (desde)"
              prepend-icon="mdi-clock-outline" v-on="on"></v-text-field>
          </template>
          <v-date-picker :max="data_fim_select" locale="pt-pt" v-model="data_inicio_select" scrollable>
            <v-btn text color="primary" @click="toggleDataInicio = false">Cancelar</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="updateDashboardDateInicio(data_inicio_select)">OK</v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="3" class="d-none d-md-block">
        <v-dialog ref="toggleDataFim" v-model="toggleDataFim" :return-value.sync="data_fim_select"
          persistent width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field clearable v-model="data_fim_select" label="Data Fim (até)"
              prepend-icon="mdi-clock-outline" v-on="on"></v-text-field>
          </template>
          <v-date-picker :min="data_inicio_select" locale="pt-pt" v-model="data_fim_select" scrollable>
            <v-btn text color="primary" @click="toggleDataFim = false">Cancelar</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="updateDashboardDateFim(data_fim_select)">OK</v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="1" class="d-none d-md-block"></v-col>
      <v-col cols="3" class="d-none d-md-block">
        <v-text-field prepend-icon="mdi-clipboard-list" @change="Filter()" v-model="number_invoice" clearable label="Número da fatura"></v-text-field>
      </v-col>
      <v-col cols="3" class="d-none d-md-block">
        <v-autocomplete prepend-icon="mdi-clipboard-list" @change="Filter()" label="Método de pagamento" v-model="payment_method" :items="payment_methods" item-text="text" item-value="method_abbreviation" clearable></v-autocomplete>
      </v-col>
      <v-col cols="2" class="d-none d-md-block"></v-col>
    </v-row>
    <v-row>
      <v-col cols="1" class="d-none d-md-block"></v-col>
      <v-col cols="8" class="d-none d-md-block">
        <v-radio-group v-model="pay" column @change="Filter()" row>
          <v-radio v-for="item in payArray" :key="item.value" :label="item.text" :value="item.value"></v-radio>
        </v-radio-group>
      </v-col>
      <v-col cols="12" md="2" class="text-right">
        <h1>Total: {{ total_faturado }} €</h1>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" md="10" class="text-right">
        <!--<v-btn text color="primary" outlined @click="openReceipts()">Criar recibo</v-btn>-->
      </v-col>
      <v-col cols="12" md="10">
        <v-data-table infinite-scroll-disabled="busy" v-infinite-scroll="getUsers"
          :headers="headers" :items="users" :loading="loading" hide-default-footer
          :items-per-page="users.length + 10" item-selectable="pay" class="mTable" item-value="id" v-model="selectedArticles">

          <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>

          <template v-slot:[`item.data-table-expand`]="{ item }">
            <v-icon @click="linesInvoices(item)" color="blue">mdi-eye</v-icon>
          </template>

          <template v-slot:[`item.payment_methods`]="{ item }">
            {{paymentMethodDoc(item.payment_methods)}}
          </template>

          <template v-slot:[`item.type_document`]="{ item }">
            {{typeDoc(item.type_document)}}
          </template>

          <template v-slot:[`item.date`]="{ item }">
            {{ formatDate(item.date) }}
          </template>

          <template v-slot:[`item.price`]="{ item }">
            {{ item.price }} €
          </template>

          <template v-slot:[`item.pay`]="{ item }">
            <span v-if="item.pay === true"><v-btn color="success">Paga</v-btn></span>
            <span v-if="item.pay === false"><v-btn color="error">Não paga</v-btn></span>
          </template>

          <template v-slot:[`item.action`]="{ item }">


              <v-tooltip top v-if="item.base64pdf != null && item.base64pdf != '' && $store.state.configerp.configuracoeserpestado == true">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0">
                    <v-icon v-on="on" color="teal" @click="downloadInvoice(item)">mdi-download</v-icon>
                  </v-btn>
                </template>
                <span>Download</span>
              </v-tooltip>

              <v-tooltip top v-if="$store.state.configerp.configuracoeserpestado == true && item.number_invoice != 1 && item.base64pdf === null || item.base64pdf === ''">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0">
                    <v-icon v-on="on" color="teal" @click="createBase64Pdf(item)">mdi-file-pdf-box</v-icon>
                  </v-btn>
                </template>
                <span>Criar fatura pdf</span>
              </v-tooltip>

              <v-tooltip top v-if="progressacriarfaturapdf === true && progressacriarfaturapdfid === item.id">
                <template v-slot:activator="{ on }">
                  <v-progress-linear v-on="on" class="mt-2" color="primary" indeterminate rounded height="10"></v-progress-linear>
                </template>
              </v-tooltip>

              <v-tooltip top v-if="item.base64pdf != null && item.base64pdf != '' && $store.state.configerp.configuracoeserpestado == true">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0">
                    <v-icon v-on="on" color="primary" @click="openDatesPdfEmail(item)">mdi-email</v-icon>
                    <v-badge v-if="item.InvoiceLogEmails.filter(email => email.invite_success).length != 0" invite_success color="primary" :content="item.InvoiceLogEmails.filter(email => email.invite_success).length" position="top right" class="ma-0 pa-0"></v-badge>
                  </v-btn>
                </template>
                <span>Datas de envio do documento por email</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }" v-if="item.ReceiptLines.length != 0">
                  <v-btn icon class="mx-0">
                    <v-icon v-on="on" color="purple" @click="openReceiptsInvoice(item)">mdi-receipt</v-icon>
                  </v-btn>
                </template>
                <span>Recibos</span>
              </v-tooltip>

          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="invoicelinesDialog" max-width="1200px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Linhas da fatura</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-col cols="12">
            <v-data-table :headers="headersLines" :items="linesinvoices" hide-default-footer
            class="mTable full-width" :items-per-page="linesinvoices.length + 10">

            <template v-slot:[`item.price`]="{ item }">
              {{ item.price }} €
            </template>

          </v-data-table>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="filterDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Filtros :</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="filterDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-dialog ref="toggleDataInicio" v-model="toggleDataInicio" :return-value.sync="data_inicio_select"
                  persistent width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field clearable v-model="data_inicio_select" label="Data Inicio (desde)"
                      prepend-icon="mdi-clock-outline" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker :max="data_fim_select" locale="pt-pt" v-model="data_inicio_select" scrollable>
                    <v-btn text color="primary" @click="toggleDataInicio = false">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="updateDashboardDateInicio(data_inicio_select)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-dialog ref="toggleDataFim" v-model="toggleDataFim" :return-value.sync="data_fim_select"
                  persistent width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field clearable v-model="data_fim_select" label="Data Fim (até)"
                      prepend-icon="mdi-clock-outline" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker :min="data_inicio_select" locale="pt-pt" v-model="data_fim_select" scrollable>
                    <v-btn text color="primary" @click="toggleDataFim = false">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="updateDashboardDateFim(data_fim_select)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field prepend-icon="mdi-clipboard-list" v-model="number_invoice" clearable label="Número da fatura"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete ref="cbClient" :label="clientTypeP" prepend-icon="mdi-account-tie"
                  :loading="loadingClients" v-debounce="newVal => myFn(newVal, 'v-text-field')" v-model="client_select"
                  :items="clients" item-text="name" item-value="id"
                  clearable :return-object="false"
                  hint="Nome, NIF ou Tlf." persistent-hint> <template v-slot:progress>
                    <span class="custom-loader">
                      <v-icon light>mdi-cached</v-icon>
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete prepend-icon="mdi-clipboard-list" label="Método de pagamento" v-model="payment_method" :items="payment_methods" item-text="text" item-value="method_abbreviation" clearable></v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6">
                <v-radio-group v-model="pay" column>
                  <v-radio v-for="item in payArray" :key="item.value" :label="item.text" :value="item.value"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="blue darken-1" text @click="Filter()">Procurar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="inviteEmailPdfDialog" max-width="1200px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Datas de envio do documento por email</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
            <v-col cols="12">
              <v-data-table :headers="headersPdfEmail" :items="dataspdfemail" hide-default-footer
              class="mTable full-width" :items-per-page="dataspdfemail.length + 10">

              <template v-slot:[`item.date`]="{ item }">
                {{ formatDate(item.date) }}
              </template>

              <template v-slot:[`item.hour`]="{ item }">
                {{ formatTime(item.date) }}
              </template>

              <template v-slot:[`item.invite_success`]="{ item }">
                <v-icon v-if="item.invite_success" color="green darken-2">mdi-check</v-icon>
                <v-icon v-else color="red darken-2">mdi-close</v-icon>
              </template>

            </v-data-table>
            </v-col>
            <v-col cols="12" class="text-right">
              <v-btn text color="primary" outlined @click="invitePdfEmail()">Enviar documento por email</v-btn>
            </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";
import ServiceInvoices from "@/services/ServiceInvoices";
import ServiceInvoiceLogEmails from "@/services/ServiceInvoiceLogEmails";
import ServiceClients from '@/services/ServiceClients'
import ServiceTypeevents from "@/services/ServiceTypeevents";
import Receipts from "@/components/receipt/Receipts.vue";
import ReceiptsInvoice from "@/components/receipt/ReceiptsInvoice.vue";
import ServiceDocumentos from "@/serviceGgest/ServiceDocumentos";
import ServiceLogin from "@/serviceGgest/ServiceLogin";
import _ from "lodash";
export default {
  name: "List-Invoices",
  directives: {
    infiniteScroll
  },
  components: {
    Receipts,
    ReceiptsInvoice
  },
  data() {
    return {
      clientTypeP: '',
      toggleDataInicio: false,
      toggleDataFim: false,
      loadingClients: false,
      filterDialog: false,
      invoicelinesDialog: false,
      inviteEmailPdfDialog: false,
      fab: false,
      valid: true,
      page: 1,
      pay: 2,
      payArray: [
        { text: "Ver todas", value: 2 },
        { text: "Ver apenas pagas", value: 0 },
        { text: "Ver apenas por pagar", value: 1 }
      ],
      headers: [
        { text: 'Linhas fatura', value: 'data-table-expand', sortable: true },
        { text: "Número fatura", value: "number_invoice", sortable: true },
        { text: "Tipo documento", value: "type_document", sortable: true },
        { text: "Cliente", value: "Client.name", sortable: true },
        { text: "Método de pagamento", value: "payment_methods", sortable: true },
        { text: "Data da faturação", value: "date", sortable: true },
        { text: "Preço", value: "price", sortable: true },
        { text: "Observações", value: "observations", sortable: true },
        { text: "", value: "pay", sortable: true },
        { text: "Ações", value: "action", sortable: true, align: "center" },
      ],
      headersLines: [
        { text: 'Referência', value: 'Typeevent.reference', sortable: true },
        { text: "Tipo de serviço", sortable: true, value: "Typeevent.name" },
        { text: "Plano", sortable: true, value: "Plan.name" },
        { text: 'Descrição', value: 'description', sortable: true },
        { text: 'Qnt', value: 'quantity', sortable: true },
        { text: 'Preço', value: 'price', sortable: true },
      ],
      headersPdfEmail: [
        { text: 'Documento', value: 'Invoice.number_invoice', sortable: true },
        { text: 'Data', value: 'date', sortable: true },
        { text: 'Hora', value: 'hour', sortable: true },
        { text: 'Enviou', value: 'invite_success', sortable: true },
      ],
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      },
      users: [],
      invoices: [],
      linesinvoices: [],
      busy: false,
      loading: true,
      payment_methods: [],
      total_faturado: 0,
      clients: [],
      type_documents: [],
      itempdfemail: {},
      dataspdfemail: [],
      selectedArticles: [],
      erpposto: 1,
      progressacriarfaturapdf: false,
      progressacriarfaturapdfid: null,
      // pesquisas
      client_select: null,
      data_inicio_select: null,
      data_fim_select: null,
      number_invoice: null,
      payment_method: null,
      typeevent: null,
    };
  },
  watch: {
    selectedArticles(val) {
      this.selectAll = val.length === this.users.length;
    },
    client_select(newName) {
      clearTimeout(this.debounceTimeout);

      if (newName.trim() === "") {
        this.Filter();
      } else {
        this.debounceTimeout = setTimeout(() => {
          this.page = 1;
          this.users = [];
          this.getUsers();
          this.total_faturado = 0;
          this.totalFaturado();
        }, 500);
      }
    },
  },
  methods: {
    formatTime(date) {
        const datee = new Date(date);
        const hours = String(datee.getHours()).padStart(2, '0');
        const minutes = String(datee.getMinutes()).padStart(2, '0');

        return `${hours}:${minutes}`;
    },
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    async getUsers() {
      this.busy = true;
      this.loading = true;
      const response = await ServiceInvoices.listinvoices(
        this.page,
        this.data_inicio_select,
        this.data_fim_select,
        null,
        this.payment_method,
        this.number_invoice,
        this.pay,
        this.client_select
      );

      this.users = this.users.concat(response.data);

      this.page++;
      this.busy = false;
      if (response.data.length == 0) {
        this.busy = true;
      }
      this.loading = false;


    },
    async totalFaturado () {
      this.invoices = (await ServiceInvoices.listtotalfaturado(this.data_inicio_select, this.data_fim_select, null, this.payment_method, this.number_invoice, this.pay, this.client_select)).data
      for (var i = 0; i < this.invoices.length; i++) {
        this.total_faturado = (parseFloat(this.total_faturado) + parseFloat(this.invoices[i].price)).toFixed(2);
      }
    },
    async downloadInvoice (item) {
      if (item.base64pdf == null || item.base64pdf == '') {
        alert("Documento sem pdf")
      } else {
        try {
          // Converter base64 em pdf
          const byteCharacters = atob(item.base64pdf);
          const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/pdf' });

          // Criar uma URL temporária e disparar o download
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = item.number_invoice;
          link.click();

          // Limpar a URL temporário
          URL.revokeObjectURL(link.href);
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    async openDatesPdfEmail (item) {
      this.itempdfemail = item
      this.inviteEmailPdfDialog = true
      this.dataspdfemail = (await ServiceInvoiceLogEmails.list(item.id)).data;
    },
    async invitePdfEmail () {

        const enviarpfdemail = await ServiceInvoices.invitePdfEmail(this.itempdfemail.id, this.itempdfemail.Client, this.itempdfemail.number_invoice, 1);
        if (enviarpfdemail.status === 200) {
          this.dataspdfemail = (await ServiceInvoiceLogEmails.list(this.itempdfemail.id)).data;
          this.$store.dispatch("setSnackbar", {});
        } else {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }

    },
    openReceipts() {
      if (this.selectedArticles != '') {
        this.$refs.receipts.open(null, this.selectedArticles, 2);
      }
    },
    openReceiptsInvoice(item) {
      this.$refs.receiptsinvoice.open(item);
    },
    async handleReceipt() {
      //reload porque não está a atualizar
      this.Filter()
      this.selectedArticles = []
      window.location.reload();
    },
    async createBase64Pdf (item) {
      try {
        this.progressacriarfaturapdf = true
        this.progressacriarfaturapdfid = item.id
        let codigoartigo = item.number_invoice.split(' ')[0]
        let numeroartigo = item.number_invoice.split('/')[1].split(',')[0]
        let serieartigo = item.number_invoice.split(' ')[1].split('/')[0]
        let anoartigo = item.number_invoice.split(',')[1]

        const response = await ServiceLogin.login(process.env.VUE_APP_API_USER_GGEST, process.env.VUE_APP_API_PASS_GGEST);
        this.$store.dispatch("setTokenGgest", response.data.token);

        const modelodeimpressaodocumento = await ServiceDocumentos.listarModelosDocumento(codigoartigo, this.erpposto)
        if (modelodeimpressaodocumento.status === 200) {
          const clienteapp = (await ServiceClients.show(item.ClientId)).data
          if (clienteapp.erp_billing_number != null) {
            // ir buscar o documento em base64
            const gerarpdfggest = {
              posto: this.erpposto,
              modelo_codigo: modelodeimpressaodocumento.data.dados[0].codigo,
              modelo_numero_vias: modelodeimpressaodocumento.data.dados[0].numero_vias,
              modelo_iniciar_via: modelodeimpressaodocumento.data.dados[0].iniciar_via,
              doc_codigo: codigoartigo,
              doc_numero: numeroartigo,
              doc_serie: serieartigo,
              doc_ano: anoartigo,
              doc_entidade: clienteapp.erp_billing_number
            }

            const pdfggestbase = await ServiceDocumentos.imprimirDocumento(gerarpdfggest)
            if (pdfggestbase.status === 200) {
              const base64adionado = await ServiceInvoices.addBase64Pdf(pdfggestbase.data.pdf, item.id)
              this.users = [];
              this.page = 1;
              this.getUsers();
              this.$store.dispatch("setSnackbar", {});
            }
          } else {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: 'Não tem cliente de faturação na ficha do cliente'
            });
          }
        } else {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: 'Modelo de impressão inválido'
          });
        }
        this.progressacriarfaturapdf = false
        this.progressacriarfaturapdfid = null
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
        this.progressacriarfaturapdf = false
        this.progressacriarfaturapdfid = null
      }

    },
    async myFn(newVal) {
      if (newVal) {
        this.loadingClients = true;
        this.clients = (await ServiceClients.listDebounce(newVal)).data;
        this.$refs.cbClient.$el.focus();
        this.loadingClients = false;
      }
    },
    async updateDashboardDateInicio(date) {
      this.$refs.toggleDataInicio.save(date);
      this.data_inicio_select = date
      this.Filter()
    },
    async updateDashboardDateFim(date) {
      this.$refs.toggleDataFim.save(date);
      this.data_fim_select = date
      this.Filter()
    },
    Filter() {
      this.users = [];
      this.invoices = [];
      this.page = 1;
      this.filterDialog = false;
      this.total_faturado = 0;
      this.getUsers();
      this.totalFaturado()
    },
    async linesInvoices (item) {
      this.invoicelinesDialog = true
      this.linesinvoices = (await ServiceInvoices.listlinesinvoices(item.id)).data;
    },
    paymentMethodDoc(value) {
      for (let paymentmethd of this.payment_methods) {
        if (paymentmethd.method_abbreviation === value) {
          return paymentmethd.text;
        }
      }
    },
    typeDoc(value) {
      for (let typedoc of this.type_documents) {
        if (typedoc.document_abbreviation === value) {
          return typedoc.text;
        }
      }
    }
  },
  async mounted() {
    /*Se o funcionário não tiver permissões de entrar nos artigos faturados */
    if (this.$store.state.user.articles_invoiced_module != true) {
      this.$router.push(this.$route.query.redirect || "home");
    }
    this.totalFaturado()
    this.payment_methods = config.PAYMENT_METHODS;
    this.clientTypeP = config.CLIENT_TYPE_PLURAL;
    this.type_documents = config.TYPE_DOCUMENT;
    this.erpposto = config.POSTO_ERP
  },
  created() {

    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {

      if (this.$refs.receipts) {
        if (this.$refs.receipts.editDialog) {
          this.$refs.receipts.editDialog = false;
          next(false);
          return;
        }

        if (this.$refs.receipts.show) {
          this.$refs.receipts.show = false;
          next(false);
          return;
        }
      }

      if (this.$refs.receiptsinvoice) {
        if (this.$refs.receiptsinvoice.editDialog) {
          this.$refs.receiptsinvoice.editDialog = false;
          next(false);
          return;
        }

        if (this.$refs.receiptsinvoice.show) {
          this.$refs.receiptsinvoice.show = false;
          next(false);
          return;
        }
      }


      if (this.filterDialog) {
        this.filterDialog = false;
        next(false);
        return;
      }

      next();
    });

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
