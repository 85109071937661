import Api from '@/serviceGgest/ServiceBase'
const path = 'gravarDocumento'

export default {

  gravarDocumento(documentoCriado) {
    return Api().post('gravarDocumento', {
      codigo: documentoCriado.tipodocumentoabreviatura,
      entidade_codigo: documentoCriado.cliente_codigo,
      posto: documentoCriado.posto,
      moeda: documentoCriado.moeda,
      entidade_descricao: documentoCriado.cliente_descricao,
      entidade_descricao2: documentoCriado.cliente_descricao2,
      entidade_morada: documentoCriado.cliente_morada,
      entidade_morada2: documentoCriado.cliente_morada2,
      entidade_codpostal: documentoCriado.cliente_codpostal,
      entidade_pais: documentoCriado.cliente_pais,
      entidade_prefixo: documentoCriado.cliente_prefixo,
      entidade_contribuinte: documentoCriado.cliente_contribuinte,
      observacao: documentoCriado.observacao,
      iva_inc: documentoCriado.iva_inc,
      prazo_pagamento: documentoCriado.prazo_pagamento,
      vendedor: documentoCriado.vendedor,
      expedicao: documentoCriado.expedicao,
      data_carga: documentoCriado.data_carga,
      hora_carga: documentoCriado.hora_carga,
      morada_carga: documentoCriado.morada_carga,
      morada2_Carga: documentoCriado.morada2_Carga,
      codpostal_carga: documentoCriado.codpostal_carga,
      pais_carga: documentoCriado.pais_carga,
      data_descarga: documentoCriado.data_descarga,
      hora_descarga: documentoCriado.hora_descarga,
      morada_descarga: documentoCriado.morada_descarga,
      morada2_descarga: documentoCriado.morada2_descarga,
      codpostal_descarga: documentoCriado.codpostal_descarga,
      pais_descarga: documentoCriado.pais_descarga,
      linhas: documentoCriado.linhas,
      formas_pag: documentoCriado.metododepagamento
    })
  },
  confTipoDocumento (codigo) {
    return Api().get(path + `/confTipoDocumento/${codigo}`)
  },
  listarTipoDocumentos (tipo, invoicetype) {
    return Api().get('listarTipoDocumentos',{
      params: {
        tipo,
        invoicetype
      }
    })
  },
  listarModelosDocumento (documento, posto) {
    return Api().get(`/listarModelosDocumento/${documento}/${posto}`,{})
  },
  imprimirDocumento(gerarPdfGgest) {
    return Api().post('imprimirDocumento', {
      posto: gerarPdfGgest.posto,
      modelo_codigo: gerarPdfGgest.modelo_codigo,
      modelo_numero_vias: gerarPdfGgest.modelo_numero_vias,
      modelo_iniciar_via: gerarPdfGgest.modelo_iniciar_via,
      doc_codigo: gerarPdfGgest.doc_codigo,
      doc_numero: gerarPdfGgest.doc_numero,
      doc_serie: gerarPdfGgest.doc_serie,
      doc_ano: gerarPdfGgest.doc_ano,
      doc_entidade: gerarPdfGgest.doc_entidade
    })
  },
  gravarRecibo(reciboCriado) {
    return Api().post('gravarRecibo', {
      codigo: reciboCriado.codigo,
      entidade: reciboCriado.entidade,
      data_doc: reciboCriado.data_doc,
      data_venc: reciboCriado.data_venc,
      posto: reciboCriado.posto,
      moeda: reciboCriado.moeda,
      prazo_pagamento: reciboCriado.prazo_pagamento,
      expedicao: reciboCriado.expedicao,
      vendedor: reciboCriado.vendedor,
      valor_por_conta: reciboCriado.valor_por_conta,
      observacao: reciboCriado.observacao,
      linhas: reciboCriado.linhas,
      formas_pag: reciboCriado.metododepagamento
    })
  },
}
