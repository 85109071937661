<template>
  <v-card>
    <v-card-title class="pa-0">
      <v-toolbar dark color="primary">

        <v-toolbar-title>{{ waitinglist.id == undefined ? 'Adicionar' : 'Editar' }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container>
          <v-row>
            <v-col cols="12" md="12">
              <v-autocomplete style="padding-top: 0px; margin-top: 0px" ref="cbClient" :label="clientTypeP" :filter="customFilter"
                :loading="loadingClients" v-debounce="newVal => myFn(newVal, 'v-text-field')" @input="selectCliente"
                :items="clients" item-text="name" item-value="id"
                clearable :return-object="true"
                hint="Nome, NIF ou Tlf." persistent-hint> <template v-slot:progress>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-autocomplete label="Responsável" v-model="waitinglist.UserId" :items="users" item-text="name" item-value="id"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete label="Tipo de serviço" v-model="waitinglist.TypeeventId" :items="typeevents" item-text="name" item-value="id" @change="typeeventChange"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="4" v-if="waitinglist.TypeeventId != null">
              <v-autocomplete label="Planos" v-model="waitinglist.PlanId" :items="plans" item-text="Plan.name" item-value="Plan.id"></v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea v-model="waitinglist.observations" label="Observações"></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <v-btn outlined text color="red" @click="clear">Limpar</v-btn>
      <v-spacer></v-spacer>
      <v-btn outlined text color="primary" :disabled="!valid" @click="submit">Submeter</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ServiceWaitinglists from "@/services/ServiceWaitinglists";
import ServicePlantypeevents from "@/services/ServicePlantypeevents";
import ServiceTypeevents from "@/services/ServiceTypeevents";
import ServiceClients from "@/services/ServiceClients";
import ServiceUsers from "@/services/ServiceUsers";
export default {
  name: "AddEdit-Waitinglist",
  props: ['waitinglist'],
  data() {
    return {
      valid: true,
      clients: [],
      typeevents: [],
      plans: [],
      users: [],
      clientTypeP: '',
      loadingClients: false,
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      },
    };
  },
  methods: {
    async submit() {
      try {
        if (this.$refs.form.validate()) {
          this.loading = true;
          const response = await ServiceWaitinglists.createUpdate(this.waitinglist);

          if (!this.waitinglist.id) {
            this.$refs.form.reset();
          }

          this.$emit('submited', response.data);

          this.loading = false;
          this.$store.dispatch("setSnackbar", {});
          this.close()
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    async typeeventChange(item) {
      this.plans = (await ServicePlantypeevents.getTypeeventPlans(null, item)).data
    },
    customFilter() {
      return true;
    },
    async myFn(newVal) {
      if (newVal) {
        this.loadingClients = true;
        this.clients = (await ServiceClients.listDebounce(newVal)).data;
        this.$refs.cbClient.$el.focus();
        this.loadingClients = false;
      }
    },
    async selectCliente (selectedClient) {
      this.waitinglist.ClientId = selectedClient.id
    },
    clear() {
      this.$refs.form.reset();
    },
    close() {
      this.$emit('close');
    }
  },
  async mounted() {
    this.users = (await ServiceUsers.list()).data;
    this.typeevents = (await ServiceTypeevents.listartodos(1)).data;
    this.clientTypeP = config.CLIENT_TYPE_PLURAL;
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
