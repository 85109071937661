<template>
  <v-dialog v-model="show" scrollable width="1500" transition="dialog-bottom-transition">
    <v-card class="basicBackGround">
      <v-toolbar dark color="light-blue">
        <v-toolbar-title>Faturação: {{ client.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
          <v-card class="elevation-0" color="transparent" v-if="articlesinvoiced.length != 0">
            <v-list color="transparent">
              <v-list-item-group>
                <v-list-item>
                  <h1>Por pagar: {{ total_por_pagar }} €</h1>
                  <v-spacer></v-spacer>
                  <h1>Total: {{ total_faturado }} €</h1>
                </v-list-item>
                <v-list-item>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" outlined @click="openReceipts()">Criar recibo</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-list-item-content>
                    <v-data-table infinite-scroll-disabled="busy"
                    :headers="headers" :items="articlesinvoiced" :loading="loading" hide-default-footer
                    :items-per-page="articlesinvoiced.length + 10" lass="mTable" item-value="id" v-model="selectedArticles">
                      <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>


                      <template v-slot:[`item.selects`]="{ item }">
                        <v-checkbox v-if="item.pay === false" @change="updateSelection(item)"/>
                      </template>

                      <template v-slot:[`item.data-table-expand`]="{ item }">
                        <v-icon @click="linesInvoices(item)" color="blue">mdi-eye</v-icon>
                      </template>

                      <template v-slot:[`item.payment_methods`]="{ item }">
                        {{paymentMethodDoc(item.payment_methods)}}
                      </template>

                      <template v-slot:[`item.type_document`]="{ item }">
                        {{typeDoc(item.type_document)}}
                      </template>

                      <template v-slot:[`item.date`]="{ item }">
                        {{ formatDate(item.date) }}
                      </template>

                      <template v-slot:[`item.price`]="{ item }">
                        {{ item.price }} €
                      </template>

                      <template v-slot:[`item.pay`]="{ item }">
                        <span v-if="item.pay === true"><v-btn color="success">Paga</v-btn></span>
                        <span v-if="item.pay === false"><v-btn color="error">Não paga</v-btn></span>
                      </template>

                      <template v-slot:[`item.action`]="{ item }">


                        <v-tooltip top v-if="item.base64pdf != null && item.base64pdf != '' && $store.state.configerp.configuracoeserpestado == true">
                          <template v-slot:activator="{ on }">
                            <v-btn icon class="mx-0">
                              <v-icon v-on="on" color="teal" @click="downloadInvoice(item)">mdi-download</v-icon>
                            </v-btn>
                          </template>
                          <span>Download</span>
                        </v-tooltip>

                        <v-tooltip top v-if="$store.state.configerp.configuracoeserpestado == true && item.number_invoice != 1 && item.base64pdf === null || item.base64pdf === ''">
                          <template v-slot:activator="{ on }">
                            <v-btn icon class="mx-0">
                              <v-icon v-on="on" color="teal" @click="createBase64Pdf(item)">mdi-file-pdf-box</v-icon>
                            </v-btn>
                          </template>
                          <span>Criar fatura pdf</span>
                        </v-tooltip>

                        <v-tooltip top v-if="progressacriarfaturapdf === true && progressacriarfaturapdfid === item.id">
                          <template v-slot:activator="{ on }">
                            <v-progress-linear v-on="on" class="mt-2" color="primary" indeterminate rounded height="10"></v-progress-linear>
                          </template>
                        </v-tooltip>


                        <v-tooltip top v-if="item.base64pdf != null && item.base64pdf != '' && $store.state.configerp.configuracoeserpestado == true">
                          <template v-slot:activator="{ on }">
                            <v-btn icon class="mx-0">
                              <v-icon v-on="on" color="primary" @click="openDatesPdfEmail(item)">mdi-email</v-icon>
                              <v-badge v-if="item.InvoiceLogEmails.filter(email => email.invite_success).length != 0" invite_success color="primary" :content="item.InvoiceLogEmails.filter(email => email.invite_success).length" position="top right" class="ma-0 pa-0"></v-badge>
                            </v-btn>
                          </template>
                          <span>Datas de envio do documento por email</span>
                        </v-tooltip>

                        <v-tooltip top>
                          <template v-slot:activator="{ on }" v-if="item.ReceiptLines.length != 0">
                            <v-btn icon class="mx-0">
                              <v-icon v-on="on" color="purple" @click="openReceiptsInvoice(item)">mdi-receipt</v-icon>
                            </v-btn>
                          </template>
                          <span>Recibos</span>
                        </v-tooltip>

                      </template>
                    </v-data-table>
                  </v-list-item-content>
                </v-list-item>
                <v-list-item>
                  <v-dialog v-model="invoicelinesDialog" max-width="1200px">
                    <v-card>
                      <v-card-title class="pa-0">
                        <v-toolbar dark color="primary">
                          <v-toolbar-title>Linhas da fatura</v-toolbar-title>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                      </v-card-title>
                      <v-card-text>
                        <v-col cols="12">
                          <v-data-table :headers="headersLines" :items="linesinvoices" hide-default-footer
                          class="mTable full-width" :items-per-page="linesinvoices.length + 10">

                          <template v-slot:[`item.price`]="{ item }">
                            {{ item.price }} €
                          </template>

                        </v-data-table>
                        </v-col>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                  <v-dialog v-model="inviteEmailPdfDialog" max-width="1200px">
                    <v-card>
                      <v-card-title class="pa-0">
                        <v-toolbar dark color="primary">
                          <v-toolbar-title>Datas de envio do documento por email</v-toolbar-title>
                          <v-spacer></v-spacer>
                        </v-toolbar>
                      </v-card-title>
                      <v-card-text>
                          <v-col cols="12">
                            <v-data-table :headers="headersPdfEmail" :items="dataspdfemail" hide-default-footer
                            class="mTable full-width" :items-per-page="dataspdfemail.length + 10">

                            <template v-slot:[`item.date`]="{ item }">
                              {{ formatDate(item.date) }}
                            </template>

                            <template v-slot:[`item.hour`]="{ item }">
                              {{ formatTime(item.date) }}
                            </template>

                            <template v-slot:[`item.invite_success`]="{ item }">
                              <v-icon v-if="item.invite_success" color="green darken-2">mdi-check</v-icon>
                              <v-icon v-else color="red darken-2">mdi-close</v-icon>
                            </template>

                          </v-data-table>
                          </v-col>
                          <v-col cols="12" class="text-right">
                            <v-btn text color="primary" outlined @click="invitePdfEmail()">Enviar documento por email</v-btn>
                          </v-col>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-card v-else class="elevation-0" align="center" justify="center" color="transparent">
            <v-icon size="200" color="red">
              mdi-file
            </v-icon>
            <v-card-title class="justify-center">Não há faturação a apresentar.</v-card-title>
          </v-card>
      </v-card-text>
    </v-card>
    <receipts @handleReceipt="handleReceipt" ref="receipts" />
    <receiptsInvoice ref="receiptsinvoice" />
  </v-dialog>
</template>

<script>
import ServiceInvoices from "@/services/ServiceInvoices";
import ServiceInvoiceLogEmails from "@/services/ServiceInvoiceLogEmails";
import Receipts from "@/components/receipt/Receipts.vue";
import ReceiptsInvoice from "@/components/receipt/ReceiptsInvoice.vue";
import ServiceClients from "@/services/ServiceClients";
import ServiceDocumentos from "@/serviceGgest/ServiceDocumentos";
import ServiceLogin from "@/serviceGgest/ServiceLogin";
export default {
  name: "ArticlesInvoiced",
  components: {
    Receipts,
    ReceiptsInvoice
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_URL,
      show: false,
      loading: false,
      client: {},
      itempdfemail: {},
      dataspdfemail: [],
      articlesinvoiced: [],
      invoices: [],
      invoicesnotpay: [],
      total_faturado: 0,
      total_por_pagar: 0,
      payment_methods: [],
      type_documents: [],
      linesinvoices: [],
      selectedArticles: [],
      erpposto: 1,
      progressacriarfaturapdf: false,
      progressacriarfaturapdfid: null,

      valid: true,
      invoicelinesDialog: false,
      inviteEmailPdfDialog: false,
      headers: [
        { text: '', value: 'selects', sortable: true },
        { text: 'Linhas fatura', value: 'data-table-expand', sortable: true },
        { text: "Número fatura", value: "number_invoice", sortable: true },
        { text: "Tipo documento", value: "type_document", sortable: true },
        { text: "Cliente", value: "Client.name", sortable: true },
        { text: "Método de pagamento", value: "payment_methods", sortable: true },
        { text: "Data da faturação", value: "date", sortable: true },
        { text: "Preço", value: "price", sortable: true },
        { text: "Observações", value: "observations", sortable: true },
        { text: "", value: "pay", sortable: true },
        { text: "Ações", value: "action", sortable: true, align: "center" },
      ],
      headersLines: [
        { text: 'Referência', value: 'Typeevent.reference', sortable: true },
        { text: "Tipo de serviço", sortable: true, value: "Typeevent.name" },
        { text: "Plano", sortable: true, value: "Plan.name" },
        { text: 'Descrição', value: 'description', sortable: true },
        { text: 'Qnt', value: 'quantity', sortable: true },
        { text: 'Preço', value: 'price', sortable: true },
      ],
      headersPdfEmail: [
        { text: 'Documento', value: 'Invoice.number_invoice', sortable: true },
        { text: 'Data', value: 'date', sortable: true },
        { text: 'Hora', value: 'hour', sortable: true },
        { text: 'Enviou', value: 'invite_success', sortable: true },
      ],
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      }
    };
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    formatTime(dateString) {
      const date = new Date(dateString);

      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${hours}:${minutes}`;
    },
    close() {
      this.show = false;
    },
    async open(client) {
      this.articlesinvoiced = []
      this.loading = true;
      this.client = client;
      this.articlesinvoiced = (await ServiceInvoices.listinvoices(null, null, null, this.client.id)).data;
      this.show = true;
      this.loading = false;
      this.totalFaturado()
      this.totalPorPagar()
    },
    async totalFaturado () {
      this.total_faturado = 0
      this.invoices = (await ServiceInvoices.listtotalfaturado(null, null, this.client.id)).data
      for (var i = 0; i < this.invoices.length; i++) {
        this.total_faturado = (parseFloat(this.total_faturado) + parseFloat(this.invoices[i].price)).toFixed(2);
      }
    },
    async totalPorPagar () {
      this.total_por_pagar = 0
      this.invoicesnotpay = (await ServiceInvoices.listtotalfaturado(null, null, this.client.id, null, null, null, 1)).data
      for (var i = 0; i < this.invoicesnotpay.length; i++) {
        this.total_por_pagar = (parseFloat(this.total_por_pagar) + parseFloat(this.invoicesnotpay[i].price)).toFixed(2);
      }
    },
    async downloadInvoice (item) {
      if (item.base64pdf == null || item.base64pdf == '') {
        alert("Documento sem pdf")
      } else {
        try {
          // Converter base64 em pdf
          const byteCharacters = atob(item.base64pdf);
          const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], { type: 'application/pdf' });

          // Criar uma URL temporária e disparar o download
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.download = item.number_invoice;
          link.click();

          // Limpar a URL temporário
          URL.revokeObjectURL(link.href);
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    async openDatesPdfEmail (item) {
      this.itempdfemail = item
      this.inviteEmailPdfDialog = true
      this.dataspdfemail = (await ServiceInvoiceLogEmails.list(item.id)).data;
    },
    async invitePdfEmail () {
      if (this.itempdfemail.base64pdf == null || this.itempdfemail.base64pdf == '') {
        alert("Documento sem pdf")
      } else {
        const enviarpfdemail = await ServiceInvoices.invitePdfEmail(this.itempdfemail.id, this.itempdfemail.Client, this.itempdfemail.number_invoice, 1);
        if (enviarpfdemail.status === 200) {
          this.dataspdfemail = (await ServiceInvoiceLogEmails.list(this.itempdfemail.id)).data;
          this.$store.dispatch("setSnackbar", {});
        } else {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    async linesInvoices (item) {
      this.invoicelinesDialog = true
      this.linesinvoices = (await ServiceInvoices.listlinesinvoices(item.id)).data;
    },
    paymentMethodDoc(value) {
      for (let paymentmethd of this.payment_methods) {
        if (paymentmethd.method_abbreviation === value) {
          return paymentmethd.text;
        }
      }
    },
    typeDoc(value) {
      for (let typedoc of this.type_documents) {
        if (typedoc.document_abbreviation === value) {
          return typedoc.text;
        }
      }
    },
    openReceipts() {
      if (this.selectedArticles != '') {
        this.$refs.receipts.open(this.client, this.selectedArticles, 1);
      }
    },
    openReceiptsInvoice(item) {
      this.$refs.receiptsinvoice.open(item);
    },
    async handleReceipt() {
      this.selectedArticles = []
      this.articlesinvoiced = (await ServiceInvoices.listinvoices(null, null, null, this.client.id)).data;
      this.show = false;
    },
    updateSelection(item) {
      const index = this.selectedArticles.findIndex(artigo => artigo === item.id);
      if (index !== -1) {
        this.selectedArticles.splice(index, 1);
      } else {
        this.selectedArticles.push(item.id);
      }
    },
    async createBase64Pdf (item) {
      try {
        this.progressacriarfaturapdf = true
        this.progressacriarfaturapdfid = item.id
        let codigoartigo = item.number_invoice.split(' ')[0]
        let numeroartigo = item.number_invoice.split('/')[1].split(',')[0]
        let serieartigo = item.number_invoice.split(' ')[1].split('/')[0]
        let anoartigo = item.number_invoice.split(',')[1]

        const response = await ServiceLogin.login(process.env.VUE_APP_API_USER_GGEST, process.env.VUE_APP_API_PASS_GGEST);
        this.$store.dispatch("setTokenGgest", response.data.token);

        const modelodeimpressaodocumento = await ServiceDocumentos.listarModelosDocumento(codigoartigo, this.erpposto)
        if (modelodeimpressaodocumento.status === 200) {
          const clienteapp = (await ServiceClients.show(item.ClientId)).data
          if (clienteapp.erp_billing_number != null) {
            // ir buscar o documento em base64
            const gerarpdfggest = {
              posto: this.erpposto,
              modelo_codigo: modelodeimpressaodocumento.data.dados[0].codigo,
              modelo_numero_vias: modelodeimpressaodocumento.data.dados[0].numero_vias,
              modelo_iniciar_via: modelodeimpressaodocumento.data.dados[0].iniciar_via,
              doc_codigo: codigoartigo,
              doc_numero: numeroartigo,
              doc_serie: serieartigo,
              doc_ano: anoartigo,
              doc_entidade: clienteapp.erp_billing_number
            }

            const pdfggestbase = await ServiceDocumentos.imprimirDocumento(gerarpdfggest)
            if (pdfggestbase.status === 200) {
              const base64adionado = await ServiceInvoices.addBase64Pdf(pdfggestbase.data.pdf, item.id)
              this.articlesinvoiced = (await ServiceInvoices.listinvoices(null, null, null, this.client.id)).data;
              this.$store.dispatch("setSnackbar", {});
            }
          } else {
            this.$store.dispatch("setSnackbar", {
              color: "error",
              text: 'Não tem cliente de faturação na ficha do cliente'
            });
          }
        } else {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: 'Modelo de impressão inválido'
          });
        }
        this.progressacriarfaturapdf = false
        this.progressacriarfaturapdfid = null
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
        this.progressacriarfaturapdf = false
        this.progressacriarfaturapdfid = null
      }

    }
  },
  async mounted() {
    this.payment_methods = config.PAYMENT_METHODS;
    this.type_documents = config.TYPE_DOCUMENT;
    this.erpposto = config.POSTO_ERP
  },
  created() {

    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {

      if (this.$refs.receipts) {
        if (this.$refs.receipts.editDialog) {
          this.$refs.receipts.editDialog = false;
          next(false);
          return;
        }

        if (this.$refs.receipts.show) {
          this.$refs.receipts.show = false;
          next(false);
          return;
        }
      }

      if (this.$refs.receiptsinvoice) {
        if (this.$refs.receiptsinvoice.editDialog) {
          this.$refs.receiptsinvoice.editDialog = false;
          next(false);
          return;
        }

        if (this.$refs.receiptsinvoice.show) {
          this.$refs.receiptsinvoice.show = false;
          next(false);
          return;
        }
      }

      next();
    });

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
