<template>
  <v-card>
    <v-card-title class="pa-0">
      <v-toolbar dark color="primary">
        <v-toolbar-title>{{ group.id == undefined ? 'Adicionar' : 'Editar' }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="close">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-card-text>
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-container>
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-text-field v-model="group.name" :rules="[rules.required, rules.counter]" :counter="250"
                label="Descrição" required></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field type="number" v-model="group.number_people" :rules="[rules.required]" :counter="250" label="Número de pessoas"></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-autocomplete style="padding-top: 0px; margin-top: 0px" ref="cbClient" :label="clientTypeP" :filter="customFilter"
                :loading="loadingClients" v-debounce="newVal => myFn(newVal, 'v-text-field')" @input="selectClienteGrupos"
                :items="clients" item-text="name" item-value="id" clearable v-model="selectedClient"
                hint="Nome, NIF ou Tlf." persistent-hint :return-object="true">
                <template v-slot:progress>
                    <span class="custom-loader">
                      <v-icon light>mdi-cached</v-icon>
                    </span>
                  </template>
                </v-autocomplete>
            </v-col>
          </v-row>
          <v-row v-for="cliente in group.arrayClientesGrupoCompleto" :key="cliente.id">
            <v-col cols="9">
              {{cliente.name}}
            </v-col>
            <v-col cols="3 text-right">
              <v-icon @click="removeClienteGrupo(cliente.id)" color="red" class="ml-5">mdi-close</v-icon>
            </v-col>
          </v-row>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions>
      <!--<v-btn outlined text color="red" @click="clear">Limpar</v-btn>-->
      <v-spacer></v-spacer>
      <v-btn outlined text color="primary" :disabled="!valid" @click="submit">Submeter</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ServiceGroups from "@/services/ServiceGroups";
import ServiceClients from "@/services/ServiceClients";
import _ from "lodash";
export default {
  name: "AddEdit-Group",
  props: ['group'],
  data() {
    return {
      valid: true,
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      },
      locked: true,
      lockClient: false,
      loadingClients: false,
      clients: [],
      clientTypeP: '',
      selectedClient: null,

    };
  },
  async mounted () {
    this.clientTypeP = config.CLIENT_TYPE_PLURAL;
  },
  methods: {
    customFilter(item, queryText, itemText) {
      return true;
    },
    async myFn(newVal) {
      if (newVal) {
        this.loadingClients = true;
        this.clients = (await ServiceClients.listDebounce(newVal)).data;
        this.$refs.cbClient.$el.focus();
        this.loadingClients = false;
      }
    },
    async selectClienteGrupos (selectedClientt) {
      if (this.group.arrayClientesGrupoCompleto != undefined) {
        if (!this.group.arrayClientesGrupoCompleto.find(item => item.id === selectedClientt.id)) {
          this.group.arrayClientesGrupoCompleto.push({id: selectedClientt.id, name: selectedClientt.name});
        }
      } else {
        this.group.arrayClientesGrupoCompleto = []
        this.group.arrayClientesGrupoCompleto.push({id: selectedClientt.id, name: selectedClientt.name});
      }

      if (this.group.arrayClientesGrupoIds != undefined) {
        if (!this.group.arrayClientesGrupoIds.includes(selectedClientt.id)) {
          this.group.arrayClientesGrupoIds.push(selectedClientt.id);
        }
      } else {
        this.group.arrayClientesGrupoIds = []
        this.group.arrayClientesGrupoIds.push(selectedClientt.id);
      }

      this.selectedClient = null;
    },
    async removeClienteGrupo (clienteId) {
      this.group.arrayClientesGrupoCompleto = [...this.group.arrayClientesGrupoCompleto.filter(client => client.id !== clienteId)];
      this.group.arrayClientesGrupoIds = [...this.group.arrayClientesGrupoIds.filter(id => id !== clienteId)];
      this.$forceUpdate();
    },
    async submit() {
      try {
        if (this.$refs.form.validate()) {

            this.group.Clients = this.group.arrayClientesGrupoIds
            this.loading = true;
            const response = await ServiceGroups.createUpdate(this.group);

            if (!this.group.id) {
              this.$refs.form.reset();
            }

            this.$emit('submited', response.data);

            this.loading = false;
            this.$store.dispatch("setSnackbar", {});

        }

      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    clear() {
      this.$refs.form.reset();
    },
    close() {
      this.$emit('close');
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>
