import Api from '@/services/ServiceBase'
const path = 'invoices'

export default {
  createinvoicecliente(clientid, arrayartigosfaturar, payment_method, type_document, invoice_pay, numerofaturagerada, observacoes) {
    return Api().post(path + '/createinvoicecliente', {
      params: {
        clientid,
        arrayartigosfaturar,
        payment_method,
        type_document,
        invoice_pay,
        numerofaturagerada,
        observacoes
      }
    })
  },
  listinvoices(page, data_primeiro_documento, data_ultimo_documento, clientid, payment_method, number_invoice, pay, clientname) {
    return Api().get(path + '/listinvoices', {
      params: {
        page,
        data_primeiro_documento,
        data_ultimo_documento,
        clientid,
        payment_method,
        number_invoice,
        pay,
        clientname
      }
    })
  },
  listarticlesnotinvoiced(page, clientId, data_primeiro_documento, data_ultimo_documento, plans_ids, typeeventid, clientname) {
    return Api().get(path + '/listarticlesnotinvoiced', {
      params: {
        page,
        clientId,
        data_primeiro_documento,
        data_ultimo_documento,
        plans_ids,
        typeeventid,
        clientname
      }
    })
  },
  listlinesinvoices(invoiceid) {
    return Api().get(path + '/listlinesinvoices', {
      params: {
        invoiceid
      }
    })
  },
  listtotalfaturado (data_primeiro_documento, data_ultimo_documento, clientid, payment_method, number_invoice, pay, clientname) {
    return Api().get(path + '/listtotalfaturado', {
      params: {
        data_primeiro_documento,
        data_ultimo_documento,
        clientid,
        payment_method,
        number_invoice,
        pay,
        clientname
      }
    })
  },
  addBase64Pdf(base64, idinvoice) {
    return Api().post(path + '/addbase64pdf', {base64,idinvoice})
  },
  addBase64PdfEmail(base64, nomeficheiro, idinvoice, client, typedocument) {
    return Api().post(path + '/addbase64pdfemail', {base64,nomeficheiro,idinvoice,client, typedocument})
  },
  createPdfInvoice(base64, nomeficheiro, idinvoice, idcliente) {
    return Api().post(path + '/createpdfinvoice', {
      params: {
        base64,
        nomeficheiro,
        idinvoice,
        idcliente
      }
    })
  },
  invitePdfEmail(idinvoice, client, nomeficheiro, typedocument) {
    return Api().post(path + '/invitepdfemail', {
      params: {
        idinvoice,
        client,
        nomeficheiro,
        typedocument
      }
    })
  },
  show(id) {
    return Api().get(path + `/${id}`)
  },
}
