import Api from '@/services/ServiceBase'
const path = 'sendsms'

export default {
  sendMsgIndividual(clientId, eventId) {
    return Api().get(path + '/sendmsg', {
      params: {
        clientId,
        eventId
      }
    })
  },
}
