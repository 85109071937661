var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","width":"1500","transition":"dialog-bottom-transition"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"basicBackGround"},[_c('v-toolbar',{attrs:{"dark":"","color":"light-blue"}},[_c('v-toolbar-title',[_vm._v("Recibos da fatura: "+_vm._s(this.invoice.number_invoice))]),_c('v-spacer')],1),_c('v-card-text',[(_vm.listreceiptsinvoice.length != 0)?_c('v-card',{staticClass:"elevation-0",attrs:{"color":"transparent"}},[_c('v-list',{attrs:{"color":"transparent"}},[_c('v-list-item-group',[_c('v-list-item',[_c('v-list-item-content',[_c('v-data-table',{attrs:{"infinite-scroll-disabled":"busy","headers":_vm.headers,"items":_vm.listreceiptsinvoice,"loading":_vm.loading,"hide-default-footer":"","items-per-page":_vm.listreceiptsinvoice.length + 10,"lass":"mTable","item-value":"id"},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"blue"},on:{"click":function($event){return _vm.linesReceipts(item)}}},[_vm._v("mdi-eye")])]}},{key:"item.type_document",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.typeDoc(item.type_document))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" ")]}},{key:"item.total_amount_paid",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Receipt.total_amount_paid)+" € ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.base64pdf != null && item.base64pdf != '' && _vm.$store.state.configerp.configuracoeserpestado == true)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""}},[_c('v-icon',_vm._g({attrs:{"color":"teal"},on:{"click":function($event){return _vm.downloadInvoice(item)}}},on),[_vm._v("mdi-download")])],1)]}}],null,true)},[_c('span',[_vm._v("Download")])]):_vm._e(),(_vm.$store.state.configerp.configuracoeserpestado == true && item.number_invoice != 1 && item.base64pdf === null || item.base64pdf === '')?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""}},[_c('v-icon',_vm._g({attrs:{"color":"teal"},on:{"click":function($event){return _vm.createBase64Pdf(item)}}},on),[_vm._v("mdi-file-pdf-box")])],1)]}}],null,true)},[_c('span',[_vm._v("Criar fatura pdf")])]):_vm._e(),(_vm.progressacriarrecibopdf === true && _vm.progressacriarrecibopdfid === item.id)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-progress-linear',_vm._g({staticClass:"mt-2",attrs:{"color":"primary","indeterminate":"","rounded":"","height":"10"}},on))]}}],null,true)}):_vm._e(),(item.base64pdf != null && item.base64pdf != '' && _vm.$store.state.configerp.configuracoeserpestado == true)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""}},[_c('v-icon',_vm._g({attrs:{"color":"primary"},on:{"click":function($event){return _vm.openDatesPdfEmail(item)}}},on),[_vm._v("mdi-email")])],1)]}}],null,true)},[_c('span',[_vm._v("Datas de envio do documento por email")])]):_vm._e()]}}],null,true)},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"})],1)],1)],1),_c('v-list-item',[_c('v-dialog',{attrs:{"max-width":"1200px"},model:{value:(_vm.receiptlinesDialog),callback:function ($$v) {_vm.receiptlinesDialog=$$v},expression:"receiptlinesDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Linhas do recibo")]),_c('v-spacer')],1)],1),_c('v-card-text',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"mTable full-width",attrs:{"headers":_vm.headersLines,"items":_vm.linesreceipts,"hide-default-footer":"","items-per-page":_vm.linesreceipts.length + 10},scopedSlots:_vm._u([{key:"item.amount_paid_invoice",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.amount_paid_invoice)+" € ")]}},{key:"item.payment_methods",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.paymentMethodDoc(item.payment_methods))+" ")]}}],null,true)})],1)],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"1200px"},model:{value:(_vm.inviteEmailPdfDialog),callback:function ($$v) {_vm.inviteEmailPdfDialog=$$v},expression:"inviteEmailPdfDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Datas de envio do documento por email")]),_c('v-spacer')],1)],1),_c('v-card-text',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"mTable full-width",attrs:{"headers":_vm.headersPdfEmail,"items":_vm.dataspdfemail,"hide-default-footer":"","items-per-page":_vm.dataspdfemail.length + 10},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.date))+" ")]}},{key:"item.hour",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.date))+" ")]}},{key:"item.invite_success",fn:function(ref){
var item = ref.item;
return [(item.invite_success)?_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red darken-2"}},[_vm._v("mdi-close")])]}}],null,true)})],1),_c('v-col',{staticClass:"text-right",attrs:{"cols":"12"}},[_c('v-btn',{attrs:{"text":"","color":"primary","outlined":""},on:{"click":function($event){return _vm.invitePdfEmail()}}},[_vm._v("Enviar documento por email")])],1)],1)],1)],1)],1)],1)],1)],1):_c('v-card',{staticClass:"elevation-0",attrs:{"align":"center","justify":"center","color":"transparent"}},[_c('v-icon',{attrs:{"size":"200","color":"red"}},[_vm._v(" mdi-file ")]),_c('v-card-title',{staticClass:"justify-center"},[_vm._v("Não há recibos a apresentar.")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }