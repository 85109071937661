import Api from '@/services/ServiceBase'
const path = 'typeevents'

export default {
  createUpdate(item) {
    return Api().post(path, item)
  },
  list(page, name, in_event) {
    return Api().get(path, {
      params: {
        page,
        name,
        in_event
      }
    })
  },
  listartodos(in_event) {
    return Api().get(path + '/listartodos', {
      params: {
        in_event
      }
    })
  },
  show(id) {
    return Api().get(path + `/${id}`)
  },
  delete(typeeventId) {
    return Api().get(path + `/delete/${typeeventId}`)
  },
  showExternal(externalID) {
    return Api().get(path + `/showExternal/${externalID}`)
  },
}
