import Api from '@/services/ServiceBase'
const path = 'receiptloglines'

export default {
  createUpdate(item) {
    return Api().post(path, item)
  },
  list(idreceiptlog) {
    return Api().get(path, {
      params: {
        idreceiptlog
      }
    })
  },
}
