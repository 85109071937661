import Api from '@/services/ServiceBase'
const path = 'settingserp'

export default {
  show() {
    return Api().get(path, {})
  },
  update (configuracao) {
    return Api().put(path + `/${configuracao.id}`, configuracao)
  },
}
