import Api from '@/services/ServiceBase'
const path = 'calendar'

export default {
  getCalendar(userId, startDate, endDate) {
    return Api().get(path, {
      params: {
        userId,
        startDate,
        endDate
      }
    })
  },
  getCalendarByClient(clientId, userId) {
    return Api().get(path + '/getCalendarByClient', {
      params: {
        clientId,
        userId
      }
    })
  },
  addEvent(event) {
    return Api().post(path + '/addevent', event)
  },
  bulkAddEvent(ClientEvents,RoomId,TypeeventId,UserId,startDate,endDate,startTime,endTime,selectedDays,details, ClientAvencas) {
    return Api().post(path + '/bulkcreate', {
      ClientEvents,RoomId,TypeeventId,UserId,startDate,endDate,startTime,endTime,selectedDays,details,ClientAvencas
    })
  },
  bulkEditEvent(ClientEvents,linkedId,RoomId,TypeeventId,UserId,startDiference,endDiference,oldStartDate,details, ClientAvencas) {
    return Api().post(path + '/bulkEdit', {
      ClientEvents,linkedId,RoomId,TypeeventId,UserId,startDiference,endDiference,oldStartDate,details,ClientAvencas
    })
  },
  bulkValidateForEdit(linkedId,RoomId,UserId,startDiference,endDiference,oldStartDate, TypeeventId) {
    return Api().post(path + '/bulkValidateForEdit', {
      linkedId,RoomId,UserId,startDiference,endDiference,oldStartDate,TypeeventId
    })
  },
  validateNewEvent(userId,roomId, start, end,eventId, ClientEvents, TypeeventId, ClientAvencas) {
    return Api().get(path + '/validatenewevent', {
      params: {
        userId,
        roomId,
        start,
        end,
        eventId,
        ClientEvents,
        TypeeventId,
        ClientAvencas
      }
    })
  },
  bulkValidate(userId,roomId, startDate, endDate,startTime,endTime,selectedDays, TypeeventId) {
    return Api().get(path + '/bulkValidate', {
      params: {
        userId,
        roomId,
        startDate,
        endDate,
        startTime,
        endTime,
        selectedDays,
        TypeeventId
      }
    })
  },
  delete(eventId, TypeeventId, ClientEvents) {
    return Api().get(path + `/delete/${eventId}`, {
      params: {
        TypeeventId,
        ClientEvents
      }
    })
  },
  deletebulk(linkedId, startDate) {
    return Api().get(path + `/deletebulk/${linkedId}`, {
      params: {
        startDate
      }
    })
  },
  getLinkedEventsUniqueDays(linkedId) {
    return Api().get(path + `/getLinkedEventsUniqueDays`, {
      params: {
        linkedId
      }
    })
  },
  bulkCreate(data) {
    return Api().post(path + '/bulkcreate', data)
  },
  addEventReplacement(event) {
    return Api().post(path + '/addeventreplacement', event)
  },
  validateNewEventReplacement(event) {
    return Api().get(path + '/validateneweventreplacement', {
      params: {
        event
      }
    })
  }
}
