<template>
  <v-app id="inspire">
    <v-navigation-drawer dark v-if="$route.path != '/login'" v-model="drawer" app :mini-variant="mini"
      style="background-color: #0D2149;padding-top: 50px;">
      <template v-slot:append>
        <v-list-item class="hidden-md-and-down" @click.stop="mini = !mini" style="justify-content: end">
          <v-icon color="white" v-if="mini">mdi-chevron-right</v-icon>
          <v-icon color="white" v-else>mdi-chevron-left</v-icon>
        </v-list-item>
      </template>

      <v-list dense>
        <v-list-item v-if="$store.state.user.role !== 'infordio'" :to="{
          name: 'home'
        }">
          <v-list-item-icon>
            <v-icon>mdi-home</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Ínicio</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="$store.state.user.dashboard_module === true" :to="{
          name: 'dashboard'
        }">
          <v-list-item-icon>
            <v-icon>mdi-monitor-dashboard</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="$store.state.user.see_everyones_appointments_module === true" :to="{
          name: 'calendarFull'
        }">
          <v-list-item-icon>
            <v-icon>mdi-calendar-month</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Calendário Completo</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="$store.state.user.see_everyones_appointments_module === true" :to="{
          name: 'calendarPeopleOverview'
        }">
          <v-list-item-icon>
            <v-icon>mdi-calendar-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Calendário Funcionários</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="$store.state.user.rooms_module === true" :to="{
          name: 'rooms'
        }">
          <v-list-item-icon>
            <v-icon>mdi-home-group</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Salas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="$store.state.user.users_module === true" :to="{
          name: 'users'
        }">
          <v-list-item-icon>
            <v-icon>mdi-account-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Funcionários</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="mDivider" v-if="$store.state.user.clients_module === true || $store.state.user.waitinglists_module === true || $store.state.user.crm_module === true || $store.state.user.groups_module === true"></v-divider>

        <v-list-item v-if="$store.state.user.clients_module === true" :to="{
          name: 'clients'
        }">
          <v-list-item-icon>
            <v-icon>mdi-account-tie</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{clientTypeP}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="$store.state.user.waitinglists_module === true" :to="{
          name: 'waitinglists'
        }">
          <v-list-item-icon>
            <v-icon>mdi-account-clock</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Lista de espera</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="$store.state.user.crm_module === true" :to="{
          name: 'crms'
        }">
          <v-list-item-icon>
            <v-icon>mdi-account-convert</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>CRM - prospeção</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="$store.state.user.groups_module === true" :to="{
          name: 'groups'
        }">
          <v-list-item-icon>
            <v-icon>mdi-account-group</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Grupos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="mDivider" v-if="$store.state.user.typeevents_module === true || $store.state.user.plans_module === true || $store.state.user.regulations_module === true"></v-divider>

        <v-list-item v-if="$store.state.user.typeevents_module === true" :to="{
          name: 'typeevents'
        }">
          <v-list-item-icon>
            <v-icon>mdi-clipboard-list</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Tipos de Serviço</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="$store.state.user.plans_module === true" :to="{
          name: 'plans'
          }" >
          <v-list-item-icon>
            <v-icon>mdi-floor-plan</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Planos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>



        <v-list-item v-if="$store.state.user.regulations_module === true" :to="{
          name: 'regulations'
        }">
          <v-list-item-icon>
            <v-icon>mdi-clipboard-text</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Regulamentos/Emails</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="mDivider" v-if="$store.state.user.articles_not_invoiced_module === true || $store.state.user.articles_invoiced_module === true || $store.state.user.logs_invoiced_module === true"></v-divider>

        <v-list-item v-if="$store.state.user.articles_not_invoiced_module === true" :to="{
          name: 'articlesnotinvoiced'
        }">
          <v-list-item-icon>
            <v-icon>mdi-invoice-text-plus</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Artigos por faturar</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="$store.state.user.articles_invoiced_module === true" :to="{
          name: 'articlesinvoiced'
        }">
          <v-list-item-icon>
            <v-icon>mdi-invoice-text</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Faturação</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-list-item v-if="$store.state.user.logs_invoiced_module === true" :to="{
          name: 'logsinvoices'
        }">
          <v-list-item-icon>
            <v-icon>mdi-invoice-send</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logs faturação</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="$store.state.user.logs_receipt_module === true" :to="{
          name: 'logsreceipts'
        }">
          <v-list-item-icon>
            <v-icon>mdi-invoice-send</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logs recibos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>


        <v-divider class="mDivider" v-if="$store.state.user.role == 'infordio'"></v-divider>

        <v-list-item v-if="$store.state.user.role === 'infordio'" :to="{
          name: 'settingserp'
        }">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Configurações ERP</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="$store.state.user.role === 'infordio'" :to="{
          name: 'settingssms'
        }">
          <v-list-item-icon>
            <v-icon>mdi-cog</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Configurações SMS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="mDivider" ></v-divider>

        <v-list-item :to="{
          name: 'edit-pass'
        }">
          <v-list-item-icon>
            <v-icon>mdi-lock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Password</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="$store.state.user.settings_edit === true" :to="{
          name: 'settingstemplates'
        }">
          <v-list-item-icon>
            <v-icon>mdi-cog-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Configurações</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider class="mDivider"></v-divider>

        <v-list-item v-if="!$store.state.isUserLoggedIn" :to="{
          name: 'login'
        }">
          <v-list-item-icon>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Login</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="$store.state.isUserLoggedIn" @click="logout()">
          <v-list-item-icon>
            <v-icon>mdi-logout-variant</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>

    </v-navigation-drawer>
    <v-app-bar app class="elevation-0" style="background-color: #f5f5ff">
      <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="$route.path != '/login'" class="hidden-lg-and-up">
          <v-icon>mdi-menu</v-icon>
        </v-app-bar-nav-icon>
        <span class="hidden-sm-and-down" ref="mainTitle">{{ title }}</span>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <p v-if="user" class="ma-2">{{ user.name }}</p>
      <v-btn icon large>
        <v-avatar size="32px" tile>
          <img src="@/assets/infordio.png" alt="Infordio" />
        </v-avatar>
      </v-btn>
    </v-app-bar>
    <v-main ref="mainContainer" style="background-color: #f5f5ff">
      <!-- <keep-alive
        include="List-Users,List-Materials,List-Clients,List-Material-Groups,List-Machine-Groups,List-User-Groups,List-Machines,List-Projects"> -->
      <!-- <keep-alive include="List-Projects"> -->
      <router-view :key="$route.fullPath"></router-view>
      <!-- </keep-alive> -->
      <v-snackbar v-model="snackbar.showing" :timeout="6000" :color="snackbar.color">
        {{ snackbar.text }}
        <template v-slot:action="{ attrs }">
          <v-btn text @click="snackbar.showing = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";
export default {
  data: () => ({
    active: false,
    dialog: false,
    drawer: null,
    mini: false,
    projectStatus: [],
    clientTypeP:'',
  }),
  methods: {
    logout() {
      try {
        this.$store.dispatch("setToken", null);
        this.$store.dispatch("setUser", null);
        this.$store.dispatch("setLogTime", null);
        this.$store.dispatch("setConfiguerp", null);
        this.$store.dispatch("setConfigusms", null);
        this.$router.push({
          name: "login"
        });
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error.message
        });
      }
    }
  },
  computed: {
    ...mapState(["snackbar", "user", 'isUserLoggedIn', 'title', 'configerp', 'configsms']),
    title() {
      if (this.$route.meta.title) {
        if (this.$route.meta.title === 'client_type') {
          return config.CLIENT_TYPE_PLURAL;
        }
        return this.$route.meta.title || 'Gestão e Planeamento'
      }
    },
  },
  watch: {
    async isUserLoggedIn(newValue) {
      if (!newValue) {
        this.$router.push({
          name: "login"
        });
      }
      if (this.$store.state.isUserLoggedIn && this.$store.state.user.role == 'infordio') {
        this.$router.push({
          name: "settingserp"
        });
      }
    }
  }, beforeCreate() {
    this.$store.dispatch("setSnackbar", {
      showing: false,
      color: "error",
      text: ""
    });
  },
  async mounted() {
    this.clientTypeP = config.CLIENT_TYPE_PLURAL;
  },
  props: {
    source: String
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

.mDivider {
  border-width: thin 0 thin 0 !important;
  border-color: white !important;
  margin-top: 20px;
  margin-bottom: 20px;
}

.mTable tr:nth-of-type(even) {
  background-color: rgba(0, 0, 255, 0.1);
  border-radius: 10px;
}

.mTable thead {
  background-color: #1565C0;
  color: white !important;
}
.mTable th {
  color: white !important;
}

.mTableNoHeader tr:nth-of-type(even) {
  background-color: rgba(0, 0, 255, 0.1);
}

.basicBackGround {
  background-color: #f5f5ff !important;
}
.next-appointment {
  background-color: rgb(211, 155, 102) !important;
  color: white !important;
}

.next-appointment td {
  color: white !important;
}

.removeTextFieldDetails .v-text-field__details{
  display: none !important;
}

#checkboxReplacement .v-input {
  padding: 0px !important;
  margin: 0px !important;
}
</style>
