<template>
  <v-dialog v-model="show" scrollable width="600" transition="dialog-bottom-transition" @input="onDialogClose2">
    <v-card class="basicBackGround">
      <v-toolbar dark color="light-blue">
        <v-toolbar-title>Criar recibo</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation v-if="progressacriarrecibo === false">
          <v-container>

            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-autocomplete label="Método de pagamento" v-model="payment_method_select" :items="payment_methods" :rules="[rules.required]" item-text="text" item-value="method_abbreviation"></v-autocomplete>
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-textarea label="Observações do recibo" v-model="observations"></v-textarea>
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="12">
                <v-checkbox v-if="sacofaturar === true && $store.state.configerp.configuracoeserpestado == true && $store.state.user.blue_document_bag === true" v-model="sacofaturarvalue" dense label="Não enviar para o GGest" class="mr-5"></v-checkbox>
              </v-col>
            </v-row>

          </v-container>
        </v-form>
        <v-progress-linear v-else class="mt-2" color="primary" indeterminate rounded height="10"></v-progress-linear>
      </v-card-text>
      <v-card-actions v-if="progressacriarrecibo === false">
        <v-spacer></v-spacer>
        <v-btn text color="primary" outlined @click="criarrecibo">Criar recibo</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ServiceInvoices from "@/services/ServiceInvoices";
import ServiceReceipts from "@/services/ServiceReceipts";
//import ServiceTypeevents from "@/services/ServiceTypeevents";
import ServiceLogin from "@/serviceGgest/ServiceLogin";
import ServiceDocumentos from "@/serviceGgest/ServiceDocumentos";
import ServiceListarClientes from "@/serviceGgest/ServiceListarClientes";
import ServiceArtigos from "@/serviceGgest/ServiceArtigos";
import ServiceClients from "@/services/ServiceClients";
import ServiceReceiptLogLines from "@/services/ServiceReceiptLogLines";
import ServiceReceiptLogs from "@/services/ServiceReceiptLogs";
import { mapState } from "vuex";
export default {
  name: "Invoices",
  data() {
    return {
      valid: true,
      show: false,
      loading: false,
      page: 1,
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido",
      },
      progressacriarrecibo: false,
      deondevemosartigos: null,
      client: {},
      payment_methods: [],
      payment_method_select: null,
      observations: '',

      arrayArtigosRecibos: [],

      //dados config
      erpmoeda: 'EUR',
      erpposto: 1,
      sacofaturarvalue: false,
      sacofaturar: false,
    };
  },
  methods: {
    formatDateTime(date) {
      const d = new Date(date);

      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const year = d.getFullYear();
      const hours = String(d.getHours()).padStart(2, '0');
      const minutes = String(d.getMinutes()).padStart(2, '0');

      return `${year}-${month}-${day} ${hours}:${minutes}`;
    },
    formatDate(date) {
      const d = new Date(date);

      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const year = d.getFullYear();

      return `${year}-${month}-${day}`;
    },
    formatTime(date) {
      const d = new Date(date);

      const hours = String(d.getHours()).padStart(2, '0');
      const minutes = String(d.getMinutes()).padStart(2, '0');

      return `${hours}:${minutes}`;
    },
    close() {
      this.show = false;
    },
    async open(client, arrayArtigos, deondevemosartigos) {
      this.deondevemosartigos = deondevemosartigos
      this.client = client;
      this.arrayArtigosRecibos = arrayArtigos
      this.loading = false;
      this.show = true;

      if (this.deondevemosartigos == 1) {
        //se o cliente tiver método de pagamento na ficha mete o do cliente
        if (this.client.payment_methods != null) {
          this.payment_method_select = this.client.payment_methods
        }
      }


    },
    async criarrecibo() {
        if (this.$refs.form.validate()) {

          if (this.deondevemosartigos == 1) {
            let recibocriadonoerp = {}
            this.progressacriarrecibo = true

            //iniciar logs receipts//iniciar logs receipts//iniciar logs receipts
            //iniciar logs receipts//iniciar logs receipts//iniciar logs receipts
            const dataagora = new Date()
            const iniciarreceiptlogs = {
              start: this.formatDateTime(dataagora),
              end: this.formatDateTime(dataagora),
              description: 'Logs recibos inseridos'
            }
            const iniciodereceiptlogs = (await ServiceReceiptLogs.createUpdate(iniciarreceiptlogs)).data

            // se vai para o ggest// se vai para o ggest// se vai para o ggest// se vai para o ggest
            // se vai para o ggest// se vai para o ggest// se vai para o ggest// se vai para o ggest
            if (this.sacofaturarvalue === false && this.$store.state.configerp.configuracoeserpestado == true) {
              let estadologs = 0

              //iniciar linha de logs invoices//iniciar linha de logs invoices//iniciar linha de logs invoices
              //iniciar linha de logs invoices//iniciar linha de logs invoices//iniciar linha de logs invoices
              const iniciarinvoiceloglines = {
                ClientId: this.client.id,
                ReceiptLogId: iniciodereceiptlogs.id
              }
              const iniciodereceiptloglines = (await ServiceReceiptLogLines.createUpdate(iniciarinvoiceloglines)).data


              // Se na ficha do cliente o cliente tem cliente de faturação
              // Se na ficha do cliente o cliente tem cliente de faturação
              if (this.client.erp_billing_number != null) {
                const atualizarreceiptloglines = {
                  id: iniciodereceiptloglines.id,
                  erp_billing_number: true
                }
                await ServiceReceiptLogLines.createUpdate(atualizarreceiptloglines)
                estadologs = 1
              }


              if (estadologs === 1) {
                //se fez login no erp
                //se fez login no erp
                const response = await ServiceLogin.login(process.env.VUE_APP_API_USER_GGEST, process.env.VUE_APP_API_PASS_GGEST);
                if (response.status === 200) {
                  this.$store.dispatch("setTokenGgest", response.data.token);
                  const atualizarreceiptloglines = {
                    id: iniciodereceiptloglines.id,
                    erp_login: true
                  }
                  await ServiceReceiptLogLines.createUpdate(atualizarreceiptloglines)
                  estadologs = 2
                }
              }



              let clientsggest = null
              if (estadologs === 2) {
                //ir buscar esse cliente ao erp e colocar os dados em baixo
                //ir buscar esse cliente ao erp e colocar os dados em baixo
                clientsggest = await ServiceListarClientes.listarClientes(this.client.erp_billing_number)
                if (clientsggest.data.dados.length && clientsggest.data.dados[0] != undefined) {
                  const atualizarreceiptloglines = {
                    id: iniciodereceiptloglines.id,
                    erp_billing_number_valid: true
                  }
                  await ServiceReceiptLogLines.createUpdate(atualizarreceiptloglines)
                  estadologs = 3
                }
              }

              let numerorecibogerado = ''
              let arraydefaturas = [];
              let arraydemetodopagamento = [];
              let numerolinha = 0;
              let totaldasfaturas = 0;
              if (estadologs === 3) {
                //criar linhas recibos
                //criar linhas recibos

                for (const artigo of this.arrayArtigosRecibos) {
                  let invoice = (await ServiceInvoices.show(artigo)).data
                  let codigoartigo = invoice.number_invoice.split(' ')[0]
                  let numeroartigo = invoice.number_invoice.split('/')[1].split(',')[0]
                  let serieartigo = invoice.number_invoice.split(' ')[1].split('/')[0]
                  let anoartigo = invoice.number_invoice.split(',')[1]

                  numerolinha = numerolinha + 1
                  arraydefaturas.push({
                    codigo: codigoartigo,
                    numero: numeroartigo,
                    serie: serieartigo,
                    ano: anoartigo,
                    valor_liquidar: invoice.price,
                    valor_desconto: 0
                  });

                  totaldasfaturas = totaldasfaturas + Number(invoice.price)
                };



                //this.payment_method_select tenho de tirar o que está a seguir ao traço - NUM-1 vai só NUM
                arraydemetodopagamento = [
                  {
                    forma_pag: this.payment_method_select.split("-")[0],
                    valor: totaldasfaturas
                  }
                ]

                //criar recibo
                const reciboerp = {
                  codigo: 'R',
                  entidade: clientsggest.data.dados[0].codigo,
                  data_doc: this.formatDate(new Date()),
                  data_venc: this.formatDate(new Date()),
                  posto: this.erpposto,
                  moeda: this.erpmoeda,
                  prazo_pagamento: clientsggest.data.dados[0].prazo_pagamento,
                  expedicao: clientsggest.data.dados[0].expedicao,
                  vendedor: clientsggest.data.dados[0].vendedor,
                  valor_por_conta: totaldasfaturas,
                  observacao: this.observations,
                  linhas: arraydefaturas,
                  metododepagamento: arraydemetodopagamento
                }


                //criar recibo no erp
                //criar recibo no erp
                recibocriadonoerp = await ServiceDocumentos.gravarRecibo(reciboerp)
                if (recibocriadonoerp.status === 200) {
                  numerorecibogerado = recibocriadonoerp.data.doc_codigo + " " + recibocriadonoerp.data.doc_serie + "/" + recibocriadonoerp.data.doc_numero + "," + recibocriadonoerp.data.doc_ano
                  const atualizarreceiptloglines = {
                    id: iniciodereceiptloglines.id,
                    erp_create_receipt: true
                  }
                  await ServiceReceiptLogLines.createUpdate(atualizarreceiptloglines)
                  estadologs = 4
                }
              }


              let documentominhabd = null
              if (estadologs === 4) {
                //criar documento na minha bd
                //criar documento na minha bd
                documentominhabd = await ServiceReceipts.createreceiptinvoice(this.client.id, this.arrayArtigosRecibos, totaldasfaturas, this.payment_method_select, 'R', numerorecibogerado, this.observations)
                if (documentominhabd.status === 200) {
                  const atualizarreceiptloglines = {
                    id: iniciodereceiptloglines.id,
                    clinic_create_receipt: true
                  }
                  await ServiceReceiptLogLines.createUpdate(atualizarreceiptloglines)
                  estadologs = 5
                }
              }




              arraydefaturas = [];
              numerolinha = 0;
              estadologs = 0;

            } else {
              // se não vai para o ggest// se não vai para o ggest// se não vai para o ggest
              // se não vai para o ggest// se não vai para o ggest// se não vai para o ggest

              //iniciar linha de logs receipts//iniciar linha de logs receipts//iniciar linha de logs receipts
              //iniciar linha de logs receipts//iniciar linha de logs receipts//iniciar linha de logs receipts
              const iniciarinvoiceloglines = {
                ClientId: this.client.id,
                ReceiptLogId: iniciodereceiptlogs.id
              }
              const iniciodereceiptloglines = (await ServiceReceiptLogLines.createUpdate(iniciarinvoiceloglines)).data

              // dar fatura como paga na minha bd// dar fatura como paga na minha bd// dar fatura como paga na minha bd
              // dar fatura como paga na minha bd// dar fatura como paga na minha bd// dar fatura como paga na minha bd
              const numerorecibogerado = 1
              let totaldasfaturas = 0;
              for (const artigo of this.arrayArtigosRecibos) {
                let invoice = (await ServiceInvoices.show(artigo)).data
                totaldasfaturas = totaldasfaturas + Number(invoice.price)
              };

              const recibominhabd = await ServiceReceipts.createreceiptinvoice(this.client.id, this.arrayArtigosRecibos, totaldasfaturas, this.payment_method_select, 'R', numerorecibogerado, this.observations);

              if (recibominhabd.status === 200) {
                const atualizarreceiptloglines = {
                  id: iniciodereceiptloglines.id,
                  clinic_create_receipt: true
                }
                await ServiceReceiptLogLines.createUpdate(atualizarreceiptloglines)
              }
            }
            //finalizar logs receipts//finalizar logs receipts//finalizar logs receipts
            //finalizar logs receipts//finalizar logs receipts//finalizar logs receipts
            const dataagora2 = new Date()
            const finalizarreceiptlogs = {
              id: iniciodereceiptlogs.id,
              end: this.formatDateTime(dataagora2)
            }
            await ServiceReceiptLogs.createUpdate(finalizarreceiptlogs)
            this.$emit('handleReceipt');
            this.show = false;
            this.onDialogClose2(this.show)
          } else if (this.deondevemosartigos == 2) {
            let recibocriadonoerp = {}
            this.progressacriarrecibo = true
            const gruposPorCliente = this.arrayArtigosRecibos.reduce((acc, item) => {
                const clientid = item.ClientId;
                if (!acc[clientid]) {
                    acc[clientid] = {
                        clientId: clientid,
                        clientName: item.ClientName,
                        artigos: []
                    };
                }
                acc[clientid].artigos.push(item);
                return acc;
            }, {});

            //iniciar logs invoices//iniciar logs invoices//iniciar logs invoices
            //iniciar logs invoices//iniciar logs invoices//iniciar logs invoices
            const dataagora = new Date()
            const iniciarreceiptlogs = {
              start: this.formatDateTime(dataagora),
              end: this.formatDateTime(dataagora),
              description: 'Logs documentos inseridos'
            }
            const iniciodereceiptlogs = (await ServiceReceiptLogs.createUpdate(iniciarreceiptlogs)).data

            for (const clientid in gruposPorCliente) {
                const cliente = gruposPorCliente[clientid];
                const itemsDoCliente = cliente.artigos

                // se vai para o ggest// se vai para o ggest// se vai para o ggest// se vai para o ggest
                // se vai para o ggest// se vai para o ggest// se vai para o ggest// se vai para o ggest
                if (this.sacofaturarvalue === false && this.$store.state.configerp.ativconfiguracoeserpestadoo == true) {
                  let estadologs = 0

                  //tenho de ir buscar o cliente com este id
                  const clientedados = (await ServiceClients.show(cliente.clientId)).data
                  // se vai para o ggest

                  //iniciar linha de logs invoices//iniciar linha de logs invoices//iniciar linha de logs invoices
                  //iniciar linha de logs invoices//iniciar linha de logs invoices//iniciar linha de logs invoices
                  const iniciarinvoiceloglines = {
                    ClientId: clientedados.id,
                    ReceiptLogId: iniciodereceiptlogs.id
                  }
                  const iniciodereceiptloglines = (await ServiceReceiptLogLines.createUpdate(iniciarinvoiceloglines)).data

                  // Se na ficha do cliente o cliente tem cliente de faturação
                  // Se na ficha do cliente o cliente tem cliente de faturação
                  if (clientedados.erp_billing_number != null) {
                    const atualizarreceiptloglines = {
                      id: iniciodereceiptloglines.id,
                      erp_billing_number: true
                    }
                    await ServiceReceiptLogLines.createUpdate(atualizarreceiptloglines)
                    estadologs = 1
                  }


                  if (estadologs === 1) {
                    //se fez login no erp
                    //se fez login no erp
                    const response = await ServiceLogin.login(process.env.VUE_APP_API_USER_GGEST, process.env.VUE_APP_API_PASS_GGEST);
                    if (response.status === 200) {
                      this.$store.dispatch("setTokenGgest", response.data.token);
                      const atualizarreceiptloglines = {
                        id: iniciodereceiptloglines.id,
                        erp_login: true
                      }
                      await ServiceReceiptLogLines.createUpdate(atualizarreceiptloglines)
                      estadologs = 2
                    }
                  }


                  let clientsggest = null
                  if (estadologs === 2) {
                    //ir buscar esse cliente ao erp e colocar os dados em baixo
                    //ir buscar esse cliente ao erp e colocar os dados em baixo
                    clientsggest = await ServiceListarClientes.listarClientes(clientedados.erp_billing_number)
                    if (clientsggest.data.dados.length === 1 && clientsggest.data.dados[0] != undefined && estadologs === 2) {
                      const atualizarreceiptloglines = {
                        id: iniciodereceiptloglines.id,
                        erp_billing_number_valid: true
                      }
                      await ServiceReceiptLogLines.createUpdate(atualizarreceiptloglines)
                      estadologs = 3
                    }
                  }


                  let resultadosartigosvalidos = null
                  if (estadologs === 3) {
                    //fazer um distinct dos artigos que estão no array para ir ao erp ver se são válidos
                    //fazer um distinct dos artigos que estão no array para ir ao erp ver se são válidos
                    const artigosUnicos = itemsDoCliente.filter((artigo, index, self) =>
                        index === self.findIndex(
                            (a) => a.TypeeventArticlecodeerp === artigo.TypeeventArticlecodeerp
                        )
                    );
                    // ir ao erp ver se todos os artigos são válidos
                    // ir ao erp ver se todos os artigos são válidos
                    resultadosartigosvalidos = await Promise.all(
                      artigosUnicos.map(async (artigo) => {
                        const artigoggest = await ServiceArtigos.listarArtigosCodigo(artigo.TypeeventArticlecodeerp)
                        if (artigoggest.data.dados[0] === undefined) {
                          return {
                            resultado: false
                          };
                        }
                        return {
                          resultado: true,
                          codigo: artigoggest.data.dados[0].codigo,
                          iva: artigoggest.data.dados[0].iva1
                        };
                      })
                    );
                    const todosResultadosValidos = resultadosartigosvalidos.every((resultado) => resultado.resultado === true);
                    if (todosResultadosValidos === true && estadologs === 3) {
                      const atualizarreceiptloglines = {
                        id: iniciodereceiptloglines.id,
                        erp_all_articles_valid: true
                      }
                      await ServiceReceiptLogLines.createUpdate(atualizarreceiptloglines)
                      estadologs = 4
                    }
                  }


                  let numerofaturagerada = ''
                  let arraydeartigos = [];
                  let arraydemetodopagamento = [];
                  let numerolinha = 0;
                  let totaldosartigosfatura = 0;
                  if (estadologs === 4) {
                    //criar linhas faturas
                    //criar linhas faturas
                    let iva = 0;
                    itemsDoCliente.forEach((artigo) => {
                      //ir buscar o iva do erp do artigo (está a pegar nos artigos distinct e ver o iva deles)
                      //ir buscar o iva do erp do artigo (está a pegar nos artigos distinct e ver o iva deles)
                      const artigoValido = resultadosartigosvalidos.find(
                        (artigoValido) => artigoValido.codigo === artigo.TypeeventArticlecodeerp
                      );
                      if (artigoValido) {
                        iva = artigoValido.iva
                      }
                      numerolinha = numerolinha + 1
                      arraydeartigos.push({
                        linha: numerolinha,
                        artigo: artigo.TypeeventArticlecodeerp,
                        quantidade: artigo.Quantity,
                        preco: artigo.Price,
                        data_mov: new Date().toISOString(),
                        cod_iva: iva,
                        descricao: artigo.TypeeventName
                        //descricao: artigo.TypeeventName + " " + artigo.PlanName + " - "  + artigo.Cycle
                      });

                      totaldosartigosfatura = totaldosartigosfatura + Number(artigo.Price)
                    });

                    //this.payment_method tenho de tirar o que está a seguir ao traço - NUM-1 vai só NUM
                    arraydemetodopagamento = [
                      {
                        forma_pag: this.payment_method.split("-")[0],
                        valor: totaldosartigosfatura
                      }
                    ]

                    //criar fatura
                    const faturaerp = {
                        tipodocumentoabreviatura: this.erptypedocumentabbreviation,
                        cliente_codigo: clientsggest.data.dados[0].codigo,
                        cliente_descricao: clientsggest.data.dados[0].descricao,
                        cliente_descricao2: clientsggest.data.dados[0].descricao2,
                        cliente_morada: clientsggest.data.dados[0].morada,
                        cliente_morada2: clientsggest.data.dados[0].morada2,
                        cliente_codpostal: clientsggest.data.dados[0].codpostal,
                        cliente_pais: clientsggest.data.dados[0].pais,
                        cliente_prefixo: clientsggest.data.dados[0].prefixo,
                        cliente_contribuinte: clientsggest.data.dados[0].contribuinte,
                        iva_inc: this.erpfaturasivaincluido,
                        moeda: this.erpmoeda,
                        posto: this.erpposto,
                        prazo_pagamento: clientsggest.data.dados[0].prazo_pagamento,
                        vendedor: clientsggest.data.dados[0].vendedor,
                        expedicao: clientsggest.data.dados[0].expedicao,
                        data_carga: this.formatDate(new Date()),
                        hora_carga: this.formatTime(new Date()),
                        morada_carga: this.erpmoradaempresa,
                        morada2_Carga: this.erpmoradaempresa2,
                        codpostal_carga: this.erpcodigopostalempresa,
                        pais_carga: this.erppaisempresa,
                        data_descarga: this.formatDate(new Date()),
                        hora_descarga: this.formatTime(new Date()),
                        morada_descarga: clientsggest.data.dados[0].morada,
                        morada2_descarga: clientsggest.data.dados[0].morada2,
                        codpostal_descarga: clientsggest.data.dados[0].codpostal,
                        pais_descarga: clientsggest.data.dados[0].espaco_fiscal,
                        linhas: arraydeartigos,
                        metododepagamento: arraydemetodopagamento
                    }

                    //criar recibo no erp
                    //criar recibo no erp
                    recibocriadonoerp = await ServiceDocumentos.gravarDocumento(faturaerp)
                    if (recibocriadonoerp.status === 200) {
                      numerofaturagerada = recibocriadonoerp.data.doc_codigo + " " + recibocriadonoerp.data.doc_serie + "/" + recibocriadonoerp.data.doc_numero
                      const atualizarreceiptloglines = {
                        id: iniciodereceiptloglines.id,
                        erp_create_receipt: true
                      }
                      await ServiceReceiptLogLines.createUpdate(atualizarreceiptloglines)
                      estadologs = 5
                    }
                  }


                  let documentominhabd = null
                  if (estadologs === 5) {
                    //criar documento na minha bd
                    //criar documento na minha bd
                    documentominhabd = await ServiceInvoices.createreceiptinvoice(cliente.clientId, itemsDoCliente, this.payment_method, this.type_document, this.invoice_pay, numerofaturagerada)
                    if (documentominhabd.status === 200) {
                      const atualizarreceiptloglines = {
                        id: iniciodereceiptloglines.id,
                        clinic_create_receipt: true
                      }
                      await ServiceReceiptLogLines.createUpdate(atualizarreceiptloglines)
                      estadologs = 6
                    }
                  }


                  let modelodeimpressaodocumento = null
                  if (estadologs === 6) {
                    //ver no ggest qual é o modelo de impressão do documento criado
                    //ver no ggest qual é o modelo de impressão do documento criado
                    modelodeimpressaodocumento = await ServiceDocumentos.listarModelosDocumento(this.type_document, this.erpposto)
                    if (modelodeimpressaodocumento.status === 200) {
                      const atualizarreceiptloglines = {
                        id: iniciodereceiptloglines.id,
                        erp_print_template_valid: true
                      }
                      await ServiceReceiptLogLines.createUpdate(atualizarreceiptloglines)
                      estadologs = 7
                    }
                  }



                  let base64 = ''
                  let nomeficheiro = ''
                  let idinvoice = ''
                  if (estadologs === 7) {
                    //enviar dados para o ggest para gerar pdf
                    //enviar dados para o ggest para gerar pdf
                    const gerarpdfggest = {
                      posto: this.erpposto,
                      modelo_codigo: modelodeimpressaodocumento.data.dados[0].codigo,
                      modelo_numero_vias: modelodeimpressaodocumento.data.dados[0].numero_vias,
                      modelo_iniciar_via: modelodeimpressaodocumento.data.dados[0].iniciar_via,
                      doc_codigo: recibocriadonoerp.data.doc_codigo,
                      doc_numero: recibocriadonoerp.data.doc_numero,
                      doc_serie: recibocriadonoerp.data.doc_serie,
                      doc_ano: recibocriadonoerp.data.doc_ano,
                      doc_entidade: recibocriadonoerp.data.doc_entidade
                    }
                    // ir buscar o documento em base64
                    const pdfggestbase = await ServiceDocumentos.imprimirDocumento(gerarpdfggest)
                    if (pdfggestbase.status === 200) {
                      const atualizarreceiptloglines = {
                        id: iniciodereceiptloglines.id,
                        erp_base64_valid: true
                      }
                      await ServiceReceiptLogLines.createUpdate(atualizarreceiptloglines)
                      base64 = pdfggestbase.data.pdf
                      nomeficheiro = numerofaturagerada
                      idinvoice = documentominhabd.data.id
                      estadologs = 8
                    }
                  }




                  arraydeartigos = [];
                  numerolinha = 0;
                  estadologs = 0;

                } else {
                  // se não vai para o ggest// se não vai para o ggest// se não vai para o ggest
                  // se não vai para o ggest// se não vai para o ggest// se não vai para o ggest

                  //iniciar linha de logs invoices//iniciar linha de logs invoices//iniciar linha de logs invoices
                  //iniciar linha de logs invoices//iniciar linha de logs invoices//iniciar linha de logs invoices
                  const iniciarinvoiceloglines = {
                    ClientId: cliente.clientId,
                    ReceiptLogId: iniciodereceiptlogs.id
                  }
                  const iniciodereceiptloglines = (await ServiceReceiptLogLines.createUpdate(iniciarinvoiceloglines)).data

                  // introduzir recibo na minha base de dados// introduzir recibo na minha base de dados// introduzir recibo na minha base de dados
                  // introduzir recibo na minha base de dados// introduzir recibo na minha base de dados// introduzir recibo na minha base de dados
                  const recibominhabd = await ServiceInvoices.createreceiptinvoice(this.receiptinvoice, this.payment_method_select);
                  if (recibominhabd.status === 200) {
                    const atualizarreceiptloglines = {
                      id: iniciodereceiptloglines.id,
                      clinic_create_receipt: true
                    }
                    await ServiceReceiptLogLines.createUpdate(atualizarreceiptloglines)
                  }
                }
            }
            //finalizar logs invoices//finalizar logs invoices//finalizar logs invoices
            //finalizar logs invoices//finalizar logs invoices//finalizar logs invoices
            const dataagora2 = new Date()
            const finalizarreceiptlogs = {
              id: iniciodereceiptlogs.id,
              end: this.formatDateTime(dataagora2)
            }
            await ServiceReceiptLogs.createUpdate(finalizarreceiptlogs)
            this.$emit('handleReceipt');
            this.show = false;
            this.onDialogClose2(this.show)
            this.$store.dispatch("setSnackbar", {});
          }
        }

    },
    onDialogClose2(isOpen) {
      if (!isOpen) {
        this.arrayArtigosRecibos = null
        this.progressacriarrecibo = false
        this.sacofaturarvalue = false
        this.observations = ''
        this.payment_method_select = null
        this.type_document = null
        this.type_document_payment_method = null
        this.type_document_name = null
        this.documentosporemailvalue =  false
      }
    },
  },
  async mounted() {
    this.payment_methods = config.PAYMENT_METHODS;
    this.erpposto = config.POSTO_ERP
    this.erpmoeda = config.POSTO_MOEDA
    this.sacofaturar = config.SACO_FATURAR

    this.erpmoradaempresa = config.GGEST_MORADA_EMPRESA
    this.erpmoradaempresa2 = config.GGEST_MORADA2_EMPRESA
    this.erpcodigopostalempresa = config.GGEST_CODIGOPOSTAL_EMPRESA
    this.erppaisempresa = config.GGEST_PAIS_EMPRESA
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
