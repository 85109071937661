<template>
  <v-layout ref="layout" v-if="$store.state.user.see_everyones_appointments_module === true">
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-btn v-if="!phone" fixed dark fab bottom right large color="indigo" @click.stop="$refs.eventDialog.open()">
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-btn v-if="!phone" fixed dark fab bottom left large color="success" @click.stop="drawer = !drawer">
      <v-icon>mdi-magnify</v-icon>
    </v-btn>

    <v-navigation-drawer v-model="drawer" :mini-variant.sync="mini" :app="phone"
      style="height: 100%;background-color:#ededed;">

      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-icon v-if="!mini" x-large>mdi-account-circle</v-icon>
          <v-btn v-else icon>
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-list-item-avatar>

        <v-list-item-title>Funcionários</v-list-item-title>

        <v-btn v-if="!phone" icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense
        :style="(rooms.length != 1 && !phone) ? 'overflow-y: auto;height: ' + (paneheight * 0.5 - 58) + 'px;' : ''">
        <v-list-item>
          <v-list-item-action>
            <v-checkbox @click="ToggleUserCalendar()" :input-value="bulkSelect" color="grey"></v-checkbox>
          </v-list-item-action>
          <v-text-field label="Filtro" v-model="filterUserKeyWord" @input="FilterUsers()" clearable></v-text-field>
        </v-list-item>

        <v-list-item v-for="item in filteredUsers" :key="item.id" link @click="hideEmploye(item)">
          <v-list-item-action>
            <v-checkbox :input-value="item.valid" :color="item.color"></v-checkbox>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list-item v-if="rooms.length != 1" class="px-2">
        <v-list-item-avatar>
          <v-icon v-if="!mini" x-large>mdi-home-outline</v-icon>
          <v-btn v-else icon>
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-list-item-avatar>

        <v-list-item-title>Salas</v-list-item-title>

        <v-btn v-if="!phone" icon @click.stop="mini = !mini">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list v-if="rooms.length != 1" dense
        :style="!phone ? 'overflow-y: auto;height: ' + (paneheight * 0.5 - 58) + 'px;' : ''">
        <!-- <v-list v-if="rooms.length != 1" dense> -->

        <v-list-item>
          <v-list-item-action>
            <v-checkbox @click="ToggleRoomCalendar()" :input-value="bulkSelectRooms" color="grey"></v-checkbox>
          </v-list-item-action>
          <v-text-field label="Filtro" v-model="filterRoomKeyWord" @input="FilterRooms()" clearable></v-text-field>
        </v-list-item>

        <v-list-item v-for="item in filteredRooms" :key="item.id" link @click="hideRoom(item)">
          <v-list-item-action>
            <v-checkbox :input-value="item.valid" :color="item.color"></v-checkbox>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>{{item.identification}} - {{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

      </v-list>

    </v-navigation-drawer>
    <v-container fluid class="grey lighten-5" style="padding:0">
      <v-row no-gutters style="height: 100%">
        <v-col cols="12">
          <v-sheet tile height="56" color="grey lighten-3" class="d-flex">
            <v-toolbar height="56" flat>

              <v-menu v-if="phone" right>
                <template v-slot:activator="{ on }">
                  <v-btn outlined color="grey darken-2" v-on="on">
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="setToday">
                    <v-list-item-title>Hoje</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="$refs.eventDialog.open()">
                    <v-list-item-title>Adicionar</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="drawer = !drawer">
                    <v-list-item-title>Filtro</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn v-else outlined class="ma-2" color="grey darken-2" @click="setToday">Hoje</v-btn>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="ma-2" @click="PrevMonth" v-on="on">
                    <v-icon>mdi-chevron-left</v-icon>
                  </v-btn>
                </template>
                <span>{{ prevDate }}</span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="ma-2" @click="NextMonth" v-on="on">
                    <v-icon>mdi-chevron-right</v-icon>
                  </v-btn>
                </template>
                <span>{{ nextDate }}</span>
              </v-tooltip>

              <v-toolbar-title class="ma-2">{{ title }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu right>
                <template v-slot:activator="{ on }">
                  <v-btn outlined color="grey darken-2" v-on="on">
                    <span>{{ typeToLabel[type] }}</span>
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="type = 'month'">
                    <v-list-item-title>Mês</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'week'">
                    <v-list-item-title>Semana</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="type = 'day'">
                    <v-list-item-title>Dia</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-menu right>
                <template v-slot:activator="{ on }">
                  <v-btn outlined color="grey darken-2" v-on="on" class="ml-2">
                    <span>{{ typeToLabelIntervalTime[intervalMinutes] }}</span>
                    <v-icon right>mdi-menu-down</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="intervalMinutes = 15">
                    <v-list-item-title>15min</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="intervalMinutes = 30">
                    <v-list-item-title>30min</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="intervalMinutes = 60">
                    <v-list-item-title>1h</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-toolbar>
          </v-sheet>
          <v-sheet :style="'height: ' + (paneheight - 56) + 'px;'">
            <v-calendar ref="calendar" locale="pt-PT" v-model="focus" :weekdays="weekday" :type="type" :events="events"
              :event-color="getEventColor" :event-overlap-mode="mode" :event-overlap-threshold="30" @click:date="viewDay"
              @change="setTitle" :first-time=$config.Calendar_First_Time :interval-count="intervalCount" :interval-minutes="intervalMinutes"
              @click:event="showEvent" @click:time="createEventClick"
              @mousedown:event="startDragMover" @mousedown:time="startTimeMover" @mousemove:time="mouseMoveMover" @mouseup:time="endDragMover" @mouseleave.native="cancelDragMover">
              <template v-slot:event="{ event }">
                <!--Se não tiver clientes na marcação-->
                <template v-if="event.ClientEvents.length == 0">
                  <!--Se for uma marcação normal-->
                  <template v-if="event.iseventnotebreak === 1">
                    <span style="padding-left: 5px;" v-if="type === 'month'"
                      :style="rooms.length != 1 && colorrooms == 1 ? `background-image: linear-gradient(110deg,${event.userColor}  50%, ${event.roomColor} 50%);white-space: nowrap;overflow: hidden;padding-left: 10px;text-overflow: ellipsis;display: inline-block;width: 100%;height: 100%;` : `background-color: ${event.userColor} ;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;width: 100%;height: 100%;`">
                      <div>
                        {{event.roomIdentification + " - " + event.typeeventName}}
                      </div>
                    </span>
                    <span style="padding-left: 5px;" v-else
                      :style="rooms.length != 1  && colorrooms == 1 ? `white-space: nowrap;padding-left: 10px;overflow: hidden;text-overflow: ellipsis;display: inline-block;width: 100%;height: 100%;background-image: linear-gradient(0deg, ${event.roomColor} 50%, ${event.userColor} 50%);` :
                        `white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;width: 100%;height: 100%;background-color: ${event.userColor};`">
                        <div>
                          {{event.roomIdentification}}
                        </div>
                        <div>
                          {{event.typeeventName}}
                        </div>
                        <div>
                          {{ formatTime(event.start) }} - {{ formatTime(event.end) }}
                        </div>
                        <div>
                          {{event.details}}
                        </div>
                    </span>
                  </template>
                  <!--Se for uma pausa (userbreak)-->
                  <template v-else-if="event.iseventnotebreak === 3">
                    <span style="padding-left: 5px;" v-if="type === 'month'"
                      :style="rooms.length != 1 && colorrooms == 1 ? `background-image: linear-gradient(110deg,${event.roomColor}  50%, ${event.roomColor} 50%);white-space: nowrap;overflow: hidden;padding-left: 10px;text-overflow: ellipsis;display: inline-block;width: 100%;height: 100%;` : `background-color: ${event.roomColor} ;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;width: 100%;height: 100%;`">
                      <div>
                        {{event.userName + " - " + event.details}}
                      </div>
                    </span>
                    <span style="padding-left: 5px;" v-else
                      :style="rooms.length != 1  && colorrooms == 1 ? `white-space: nowrap;padding-left: 10px;overflow: hidden;text-overflow: ellipsis;display: inline-block;width: 100%;height: 100%;background-image: linear-gradient(0deg, ${event.roomColor} 50%, ${event.roomColor} 50%);` :
                        `white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;width: 100%;height: 100%;background-color: ${event.roomColor};`">
                        <div>
                          {{event.userName}}
                        </div>
                        <div>
                          {{event.details}}
                        </div>
                    </span>
                  </template>
                  <!--Se for uma nota-->
                  <template v-else>
                    <span style="padding-left: 5px;" v-if="type === 'month'"
                      :style="rooms.length != 1 && colorrooms == 1 ? `background-image: linear-gradient(110deg,${event.roomColor}  50%, ${event.roomColor} 50%);white-space: nowrap;overflow: hidden;padding-left: 10px;text-overflow: ellipsis;display: inline-block;width: 100%;height: 100%;` : `background-color: ${event.roomColor} ;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;width: 100%;height: 100%;`">
                      <div>
                        {{event.roomName + " - " + event.details}}
                      </div>
                    </span>
                    <span style="padding-left: 5px;" v-else
                      :style="rooms.length != 1  && colorrooms == 1 ? `white-space: nowrap;padding-left: 10px;overflow: hidden;text-overflow: ellipsis;display: inline-block;width: 100%;height: 100%;background-image: linear-gradient(0deg, ${event.roomColor} 50%, ${event.roomColor} 50%);` :
                        `white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;width: 100%;height: 100%;background-color: ${event.roomColor};`">
                        <div>
                          {{event.roomName}}
                        </div>
                        <div>
                          {{event.details}}
                        </div>
                    </span>
                  </template>
                </template>
                <template v-else-if="event.ClientEvents.length != 1">
                  <span style="padding-left: 5px;" v-if="type === 'month'"
                    :style="rooms.length != 1 && colorrooms == 1 ? `background-image: linear-gradient(110deg,${event.userColor}  50%, ${event.roomColor} 50%);white-space: nowrap;overflow: hidden;padding-left: 10px;text-overflow: ellipsis;display: inline-block;width: 100%;height: 100%;` : `background-color: ${event.userColor} ;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;width: 100%;height: 100%;`">
                    <div>
                      {{ event.roomIdentification + ' - Marcação com ' + event.ClientEvents.length }} {{ clientTypeP }}
                    </div>
                    <div>
                      {{ event.typeeventName }}
                    </div>
                    <div>
                      {{ formatTime(event.start) }} - {{ formatTime(event.end) }}
                    </div>
                    <div>
                      {{event.details}}
                    </div>
                  </span>
                  <span style="padding-left: 5px;" v-else
                    :style="rooms.length != 1  && colorrooms == 1 ? `white-space: nowrap;padding-left: 10px;overflow: hidden;text-overflow: ellipsis;display: inline-block;width: 100%;height: 100%;background-image: linear-gradient(0deg, ${event.roomColor} 50%, ${event.userColor} 50%);` :
                          `white-space: nowrap;overflow: hidden;text-overflow: ellipsis;display: inline-block;width: 100%;height: 100%;background-color: ${event.userColor};`">
                    <div>
                      {{ event.roomIdentification + ' - Marcação com ' + event.ClientEvents.length }} {{ clientTypeP }}
                    </div>
                    <div>
                      {{ event.typeeventName }}
                    </div>
                    <div>
                      {{ formatTime(event.start) }} - {{ formatTime(event.end) }}
                    </div>
                    <div>
                      {{event.details}}
                    </div>
                  </span>
                </template>
                <template v-else>
                  <span v-if="type === 'month'" :style="{paddingLeft: '5px',borderLeft: '10px solid',borderLeftColor: statusColor(event.ClientEvents[0].status),
                        ...(rooms.length != 1 && colorrooms == 1
                        ? {
                            backgroundImage: `linear-gradient(110deg, ${event.userColor} 50%, ${event.roomColor} 50%)`
                          }
                        : {
                            backgroundColor: event.userColor
                          }
                      ),whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis',display: 'inline-block',width: '100%',height: '100%'}">
                      <div>
                        {{ event.roomIdentification + ' - ' + event.ClientEvents[0].Client.name }}
                      </div>
                      <div>
                        {{ event.typeeventName }}
                      </div>
                      <div>
                        {{ formatTime(event.start) }} - {{ formatTime(event.end) }}
                      </div>
                      <div>
                        {{event.details}}
                      </div>
                      <v-tooltip top v-if="event.ClientEvents[0].sms == true">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" color="green">mdi-message-text</v-icon>
                        </template>
                        <span>{{ formatDate(event.ClientEvents[0].sms_date) }}</span>
                      </v-tooltip>
                      <v-tooltip top v-else>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" color="red">mdi-message-text</v-icon>
                        </template>
                        <span>SMS não enviado</span>
                      </v-tooltip>
                  </span>

                  <span v-else :style="{paddingLeft: '10px',borderLeft: '10px solid',borderLeftColor: statusColor(event.ClientEvents[0].status),
                        ...(rooms.length != 1 && colorrooms == 1
                        ? {
                            backgroundImage: `linear-gradient(0deg, ${event.roomColor} 50%, ${event.userColor} 50%)`
                          }
                        : {
                            backgroundColor: event.userColor
                          }
                      ),whiteSpace: 'nowrap',overflow: 'hidden',textOverflow: 'ellipsis',display: 'inline-block',width: '100%',height: '100%'}">
                      <div>
                        {{ event.roomIdentification + ' - ' + event.ClientEvents[0].Client.name }}
                      </div>
                      <div>
                        {{ event.typeeventName }}
                      </div>
                      <div>
                        {{ formatTime(event.start) }} - {{ formatTime(event.end) }}
                      </div>
                      <div>
                        {{event.details}}
                      </div>
                      <v-tooltip top v-if="event.ClientEvents[0].sms == true">
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" color="green">mdi-message-text</v-icon>
                        </template>
                        <span>{{ formatDate(event.ClientEvents[0].sms_date) }}</span>
                      </v-tooltip>
                      <v-tooltip top v-else>
                        <template v-slot:activator="{ on }">
                          <v-icon v-on="on" color="red">mdi-message-text</v-icon>
                        </template>
                        <span>SMS não enviado</span>
                      </v-tooltip>
                  </span>
                </template>
              </template>
            </v-calendar>
          </v-sheet>
        </v-col>
      </v-row>
      <event-dialog ref="eventDialog" :rooms="rooms" :users="users" :typeevents="typeevents" :groups="groups" @handleEvent="handleEvent"
        @handleDelete="handleDelete" @handleReplacementEvent="handleReplacementEvent" @handleReplacementEventClose="handleReplacementEventClose"></event-dialog>
    </v-container>
  </v-layout>
</template>

<script>
import PopUpSelector from "@/components/common/PopUpSelector";
import ServiceCalendars from "@/services/ServiceCalendars";
import { isMobile, getUsersAndRooms, getTypeevents, getGroups} from "@/components/Utils";
import EventDialog from "@/components/common/EventDialog.vue";
import _ from "lodash";

export default {
  name: "Calendar",
  components: {
    PopUpSelector
  },
  components: {
    EventDialog
  },
  data: () => ({
    settings: [],

    addingNewEvent: false,
    formValid: false,
    userPhasesEnabled: false,
    userProjectsEnabled: false,
    isLoadingUserProjects: false,
    eventDetails: "",
    selectedPatients: null,
    selectedPhase: null,
    clients: [],

    rooms: [],
    filteredRooms: [],
    filterRoomKeyWord: '',

    userPhases: [],
    phone: false,
    startDate: "",
    toggleStartDate: false,
    endDate: "",
    toggleEndDate: false,
    dialogCustomEvent: false,
    eventStart: "",
    toggleEventStart: false,
    eventEnd: "",
    toggleEventEnd: false,
    selectedDay: "",
    fab: false,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,

    typeToLabel: {
      month: "Mês",
      week: "Semana",
      day: "Dia"
    },

    typeToLabelIntervalTime: {
      15: 15,
      30: 30,
      60: 60
    },

    drawer: null,
    mini: false,
    focus: "",
    mode: "stack",
    weekday: [0, 1, 2, 3, 4, 5, 6],
    includeInactive: false,
    selectedUser: null,
    selectedRoom: null,
    selectedMachine: null,
    loading: true,
    showDialog: false,

    users: [],
    filteredUsers: [],
    filterUserKeyWord: '',


    typeevents: [],

    groups: [],

    paneheight: 0,
    dark: false,
    startMenu: false,
    endMenu: false,
    nowMenu: false,
    minWeeks: 1,
    now: null,
    type: "month",
    intervalMinutes: 60,
    intervalCount: 24,
    types: [
      { text: "Mês", value: "month" },
      { text: "Semana", value: "week" },
      { text: "Dia", value: "day" }
    ],
    color: "primary",
    months: [
      "Janeiro",
      "Fevereiro",
      "Março",
      "Abril",
      "Maio",
      "Junho",
      "Julho",
      "Agosto",
      "Setembro",
      "Outubro",
      "Novembro",
      "Dezembro"
    ],
    show: false,
    colors: [],
    status: [],
    eventstatus: [],
    monthsProcessed: [],
    events: [],
    fullEvents: [],
    start: null,
    end: null,
    title: "",
    prevDate: "Mês Anterior",
    nextDate: "Mês Seguinte",
    bulkSelect: true,
    bulkSelectRooms: true,
    calMoveDir: null,
    clientTypeP: '',
    colorrooms: null,
    newEventReplacement: {},
    newEventReplacementClients: [],
    replacementeventstatus: false,

    //variáveis mover evento
    moverEvent: null,
    moverTime: null
  }),
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    formatTime(dateString) {
      const date = new Date(dateString);

      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${hours}:${minutes}`;
    },
    FilterRooms() {
      if (!this.filterRoomKeyWord) {
        this.filteredRooms = this.rooms;
      }
      this.filteredRooms = _.filter(this.rooms, (o) => { return o.name.toLowerCase().includes(this.filterRoomKeyWord.toLowerCase()); });
    },
    FilterUsers() {
      if (!this.filterUserKeyWord) {
        this.filteredUsers = this.users;
      }
      this.filteredUsers = _.filter(this.users, (o) => { return o.name.toLowerCase().includes(this.filterUserKeyWord.toLowerCase()); });
    },
    async setTitle({ start, end }) {
      if (!start || !end) {
        return;
      }
      const startMonth = this.months[start.month - 1];
      const endMonth = this.months[end.month - 1];
      //   const suffixMonth = startMonth === endMonth ? "" : endMonth;
      const suffixMonth = endMonth;
      const startYear = start.year;
      const endYear = end.year;
      //   const suffixYear = startYear === endYear ? "" : endYear;
      const suffixYear = endYear;
      const startDay = start.day;
      const endDay = end.day;

      switch (this.type) {
        case "month":
          this.prevDate = "Mês Anterior";
          this.nextDate = "Mês Seguinte";
          this.title = `${startMonth} ${startYear}`;
          break;
        case "week":
          this.prevDate = "Semana Anterior";
          this.nextDate = "Semana Seguinte";
          this.title = `${startDay} ${startMonth} ${startYear} - ${endDay} ${suffixMonth} ${suffixYear}`;
          break;
        case "day":
          this.prevDate = "Dia Anterior";
          this.nextDate = "Dia Seguinte";
          this.title = `${startDay} ${startMonth} ${startYear}`;
          break;
      }

      if (this.calMoveDir !== null) {
        this.getCalendar();
      }
    },
    setToday() {
      this.focus = this.today;
    },
    hideEmploye(item) {
      item.valid = !item.valid;

      let cutElements;
      if (item.valid) {
        cutElements = _.filter(this.fullEvents, { UserId: item.id });

        cutElements.forEach(element => {
          if (_.findIndex(this.events, ['id', element.id]) == -1) {
            this.events.push(element);
          }
        });
      } else {
        let keepRooms = _.filter(this.rooms, ['valid', true]);
        this.events = _.filter(this.events, o => {
          return (o.UserId != item.id || _.findIndex(keepRooms, ['id', o.RoomId]) != -1);
        });
      }
    },
    hideRoom(item) {
      item.valid = !item.valid;

      let cutElements;
      if (item.valid) {
        cutElements = _.filter(this.fullEvents, { RoomId: item.id });

        cutElements.forEach(element => {
          if (_.findIndex(this.events, ['id', element.id]) == -1) {
            this.events.push(element);
          }
        });
      } else {
        let keepEmployees = _.filter(this.users, ['valid', true]);
        this.events = _.filter(this.events, o => {
          return (o.RoomId != item.id || _.findIndex(keepEmployees, ['id', o.UserId]) != -1);
        });
      }
    },
    handleDelete(id) {
      let mIds = [];
      mIds = mIds.concat(id);

      mIds.forEach(id => {
        let index = -1;
        index = _.findIndex(this.fullEvents, { id });
        if (index != -1) this.fullEvents.splice(index, 1);

        index = _.findIndex(this.events, { id });
        if (index != -1) this.events.splice(index, 1);
      });

    },
    //clicar num espaço em branco e criar evento
    createEventClick(campoescolhido) {
      if (!this.events) {
        this.events = [];
      }

      const intervalMinutes = this.intervalMinutes;
      const startdate = new Date(campoescolhido.year, campoescolhido.month - 1, campoescolhido.day, campoescolhido.hour, campoescolhido.minute);

      const minutes = startdate.getMinutes();
      const roundedMinutes = Math.floor(minutes / intervalMinutes) * intervalMinutes;

      startdate.setMinutes(roundedMinutes);
      startdate.setSeconds(0);
      startdate.setMilliseconds(0);
      const enddate = new Date(startdate.getTime() + intervalMinutes * 60 * 1000);


      const dataInicio = `${startdate.getFullYear()}-${String(startdate.getMonth() + 1).padStart(2, '0')}-${String(startdate.getDate()).padStart(2, '0')}`;
      const dataFim = `${enddate.getFullYear()}-${String(enddate.getMonth() + 1).padStart(2, '0')}-${String(enddate.getDate()).padStart(2, '0')}`;
      const horaInicio = `${String(startdate.getHours()).padStart(2, '0')}:${String(startdate.getMinutes()).padStart(2, '0')}`;
      const horaFim = `${String(enddate.getHours()).padStart(2, '0')}:${String(enddate.getMinutes()).padStart(2, '0')}`;

      if (this.replacementeventstatus === false) {
        const newEventClick = {
          dataInicio: dataInicio,
          dataFim: dataFim,
          horaInicio: horaInicio,
          horaFim: horaFim,
        };

        this.$refs.eventDialog.open(null, null, null, newEventClick, null);
      } else {
        const newEventReplacementClick = {
          id: this.newEventReplacement.id,
          dataInicio: dataInicio,
          dataFim: dataFim,
          horaInicio: horaInicio,
          horaFim: horaFim,
          TypeeventId: this.newEventReplacement.TypeeventId,
          RoomId: this.newEventReplacement.RoomId,
          UserId: this.newEventReplacement.UserId,
          invoiced: this.newEventReplacement.invoiced,
          details: this.newEventReplacement.details,
          linkedId: this.newEventReplacement.linkedId,
          arrayDeClientes: this.newEventReplacementClients
        };
        this.$refs.eventDialog.open(null, null, null, null, newEventReplacementClick);
      }
    },
    //clicar no evento e abrir EventDialog
    showEvent({ nativeEvent, event }) {
      // se clicarmos numa pausa (userbreak), o iseventnotebreak está 3 e não abre o EventDialog
      if (event.iseventnotebreak != 3) {
        this.$refs.eventDialog.open(event, null, null, null, null);
      }
      nativeEvent.stopPropagation();
    },
    //mover evento para outro dia e abrir EventDialog
    startDragMover (event) {
        this.moverEvent = event
        this.moverTime = null
    },
    startTimeMover (tms) {
        const mouse = this.toTime(tms)
        if (this.moverEvent && this.moverTime === null) {
          let dateinicioevento = new Date(this.moverEvent.event.start);
          let timestampdateinicioevento = dateinicioevento.getTime();
          const start = timestampdateinicioevento
          this.moverTime = mouse - start
        }
    },
    mouseMoveMover (tms) {
        const mouse = this.toTime(tms)
        // se tentarmos arrastar uma pausa (userbreak), o iseventnotebreak está 3, não arraste e não abre o EventDialog
        if (this.moverEvent && this.moverTime !== null && this.moverEvent.event.iseventnotebreak != 3) {
          let dateinicioevento = new Date(this.moverEvent.event.start);
          let timestampdateinicioevento = dateinicioevento.getTime();
          let datefimevento = new Date(this.moverEvent.event.end);
          let timestampdatefimevento = datefimevento.getTime();
          const start = timestampdateinicioevento
          const end = timestampdatefimevento
          const duration = end - start
          const newStartTime = mouse - this.moverTime
          const newStart = this.roundTime(newStartTime)
          const newEnd = newStart + duration

          let datainicio = new Date(newStart);
          let datafim = new Date(newEnd);

          let formattedDateinicio = datainicio.getFullYear() + '-' + String(datainicio.getMonth() + 1).padStart(2, '0') + '-' + String(datainicio.getDate()).padStart(2, '0') + ' ' + String(datainicio.getHours()).padStart(2, '0') + ':' + String(datainicio.getMinutes()).padStart(2, '0');
          let formattedDateifim = datafim.getFullYear() + '-' + String(datafim.getMonth() + 1).padStart(2, '0') + '-' + String(datafim.getDate()).padStart(2, '0') + ' ' + String(datafim.getHours()).padStart(2, '0') + ':' + String(datafim.getMinutes()).padStart(2, '0');

          this.moverEvent.event.start = formattedDateinicio
          this.moverEvent.event.end = formattedDateifim
        }

    },
    endDragMover () {
      // se tentarmos arrastar uma pausa (userbreak), o iseventnotebreak está 3, não arraste e não abre o EventDialog
      if (this.moverEvent.event.iseventnotebreak != 3) {
        this.$refs.eventDialog.open(this.moverEvent.event, null, null, null, null);
      }
      this.moverTime = null
      this.moverEvent = null
    },
    cancelDragMover () {
        this.moverTime = null
        this.moverEvent = null
    },
    roundTime (time, down = true) {
      const roundTo = 15
      const roundDownTime = roundTo * 60 * 1000

      return down
        ? time - time % roundDownTime
        : time + (roundDownTime - (time % roundDownTime))
    },
    toTime (tms) {
      return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
    },
    statusColor(value) {
      for (let statu of this.eventstatus) {
        if (statu.value === value) {
          return statu.color;
        }
      }
    },
    viewDay({ date }) {
      this.calMoveDir = true;
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    showIntervalLabel(interval) {
      return interval.minute === 0;
    },
    handleEvent(events) {
      let mEvents = [];
      mEvents = mEvents.concat(events);

      mEvents.forEach(event => {

        event.category = (_.find(this.users, { 'id': event.UserId })).name;
        event.color = event.roomColor;

        const index = _.findIndex(this.events, { 'id': event.id });

        if (index === -1) {

          if (!_.find(this.fullEvents, ["id", event.id])) {
            this.fullEvents.push(event);
          }

          if (_.find(this.users, ["id", event.UserId]).valid) {
            this.events.push(event);
          }
        } else {
          if (!_.find(this.fullEvents, ["id", event.id])) {
            this.fullEvents.splice(index, 1, event);
          }

          if (_.find(this.users, ["id", event.UserId]).valid) {
            this.events.splice(index, 1, event);
          }
        }
      });
    },
    handleReplacementEvent (replacementEvent, clientsReplacementEvent) {
      this.replacementeventstatus = true
      this.newEventReplacement = replacementEvent
      this.newEventReplacementClients = clientsReplacementEvent
    },
    handleReplacementEventClose () {
      this.replacementeventstatus = false
    },
    ToggleUserCalendar() {
      this.bulkSelect = !this.bulkSelect;

      if (this.bulkSelect) {
        this.events = this.fullEvents;
      } else {
        // this.events = [];

        let keepRooms = _.filter(this.rooms, ['valid', true]);
        this.events = _.filter(this.events, o => {
          return (_.findIndex(keepRooms, ['id', o.RoomId]) != -1);
        });
      }

      _.forEach(this.users, value => {
        value.valid = this.bulkSelect;
      });
    },
    ToggleRoomCalendar() {
      this.bulkSelectRooms = !this.bulkSelectRooms;

      if (this.bulkSelectRooms) {
        this.events = this.fullEvents;
      } else {
        // this.events = [];

        let keepEmployees = _.filter(this.users, ['valid', true]);
        this.events = _.filter(this.events, o => {
          return (_.findIndex(keepEmployees, ['id', o.UserId]) != -1);
        });
      }

      _.forEach(this.rooms, value => {
        value.valid = this.bulkSelectRooms;
      });
    },
    async NextMonth() {
      this.calMoveDir = true;
      this.$refs.calendar.next();
      // await this.getCalendar(this.$refs.calendar.lastEnd.month + 1);
    },
    async PrevMonth() {
      this.calMoveDir = false;
      this.$refs.calendar.prev();
      // await this.getCalendar(this.$refs.calendar.lastStart.month - 1);
    },
    async getCalendar() {
      this.clientTypeP = config.CLIENT_TYPE_PLURAL;
      try {
        let month;
        let year;
        if (this.calMoveDir) {
          month = this.$refs.calendar.lastEnd.month;
          year = this.$refs.calendar.lastEnd.year;
        } else {
          month = this.$refs.calendar.lastStart.month;
          year = this.$refs.calendar.lastStart.year;
        }

        let newMOnth = year + "_" + month;

        if (_.includes(this.monthsProcessed, newMOnth)) {
          return;
        }

        this.loading = true;

        this.tracked = [];
        this.colors = [];
        this.status = [];

        let startDate = "";

        if (month == 0) {
          startDate = year - 1 + "-12-01";
        } else {
          startDate = year + "-" + month.toString().padStart(2, "0") + "-01";
        }

        let endDate = "";

        if (month == 12) {
          endDate = year + 1 + "-01-01";
        } else {
          endDate =
            year + "-" + (month + 1).toString().padStart(2, "0") + "-01";
        }

        const response = await ServiceCalendars.getCalendar(
          null,
          startDate,
          endDate
        );

        _.forEach(response.data, value => {
          // value.color =
          //   "#" +
          //   Math.floor(rng() * 16777215)
          //     .toString(16)
          //     .padEnd(6, "0");

          //   this.fullEvents.push(value);
          // value.auxColor = value.color;
          // value.color = '';
          // value.roomColor = '#FFFFFF';

          if (!_.find(this.fullEvents, ["id", value.id])) {
            this.fullEvents.push(value);
          }

          const index = _.findIndex(this.events, { 'id': value.id });

          if (index === -1) {
            if (_.find(this.users, ["id", value.UserId]).valid) {
              this.events.push(value);
            }
          }
        });

        this.monthsProcessed.push(newMOnth);

        let i = 0;
        let currentDate;
        let stopDate;
        let formattedDate;
        let newProj;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    resizeEvent() {
      this.paneheight = document.documentElement.clientHeight - 64;
    }
  },
  watch: {
    intervalMinutes() {
      this.$nextTick(() => {
        if (this.intervalMinutes === 15) {
          this.intervalCount = config.Calendar_Interval_Count_15
        } else if (this.intervalMinutes === 30) {
          this.intervalCount = config.Calendar_Interval_Count_30
        } else {
          this.intervalCount = config.Calendar_Interval_Count_60
        }
        this.getCalendar()
      });
    },
  },
  async mounted() {
    /*Se o funcionário não tiver permissões de ver nos calendários da todos */
    if (this.$store.state.user.see_everyones_appointments_module != true) {
      this.$router.push(this.$route.query.redirect || "home");
    }
    try {
      this.intervalCount = config.Calendar_Interval_Count_start
      this.intervalMinutes = config.Calendar_Interval_Minutes_start
      this.weekday = config.WEEK_DAYS
      this.type = config.CALENDAR_FULL_TYPE

      this.eventstatus = config.EVENT_STATUS;

      this.phone = isMobile();

      if (this.phone) {
        this.drawer = false;
      } else {
        this.drawer = true;
      }

      this.$refs.calendar.checkChange();
      this.paneheight = document.documentElement.clientHeight - 64;

      await getUsersAndRooms(1, 1, this.users, this.rooms);
      await getTypeevents(1, this.typeevents);
      await getGroups(1, this.groups);

      if (this.users.length == 1) {
        this.selectedUser = this.users[0];
      }

      if (this.rooms.length == 1) {
        this.selectedRoom = this.rooms[0];
        this.rooms[0].valid = false;
      }

      this.filteredUsers = this.filteredUsers.concat(this.users);
      this.filteredRooms = this.filteredRooms.concat(this.rooms);

      await this.getCalendar(this.$refs.calendar.lastStart.month);

      this.colorrooms = config.COLOR_ROOMS;
    } catch (error) {
      this.$store.dispatch("setSnackbar", {
        color: "error",
        text: error
      });
    }
  },
  created: function () {
    window.addEventListener("resize", this.resizeEvent);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.resizeEvent);
  },
};

</script>

<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: '';
  }

  &:hover::after {
    display: block;
  }
}
</style>

