import Api from '@/serviceGgest/ServiceBase'
const path = 'configurarAcessoBD'

export default {
  acessobd () {
    console.log(process.env.VUE_APP_API_PASSWORD_GGEST)
    const requestData = {
      hfsql_servidor: process.env.VUE_APP_API_SERVIDOR_GGEST,
      hfsql_porta: process.env.VUE_APP_API_PORTA_GGEST,
      hfsql_basedados: process.env.VUE_APP_API_BASEDADOS_GGEST,
      hfsql_utilizador: process.env.VUE_APP_API_UTILIZADOR_GGEST,
      hfsql_palavrapasse: process.env.VUE_APP_API_PASSWORD_GGEST
    };

    return Api().put(path, requestData)
  }
}
