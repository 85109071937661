<template>
  <v-container v-if="$store.state.user.dashboard_module === true">
    <v-row align="center" justify="center">
      <v-col cols="12" md="10">
        <v-container>
          <v-row>
            <v-col cols="12" md="4">
              <v-dialog ref="toggleDataInicio" v-model="toggleDataInicio" :return-value.sync="data_inicio_select"
                persistent width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field clearable v-model="data_inicio_select" label="Data Inicio (desde)"
                    prepend-icon="mdi-clock-outline" v-on="on"></v-text-field>
                </template>
                <v-date-picker :max="data_fim_select" locale="pt-pt" v-model="data_inicio_select" scrollable>
                  <v-btn text color="primary" @click="toggleDataInicio = false">Cancelar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="updateDashboardDateInicio(data_inicio_select)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="4">
              <v-dialog ref="toggleDataFim" v-model="toggleDataFim" :return-value.sync="data_fim_select"
                persistent width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field clearable v-model="data_fim_select" label="Data Fim (até)"
                    prepend-icon="mdi-clock-outline" v-on="on"></v-text-field>
                </template>
                <v-date-picker :min="data_inicio_select" locale="pt-pt" v-model="data_fim_select" scrollable>
                  <v-btn text color="primary" @click="toggleDataFim = false">Cancelar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="updateDashboardDateFim(data_fim_select)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4">
              <v-card>
                <h4 class="text-center" style="padding: 5px;">Faturação de cada funcionário</h4>
                <column-chart class="graficosTamanho" :zeros="true" :round="2" suffix="€" style="padding: 10px;" :data="chartEmployeeBillingPesquisa" :colors="['#1565C0']"></column-chart>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ServiceDashboard from '@/services/ServiceDashboard'
import _ from "lodash";
export default {
  name: "Dashboard",
  data() {
    return {
      clientTypeP: '',

      // pesquisas
      toggleDataInicio: false,
      toggleDataFim: false,
      data_inicio_select: null,
      data_fim_select: null,
      employee_billing: []
    }
  },
  async mounted() {
    /*Se o funcionário não tiver permissões de entrar nas dashboards */
    if (this.$store.state.user.dashboard_module != true) {
      this.$router.push(this.$route.query.redirect || "home");
    }
    this.clientTypeP = config.CLIENT_TYPE_PLURAL;
    this.employee_billing = (await ServiceDashboard.listemployeebilling(this.data_inicio_select, this.data_fim_select)).data
  },
  computed: {
    chartEmployeeBillingPesquisa() {
      let data = [];
      this.employee_billing.forEach(employeebilling => {
        data.push([employeebilling.name, employeebilling.total.toFixed(2) + ' €']);
      });
      return data;
    },
  },
  methods: {
    async updateDashboardDateInicio(date) {
      this.$refs.toggleDataInicio.save(date);
      this.employee_billing = (await ServiceDashboard.listemployeebilling(this.data_inicio_select, this.data_fim_select)).data
    },
    async updateDashboardDateFim(date) {
      this.$refs.toggleDataFim.save(date);
      this.employee_billing = (await ServiceDashboard.listemployeebilling(this.data_inicio_select, this.data_fim_select)).data
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
