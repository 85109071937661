<template>
  <v-container v-if="$store.state.user.dashboard_module === true">
    <v-row align="center" justify="center">
      <v-col cols="12" md="10">
        <router-link style="text-decoration: none;" :to="{ name: 'dashclients' }">
          <span style="color: #1565C0; cursor: pointer;">Clientes</span>
        </router-link>
        <router-link style="text-decoration: none;" :to="{ name: 'dashevents' }" class="ml-5">
          <span style="color: #1565C0; cursor: pointer;">Marcações</span>
        </router-link>
        <router-link style="text-decoration: none;" :to="{ name: 'dashemployeebilling' }" class="ml-5">
          <span style="color: #1565C0; cursor: pointer;">Faturação de cada funcionários</span>
        </router-link>
        <router-link v-if="$store.state.user.role == 'admin'" style="text-decoration: none;" :to="{ name: 'dashbilling' }" class="ml-5">
          <span style="color: #1565C0; cursor: pointer;">Faturação</span>
        </router-link>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
export default {
  name: "Dashboard",
  data() {
    return {

    }
  },
  async mounted() {
    /*Se o funcionário não tiver permissões de entrar nas dashboards */
    if (this.$store.state.user.dashboard_module != true) {
      this.$router.push(this.$route.query.redirect || "home");
    }
  },
  methods: {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
