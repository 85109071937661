<template>
  <v-container v-if="$store.state.user.dashboard_module == true && $store.state.user.role == 'admin'">
    <v-row>
      <v-col cols="12">
        <h3>Dados fixos</h3>
      </v-col>
      <v-col cols="12">
        <v-card>
          <h4 class="text-center" style="padding: 5px;">Faturado ano</h4>
          <column-chart class="graficosTamanho" :zeros="true" :round="2" suffix="€" style="padding: 10px;" :data="faturado_ano" :colors="['#1565C0', '#0d2149', '#0d9834', '#FF0000']" ></column-chart>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-card>
          <h4 class="text-center" style="padding: 5px;">Faturação ano ({{currentYear}}) <v-icon color="black" @click="alterarAnoEventosMenosUm">mdi-chevron-left</v-icon><v-icon color="black" @click="alterarAnoEventosMaisUm">mdi-chevron-right</v-icon></h4>
          <column-chart class="graficosTamanho" :zeros="true" :round="2" suffix="€" style="padding: 10px;" :data="faturado_ano_atual" :colors="['#1565C0', '#0d2149', '#0d9834', '#FF0000']"></column-chart>
        </v-card>
      </v-col>
    </v-row>
    <!--
    <v-row>
      <v-col cols="12">
        <v-container>
          <v-row>
            <v-col cols="12" md="2">
              <v-dialog ref="toggleDataInicio" v-model="toggleDataInicio" :return-value.sync="data_inicio_select"
                persistent width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field clearable v-model="data_inicio_select" label="Data Inicio (desde)"
                    prepend-icon="mdi-clock-outline" v-on="on"></v-text-field>
                </template>
                <v-date-picker :max="data_fim_select" locale="pt-pt" v-model="data_inicio_select" scrollable>
                  <v-btn text color="primary" @click="toggleDataInicio = false">Cancelar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="updateDashboardDateInicio(data_inicio_select)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="2">
              <v-dialog ref="toggleDataFim" v-model="toggleDataFim" :return-value.sync="data_fim_select"
                persistent width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field clearable v-model="data_fim_select" label="Data Fim (até)"
                    prepend-icon="mdi-clock-outline" v-on="on"></v-text-field>
                </template>
                <v-date-picker :min="data_inicio_select" locale="pt-pt" v-model="data_fim_select" scrollable>
                  <v-btn text color="primary" @click="toggleDataFim = false">Cancelar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="updateDashboardDateFim(data_fim_select)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete ref="cbClient" :label="clientTypeP"
                :loading="loadingClients" v-debounce="newVal => myFn(newVal, 'v-text-field')" v-model="client_select"
                :items="clients" item-text="name" item-value="id"
                clearable :return-object="false" @change="updateDashboardClient"
                hint="Nome, NIF ou Tlf." persistent-hint> <template v-slot:progress>
                  <span class="custom-loader">
                    <v-icon light>mdi-cached</v-icon>
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col cols="12" md="4">
              <v-autocomplete @change="updateDashboardClient" label="Funcionário" v-model="user_select" :items="users" item-text="name" item-value="id" clearable></v-autocomplete>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    -->
  </v-container>
</template>

<script>
import ServiceDashboard from '@/services/ServiceDashboard'
import ServiceClients from '@/services/ServiceClients'
import ServiceUsers from '@/services/ServiceUsers'
import _ from "lodash";
export default {
  name: "Dashboard",
  data() {
    return {
      toggleDataInicio: false,
      toggleDataFim: false,
      clientTypeP: '',
      clients: [],
      users: [],
      loadingClients: false,
      currentYear: new Date().getFullYear(),

      // pesquisas
      client_select: null,
      user_select: null,
      data_inicio_select: new Date().toISOString().substr(0, 10),
      data_fim_select: new Date().toISOString().substr(0, 10),

      // dados faturado
      faturado_ano: [],
      faturado_ano_atual: []
    }
  },
  async mounted() {
    /*Se o funcionário não tiver permissões de entrar nas dashboards */
    if (this.$store.state.user.dashboard_module != true || this.$store.state.user.role != 'admin') {
      this.$router.push(this.$route.query.redirect || "home");
    }

    this.clientTypeP = config.CLIENT_TYPE_PLURAL;
    this.users = (await ServiceUsers.list(null, false)).data;

    // dados faturado
    this.faturado_ano = (await ServiceDashboard.listbillingano()).data
    this.faturado_ano_atual = (await ServiceDashboard.listbillinganoatual(this.currentYear)).data

  },
  computed: {
  },
  methods: {
    async updateDashboardDateInicio(date) {
      this.$refs.toggleDataInicio.save(date);
      this.eventos_numero_pesquisa = (await ServiceDashboard.listeventsnumeropesquisa(this.data_inicio_select, this.data_fim_select, this.client_select, this.user_select)).data
      this.eventos_tiposdeservico_pesquisa = (await ServiceDashboard.listeventstiposdeservicopesquisa(this.data_inicio_select, this.data_fim_select, this.client_select, this.user_select)).data
      this.eventos_tiposdestatus_pesquisa = (await ServiceDashboard.listeventstiposdestatuspesquisa(this.data_inicio_select, this.data_fim_select, this.client_select, this.user_select)).data
    },
    async updateDashboardDateFim(date) {
      this.$refs.toggleDataFim.save(date);
      this.eventos_numero_pesquisa = (await ServiceDashboard.listeventsnumeropesquisa(this.data_inicio_select, this.data_fim_select, this.client_select, this.user_select)).data
      this.eventos_tiposdeservico_pesquisa = (await ServiceDashboard.listeventstiposdeservicopesquisa(this.data_inicio_select, this.data_fim_select, this.client_select, this.user_select)).data
      this.eventos_tiposdestatus_pesquisa = (await ServiceDashboard.listeventstiposdestatuspesquisa(this.data_inicio_select, this.data_fim_select, this.client_select, this.user_select)).data
    },
    async updateDashboardClient() {
      this.eventos_numero_pesquisa = (await ServiceDashboard.listeventsnumeropesquisa(this.data_inicio_select, this.data_fim_select, this.client_select, this.user_select)).data
      this.eventos_tiposdeservico_pesquisa = (await ServiceDashboard.listeventstiposdeservicopesquisa(this.data_inicio_select, this.data_fim_select, this.client_select, this.user_select)).data
      this.eventos_tiposdestatus_pesquisa = (await ServiceDashboard.listeventstiposdestatuspesquisa(this.data_inicio_select, this.data_fim_select, this.client_select, this.user_select)).data
    },
    async myFn(newVal) {
      if (newVal) {
        this.loadingClients = true;
        this.clients = (await ServiceClients.listDebounce(newVal)).data;
        this.$refs.cbClient.$el.focus();
        this.loadingClients = false;
      }
    },
    async alterarAnoEventosMenosUm () {
      this.currentYear = this.currentYear - 1
      this.faturado_ano_atual = (await ServiceDashboard.listbillinganoatual(this.currentYear)).data
    },
    async alterarAnoEventosMaisUm () {
      this.currentYear = this.currentYear + 1
      this.faturado_ano_atual = (await ServiceDashboard.listbillinganoatual(this.currentYear)).data
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.graficosTamanho{
  height: 160px !important;
  line-height: 160px !important;
}
</style>
