var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$store.state.user.logs_invoiced_module === true)?_c('v-container',[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-data-table',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.getUsers),expression:"getUsers"}],staticClass:"mTable",attrs:{"id":"mytable","infinite-scroll-disabled":"busy","infinite-scroll-distance":"10","headers":_vm.headers,"items":_vm.users,"loading":_vm.loading,"hide-default-footer":"","items-per-page":_vm.users.length + 10},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":"blue"},on:{"click":function($event){return _vm.invoiceLinesLogs(item)}}},[_vm._v("mdi-eye")])]}},{key:"item.start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.start))+" ")]}},{key:"item.hourstart",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.start))+" ")]}},{key:"item.hourend",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.end))+" ")]}}],null,true)},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"blue","indeterminate":""},slot:"progress"})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"1500px"},model:{value:(_vm.loglinesDialog),callback:function ($$v) {_vm.loglinesDialog=$$v},expression:"loglinesDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"pa-0"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Linhas do log")]),_c('v-spacer')],1)],1),_c('v-card-text',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"mTable full-width",attrs:{"headers":_vm.headersLines,"items":_vm.invoicelineslogs,"hide-default-footer":"","items-per-page":_vm.invoicelineslogs.length + 10},scopedSlots:_vm._u([{key:"item.erp_billing_number",fn:function(ref){
var item = ref.item;
return [(item.erp_billing_number)?_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red darken-2"}},[_vm._v("mdi-close")])]}},{key:"item.erp_billing_number_valid",fn:function(ref){
var item = ref.item;
return [(item.erp_billing_number_valid)?_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red darken-2"}},[_vm._v("mdi-close")])]}},{key:"item.erp_all_articles_valid",fn:function(ref){
var item = ref.item;
return [(item.erp_all_articles_valid)?_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red darken-2"}},[_vm._v("mdi-close")])]}},{key:"item.erp_create_document",fn:function(ref){
var item = ref.item;
return [(item.erp_create_document)?_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red darken-2"}},[_vm._v("mdi-close")])]}},{key:"item.clinic_create_document",fn:function(ref){
var item = ref.item;
return [(item.clinic_create_document)?_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red darken-2"}},[_vm._v("mdi-close")])]}},{key:"item.erp_print_template_valid",fn:function(ref){
var item = ref.item;
return [(item.erp_print_template_valid)?_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red darken-2"}},[_vm._v("mdi-close")])]}},{key:"item.erp_base64_valid",fn:function(ref){
var item = ref.item;
return [(item.erp_base64_valid)?_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red darken-2"}},[_vm._v("mdi-close")])]}},{key:"item.invite_document_email",fn:function(ref){
var item = ref.item;
return [(item.invite_document_email)?_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red darken-2"}},[_vm._v("mdi-close")])]}},{key:"item.clinic_base64_valid",fn:function(ref){
var item = ref.item;
return [(item.clinic_base64_valid)?_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red darken-2"}},[_vm._v("mdi-close")])]}},{key:"item.erp_login",fn:function(ref){
var item = ref.item;
return [(item.erp_login)?_c('v-icon',{attrs:{"color":"green darken-2"}},[_vm._v("mdi-check")]):_c('v-icon',{attrs:{"color":"red darken-2"}},[_vm._v("mdi-close")])]}}],null,true)})],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }