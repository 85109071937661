import Api from '@/services/ServiceBase'
const path = 'receipts'

export default {
  createreceiptinvoice(clientid, arrayartigosrecibos, total_amount_paid, payment_method_select, type_document, numerorecibogerado, observacoes) {
    return Api().post(path + '/createreceiptinvoice', {
      params: {
        clientid,
        arrayartigosrecibos,
        total_amount_paid,
        payment_method_select,
        type_document,
        numerorecibogerado,
        observacoes
      }
    })
  },
  listreceiptsinvoice(invoiceid) {
    return Api().get(path + '/listreceiptsinvoice', {
      params: {
        invoiceid
      }
    })
  },
  listlinesreceipts(receiptid) {
    return Api().get(path + '/listlinesreceipts', {
      params: {
        receiptid
      }
    })
  },
}
