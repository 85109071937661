<template>
  <div>
    <v-dialog v-model="dialog" scrollable max-width="1200" transition="dialog-bottom-transition">

      <v-overlay :value="processing">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>

      <v-card>
        <observations ref="observations" />
        <attachments ref="attachments" />
        <invoices ref="invoices" />
        <covenants ref="covenants" @handleRemoverClientes="handleRemoverClientes" />
        <add-edit-client ref="addEditClient" @submited="submitedData" />
        <v-toolbar :dark="true" :color="replacementstatusevent ? 'red' : 'primary'">
          <v-toolbar-title>
            {{ replacementstatusevent ? 'Criar marcação reposição' : 'Marcação' }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-tooltip top v-if="replacementstatusevent === true">
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" @click="sairdareposicao" color="white">mdi-close</v-icon>
            </template>
            <span>Sair da reposição</span>
          </v-tooltip>
        </v-toolbar>
        <v-card-text>
          <v-form ref="newEventForm" v-model="formValid" lazy-validation>
            <v-tabs v-if="showTab" v-model="tab" centered>
              <v-tab>
                Normal
              </v-tab>
              <v-tab>
                Periódica
              </v-tab>
              <v-tab>
                Nota
              </v-tab>
              <v-tab-item>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6" sm="12">
                      <v-dialog ref="toggleStartDate" v-model="toggleStartDate" :return-value.sync="event.startDate"
                        persistent width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field :disabled="bloquearEventoTodo" clearable v-model="event.startDate" label="Data Inicio"
                            prepend-icon="mdi-clock-outline" required :rules="[rules.required]" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker :max="event.endDate" locale="pt-pt" v-model="event.startDate" scrollable>
                          <v-btn text color="primary" @click="toggleStartDate = false">Cancelar</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="$refs.toggleStartDate.save(event.startDate)">OK</v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-dialog ref="toggleStartHour" v-model="toggleStartHour" :return-value.sync="event.startHour"
                        persistent width="290px"
                        @input="value => value && $refs.picker1 && ($refs.picker1.selectingHour = true)">
                        <template v-slot:activator="{ on }">
                          <v-text-field :disabled="bloquearEventoTodo" v-model="event.startHour" label="Hora Inicio"
                            prepend-icon="mdi-clock-outline" readonly required :rules="[rules.required]" clearable
                            v-on="on"></v-text-field>
                        </template>
                        <v-time-picker v-if="toggleStartHour" format="24hr"
                          :max="event.startDate === event.endDate ? event.endHour : ''" locale="pt-pt"
                          v-model="event.startHour" ref="picker1">
                          <v-btn text color="primary" @click="toggleStartHour = false">Cancelar</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="$refs.toggleStartHour.save(event.startHour)">OK</v-btn>
                        </v-time-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row no-gutters align="center" justify="center" v-if="!bloquearEventoTodo">
                    <v-col cols="6" align="center" justify="center">
                      <v-btn text :disabled="(event.startDate && event.startHour) ? false : true" color="primary" outlined
                        @click="addTime(0.5)">
                        +30min
                      </v-btn>
                    </v-col>
                    <v-col cols="6" align="center" justify="center">
                      <v-btn text :disabled="(event.startDate && event.startHour) ? false : true" color="primary" outlined
                        @click="addTime(1)">
                        +1 hora
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" sm="12">
                      <v-dialog ref="toggleEndDate" v-model="toggleEndDate" :return-value.sync="event.endDate" persistent
                        width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field :disabled="bloquearEventoTodo" clearable v-model="event.endDate" label="Data Fim"
                            prepend-icon="mdi-clock-outline" required :rules="[rules.required]" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker :min="event.startDate" locale="pt-pt" v-model="event.endDate" scrollable>
                          <v-btn text color="primary" @click="toggleEndDate = false">Cancelar</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="$refs.toggleEndDate.save(event.endDate)">OK</v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-dialog ref="toggleEndHour" v-model="toggleEndHour" :return-value.sync="event.endHour" persistent
                        width="290px" @input="value => value && $refs.picker2 && ($refs.picker2.selectingHour = true)">
                        <template v-slot:activator="{ on }">
                          <v-text-field :disabled="bloquearEventoTodo" v-model="event.endHour" label="Hora Fim"
                            prepend-icon="mdi-clock-outline" readonly clearable required :rules="[rules.required]"
                            v-on="on"></v-text-field>
                        </template>
                        <v-time-picker v-if="toggleEndHour" format="24hr"
                          :min="event.startDate === event.endDate ? event.startHour : ''" locale="pt-pt"
                          v-model="event.endHour" ref="picker2">
                          <v-btn text color="primary" @click="toggleEndHour = false">Cancelar</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="$refs.toggleEndHour.save(event.endHour)">OK</v-btn>
                        </v-time-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container>
                  <v-row no-gutters>
                    <v-col cols="12" md="6" sm="12">
                      <v-dialog ref="bulkToggleStartDate" v-model="bulkToggleStartDate"
                        :return-value.sync="event.bulkStartDate" persistent width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field :disabled="bloquearEventoTodo" clearable v-model="event.bulkStartDate" label="Data Inicio"
                            prepend-icon="mdi-clock-outline" required :rules="[rules2.required]" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker :max="event.bulkEndDate" locale="pt-pt" v-model="event.bulkStartDate" scrollable>
                          <v-btn text color="primary" @click="bulkToggleStartDate = false">Cancelar</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary"
                            @click="$refs.bulkToggleStartDate.save(event.bulkStartDate)">OK</v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>


                    <v-col cols="12" md="6" sm="12">
                      <v-dialog ref="bulkToggleEndDate" v-model="bulkToggleEndDate" :return-value.sync="event.bulkEndDate"
                        persistent width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field :disabled="bloquearEventoTodo" clearable v-model="event.bulkEndDate" label="Data Fim"
                            prepend-icon="mdi-clock-outline" required :rules="[rules2.required]" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker :min="event.bulkStartDate" locale="pt-pt" v-model="event.bulkEndDate" scrollable>
                          <v-btn text color="primary" @click="bulkToggleEndDate = false">Cancelar</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="$refs.bulkToggleEndDate.save(event.bulkEndDate)">OK</v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>

                  </v-row>
                  <v-row no-gutters><v-col cols="12" md="6" sm="12">
                      <v-dialog ref="bulkToggleStartHour" v-model="bulkToggleStartHour"
                        :return-value.sync="event.bulkStartHour" persistent width="290px"
                        @input="value => value && $refs.picker1 && ($refs.picker1.selectingHour = true)">
                        <template v-slot:activator="{ on }">
                          <v-text-field :disabled="bloquearEventoTodo" v-model="event.bulkStartHour" label="Hora Inicio"
                            prepend-icon="mdi-clock-outline" readonly required :rules="[rules2.required]" clearable
                            v-on="on"></v-text-field>
                        </template>
                        <v-time-picker v-if="bulkToggleStartHour" format="24hr" :max="event.bulkEndHour" locale="pt-pt"
                          v-model="event.bulkStartHour" ref="picker1">
                          <v-btn text color="primary" @click="bulkToggleStartHour = false">Cancelar</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary"
                            @click="$refs.bulkToggleStartHour.save(event.bulkStartHour)">OK</v-btn>
                        </v-time-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-dialog ref="bulkToggleEndHour" v-model="bulkToggleEndHour" :return-value.sync="event.bulkEndHour"
                        persistent width="290px"
                        @input="value => value && $refs.picker2 && ($refs.picker2.selectingHour = true)">
                        <template v-slot:activator="{ on }">
                          <v-text-field :disabled="bloquearEventoTodo" v-model="event.bulkEndHour" label="Hora Fim"
                            prepend-icon="mdi-clock-outline" readonly clearable required :rules="[rules2.required]"
                            v-on="on"></v-text-field>
                        </template>
                        <v-time-picker v-if="bulkToggleEndHour" format="24hr" :min="event.bulkStartHour" locale="pt-pt"
                          v-model="event.bulkEndHour" ref="picker2">
                          <v-btn text color="primary" @click="bulkToggleEndHour = false">Cancelar</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="$refs.bulkToggleEndHour.save(event.bulkEndHour)">OK</v-btn>
                        </v-time-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>

                  <v-row no-gutters align="center" justify="center">
                    <v-col cols="12" align="center" justify="center">
                      <v-item-group multiple v-model="event.selectedDays" align="center" justify="center">
                        <v-subheader>Repetir :</v-subheader>
                        <v-item :value="0" v-slot="{ active, toggle }">
                          <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                            @click="toggle">Dom</v-btn>
                        </v-item>

                        <v-item :value="1" v-slot="{ active, toggle }">
                          <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                            @click="toggle">Seg</v-btn>
                        </v-item>

                        <v-item :value="2" v-slot="{ active, toggle }">
                          <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                            @click="toggle">Ter</v-btn>
                        </v-item>

                        <v-item :value="3" v-slot="{ active, toggle }">
                          <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                            @click="toggle">Qua</v-btn>
                        </v-item>

                        <v-item :value="4" v-slot="{ active, toggle }">
                          <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                            @click="toggle">Qui</v-btn>
                        </v-item>

                        <v-item :value="5" v-slot="{ active, toggle }">
                          <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                            @click="toggle">Sex</v-btn>
                        </v-item>

                        <v-item :value="6" v-slot="{ active, toggle }">
                          <v-btn class="ma-1" :input-value="active" active-class="primary white--text" depressed rounded
                            @click="toggle">Sab</v-btn>
                        </v-item>
                      </v-item-group>
                    </v-col>

                    <v-col cols="12" align="center" justify="center" v-if="event.selectedDays.length == 0">
                      <v-icon left small>mdi-check</v-icon>
                      <span style="color: red;">Tem de escolher pelo menos 1 dia.</span>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item>
                <v-container>
                  <v-row>
                    <v-col cols="12" md="6" sm="12">
                      <v-dialog ref="toggleStartDate" v-model="toggleStartDate" :return-value.sync="event.startDate"
                        persistent width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field :disabled="bloquearEventoTodo" clearable v-model="event.startDate" label="Data Inicio"
                            prepend-icon="mdi-clock-outline" required :rules="[rules.required]" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker :max="event.endDate" locale="pt-pt" v-model="event.startDate" scrollable>
                          <v-btn text color="primary" @click="toggleStartDate = false">Cancelar</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="$refs.toggleStartDate.save(event.startDate)">OK</v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-dialog ref="toggleStartHour" v-model="toggleStartHour" :return-value.sync="event.startHour"
                        persistent width="290px"
                        @input="value => value && $refs.picker1 && ($refs.picker1.selectingHour = true)">
                        <template v-slot:activator="{ on }">
                          <v-text-field :disabled="bloquearEventoTodo" v-model="event.startHour" label="Hora Inicio"
                            prepend-icon="mdi-clock-outline" readonly required :rules="[rules.required]" clearable
                            v-on="on"></v-text-field>
                        </template>
                        <v-time-picker v-if="toggleStartHour" format="24hr"
                          :max="event.startDate === event.endDate ? event.endHour : ''" locale="pt-pt"
                          v-model="event.startHour" ref="picker1">
                          <v-btn text color="primary" @click="toggleStartHour = false">Cancelar</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="$refs.toggleStartHour.save(event.startHour)">OK</v-btn>
                        </v-time-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                  <v-row no-gutters align="center" justify="center" v-if="!bloquearEventoTodo">
                    <v-col cols="6" align="center" justify="center">
                      <v-btn text :disabled="(event.startDate && event.startHour) ? false : true" color="primary" outlined
                        @click="addTime(0.5)">
                        +30min
                      </v-btn>
                    </v-col>
                    <v-col cols="6" align="center" justify="center">
                      <v-btn text :disabled="(event.startDate && event.startHour) ? false : true" color="primary" outlined
                        @click="addTime(1)">
                        +1 hora
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="6" sm="12">
                      <v-dialog ref="toggleEndDate" v-model="toggleEndDate" :return-value.sync="event.endDate" persistent
                        width="290px">
                        <template v-slot:activator="{ on }">
                          <v-text-field :disabled="bloquearEventoTodo" clearable v-model="event.endDate" label="Data Fim"
                            prepend-icon="mdi-clock-outline" required :rules="[rules.required]" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker :min="event.startDate" locale="pt-pt" v-model="event.endDate" scrollable>
                          <v-btn text color="primary" @click="toggleEndDate = false">Cancelar</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="$refs.toggleEndDate.save(event.endDate)">OK</v-btn>
                        </v-date-picker>
                      </v-dialog>
                    </v-col>
                    <v-col cols="12" md="6" sm="12">
                      <v-dialog ref="toggleEndHour" v-model="toggleEndHour" :return-value.sync="event.endHour" persistent
                        width="290px" @input="value => value && $refs.picker2 && ($refs.picker2.selectingHour = true)">
                        <template v-slot:activator="{ on }">
                          <v-text-field :disabled="bloquearEventoTodo" v-model="event.endHour" label="Hora Fim"
                            prepend-icon="mdi-clock-outline" readonly clearable required :rules="[rules.required]"
                            v-on="on"></v-text-field>
                        </template>
                        <v-time-picker v-if="toggleEndHour" format="24hr"
                          :min="event.startDate === event.endDate ? event.startHour : ''" locale="pt-pt"
                          v-model="event.endHour" ref="picker2">
                          <v-btn text color="primary" @click="toggleEndHour = false">Cancelar</v-btn>
                          <v-spacer></v-spacer>
                          <v-btn text color="primary" @click="$refs.toggleEndHour.save(event.endHour)">OK</v-btn>
                        </v-time-picker>
                      </v-dialog>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
            </v-tabs>
            <v-container v-else>
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-dialog ref="toggleStartDate" v-model="toggleStartDate" :return-value.sync="event.startDate" persistent
                    width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field :disabled="bloquearEventoTodo" clearable v-model="event.startDate" label="Data Inicio"
                        prepend-icon="mdi-clock-outline" required :rules="[rules.required]" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker locale="pt-pt" v-model="event.startDate" scrollable>
                      <v-btn text color="primary" @click="toggleStartDate = false">Cancelar</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="saveStartDate(event.startDate)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-dialog ref="toggleStartHour" v-model="toggleStartHour" :return-value.sync="event.startHour" persistent
                    width="290px" @input="value => value && $refs.picker1 && ($refs.picker1.selectingHour = true)">
                    <template v-slot:activator="{ on }">
                      <v-text-field :disabled="bloquearEventoTodo" v-model="event.startHour" label="Hora Inicio"
                        prepend-icon="mdi-clock-outline" readonly required :rules="[rules.required]" clearable
                        v-on="on"></v-text-field>
                    </template>
                    <v-time-picker v-if="toggleStartHour" format="24hr"
                      :max="event.startDate === event.endDate ? event.endHour : ''" locale="pt-pt" v-model="event.startHour"
                      ref="picker1">
                      <v-btn text color="primary" @click="toggleStartHour = false">Cancelar</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="$refs.toggleStartHour.save(event.startHour)">OK</v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row no-gutters align="center" justify="center" v-if="!bloquearEventoTodo">
                <v-col cols="6" align="center" justify="center">
                  <v-btn text :disabled="(event.startDate && event.startHour) ? false : true" color="primary" outlined
                    @click="addTime(0.5)">
                    +30min
                  </v-btn>
                </v-col>
                <v-col cols="6" align="center" justify="center">
                  <v-btn text :disabled="(event.startDate && event.startHour) ? false : true" color="primary" outlined
                    @click="addTime(1)">
                    +1 hora
                  </v-btn>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" sm="12">
                  <v-dialog ref="toggleEndDate" v-model="toggleEndDate" :return-value.sync="event.endDate" persistent
                    width="290px">
                    <template v-slot:activator="{ on }">
                      <v-text-field :disabled="bloquearEventoTodo" clearable v-model="event.endDate" label="Data Fim"
                        prepend-icon="mdi-clock-outline" required :rules="[rules.required]" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker :min="event.startDate" locale="pt-pt" v-model="event.endDate" scrollable>
                      <v-btn text color="primary" @click="toggleEndDate = false">Cancelar</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="$refs.toggleEndDate.save(event.endDate)">OK</v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col cols="12" md="6" sm="12">
                  <v-dialog ref="toggleEndHour" v-model="toggleEndHour" :return-value.sync="event.endHour" persistent
                    width="290px" @input="value => value && $refs.picker2 && ($refs.picker2.selectingHour = true)">
                    <template v-slot:activator="{ on }">
                      <v-text-field :disabled="bloquearEventoTodo" v-model="event.endHour" label="Hora Fim"
                        prepend-icon="mdi-clock-outline" readonly clearable required :rules="[rules.required]"
                        v-on="on"></v-text-field>
                    </template>
                    <v-time-picker v-if="toggleEndHour" format="24hr"
                      :min="event.startDate === event.endDate ? event.startHour : ''" locale="pt-pt" v-model="event.endHour"
                      ref="picker2">
                      <v-btn text color="primary" @click="toggleEndHour = false">Cancelar</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="$refs.toggleEndHour.save(event.endHour)">OK</v-btn>
                    </v-time-picker>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-container>
            <v-container>
              <!--TypeEvents-->
              <v-row no-gutters v-if="typeevents.length != 1 && tab != 2">
                <v-col cols="12" sm="12">
                  <v-autocomplete v-model="event.TypeeventId" :items="typeevents" item-text="name" item-value="id" label="Tipo de Serviço"
                    required :rules="generalRules" :disabled="typeevents.length == 1 || bloquearEventoTodo || bloquearEventoFaturado || bloquearTipoDeServico" @change="typeeventChange"></v-autocomplete>
                </v-col>
              </v-row>
              <v-row no-gutters v-if="event.TypeeventId != ''  && tab != 2">
                <v-col cols="10" sm="11">
                  <!--:disabled="bloquearEventoTodo || bloquearClientes || bloquearEventoFaturado"-->
                  <v-autocomplete style="padding-top: 0px; margin-top: 0px"  ref="cbClient" :label="clientTypeP" :filter="customFilter"
                    :loading="loadingClients" v-debounce="newVal => myFn(newVal, 'v-text-field')" @input="selectClienteMarcacao"
                    :items="clients" item-text="name" item-value="id" v-model="selectedClient"
                    clearable :append-outer-icon="'mdi-plus'" :return-object="true" @click:append-outer="openClientInfoEmpty()"
                    hint="Nome, NIF ou Tlf." persistent-hint> <template v-slot:progress>
                      <span class="custom-loader">
                        <v-icon light>mdi-cached</v-icon>
                      </span>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="1">
                  <!--:disabled="bloquearEventoTodo || bloquearClientes || bloquearEventoFaturado"-->
                  <v-icon style="margin-top: 3px;" @click="abrirGrupos" color="green" class="ml-5">mdi-account-group</v-icon>
                </v-col>
              </v-row>
              <template v-if="tab != 2">
                <v-card v-for="clientemarcacao in clientesmarcacao" :key="clientemarcacao.id" class="mt-5">
                  <v-container :style="{ borderLeft: '10px solid', borderLeftColor: statusColor(clientemarcacao.status) }">
                    <v-row>
                      <v-col cols="1" id="checkboxReplacement">
                        <v-checkbox v-model="checkboxesReplacement[clientemarcacao.id]" v-if="statusReplacement(clientemarcacao.status) === true && clientemarcacao.clientecomreposicao === null" @change="onCheckboxChangeReplacement(clientemarcacao)"></v-checkbox>

                      </v-col>
                      <v-col cols="7">
                        <h3>{{ clientemarcacao.name }}</h3>
                        <h4 v-if="clientemarcacao.covenantcycletext != null">{{clientemarcacao.covenantcycletext}}</h4>
                        <h5 v-if="clientemarcacao.clienteeventoreposto != null">Reposição do dia {{ formatDate(clientemarcacao.clienteeventoreposto) }}</h5>
                        <h5 v-if="clientemarcacao.clientecomreposicao != null">Marcação reposta no dia {{ formatDate(clientemarcacao.clientecomreposicao) }}</h5>
                        <!--<h5 v-if="clientemarcacao.covenanttext != null">{{ clientemarcacao.covenanttext }}</h5>-->
                        <v-tooltip top v-if="clientemarcacao.sms == true">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="green">mdi-message-text</v-icon>
                          </template>
                          <span>{{ formatDate(clientemarcacao.sms_date) }}</span>
                        </v-tooltip>
                        <v-tooltip top v-else>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="red">mdi-message-text</v-icon>
                          </template>
                          <span>SMS não enviado</span>
                        </v-tooltip>
                        <v-tooltip top v-if="clientemarcacao.eventofaturado == true">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="green" class="ml-3">mdi-cash</v-icon>
                          </template>
                          <span>Faturado</span>
                        </v-tooltip>
                        <v-tooltip top v-else>
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" color="red" class="ml-3">mdi-cash</v-icon>
                          </template>
                          <span>Não Faturado</span>
                        </v-tooltip>
                      </v-col>
                      <v-col class="d-flex align-center justify-end text-right" cols="4">

                        <v-tooltip top v-if="clientemarcacao.clientecomreposicao === undefined || clientemarcacao.clientecomreposicao === null">
                          <template v-slot:activator="{ on }">
                            <v-icon v-on="on" @click="chipClickClient(clientemarcacao)" :disabled="bloquearEventoTodo || bloquearOpcoesCliente" color="blue" class="ml-5">mdi-eye</v-icon>
                          </template>
                          <span>Ver opções cliente</span>
                        </v-tooltip>

                        <!--clientemarcacao.eventofaturado === undefined && clientemarcacao.clientecomreposicao === undefined || clientemarcacao.eventofaturado == false && clientemarcacao.clientecomreposicao === null-->
                        <v-tooltip top v-if="clientemarcacao.clientecomreposicao === undefined || clientemarcacao.clientecomreposicao === null">
                          <template v-slot:activator="{ on }">
                            <!--v-if="!bloquearEventoFaturado"-->
                            <v-icon v-on="on"  :disabled="bloquearEventoTodo || bloquearClientes" @click="removeClienteMarcacao(clientemarcacao)" color="red" class="ml-5">mdi-close</v-icon>
                          </template>
                          <span>Remover cliente marcação</span>
                        </v-tooltip>
                      </v-col>
                      <v-col class="d-flex align-center pb-0" cols="12" v-if="clientemarcacao.covenantsclient != undefined">
                        <v-autocomplete class="removeTextFieldDetails" label="Avença" v-model="clientemarcacao.covenantid" style="padding-top: 2px !important; margin-top: 0px !important;" :items="clientemarcacao.covenantsclient" :return-object="true" :item-text="formatarCiclos" item-value="id" @input="selectClienteAvenca"></v-autocomplete>
                      </v-col>
                      <v-col class="d-flex align-center pt-0" cols="12" v-if="clientemarcacao.covenantsclient != undefined">
                        <span v-if="tab === 0 && clientemarcacao.temciclo === 1" style="color: red">Atenção, não tens ciclo para a data que selecionou (Marcação vai ser faturada a vulso)</span>
                        <span v-if="tab === 1" style="color: red">Tem de ter atenção, ao escolher a avença ela pode não ter ciclos para as datas que selecionou (Marcações vão ser faturadas a vulso)</span>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </template>
              <v-row no-gutters class="mt-5" v-if="tab != 2">
                <v-col cols="12" sm="12">
                  <!-- <v-text-field label="Funcionário" placeholder="Placeholder"></v-text-field> -->
                  <!-- <pop-up-selector v-if="phone" :itemList="users" :id="'id'" :title="'Funcionários'" :label="'Funcionário'"
                    :description="'name'" :fullReturn="true" :selectedItem="selectedUser === null ? '' : selectedUser.name"
                    @selection="selectuser" :rules="rules" /> -->
                  <v-autocomplete :disabled="!bloquearUser" v-model="event.UserId" :items="users" item-text="name"
                    item-value="id" label="Funcionário" required :rules="generalRules"></v-autocomplete>
                </v-col>
              </v-row>
              <v-row no-gutters v-if="rooms.length != 1 && tab != 2">
                <v-col cols="12" sm="12">
                  <!-- <v-text-field label="Funcionário" placeholder="Placeholder"></v-text-field> -->
                  <!-- <pop-up-selector v-if="phone" :itemList="users" :id="'id'" :title="'Funcionários'" :label="'Funcionário'"
                    :description="'name'" :fullReturn="true" :selectedItem="selectedUser === null ? '' : selectedUser.name"
                    @selection="selectuser" :rules="rules" /> -->
                  <v-autocomplete v-model="event.RoomId" :items="rooms" item-text="name" item-value="id" label="Sala"
                    required :rules="generalRules" :disabled="rooms.length == 1 || bloquearEventoTodo || bloquearSala"></v-autocomplete>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col cols="12" sm="12">
                  <v-textarea v-if="tab != 2" :disabled="bloquearEventoTodo || bloquearDetalhes" label="Detalhes da marcação" v-model="event.details"></v-textarea>
                  <v-textarea v-else :disabled="bloquearEventoTodo" label="Nota" v-model="event.details"></v-textarea>
                </v-col>
              </v-row>
              <v-row no-gutters v-if="tab != 2">
                <v-col cols="10" sm="12">
                  <v-dialog v-model="optionsClient" scrollable max-width="600">
                    <v-card>
                      <v-toolbar class="mb-2" color="primary" dark flat>
                        <v-toolbar-title>{{clientTypeS}}: {{optionsClientSelect.name}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                      </v-toolbar>
                      <v-card-text>
                        <v-row class="mt-10">
                          <v-col cols="6" sm="3">
                            <v-btn style="width: 100%;" text :disabled="optionsClientSelect.id ? false : true" color="primary" outlined @click="openClientInfo(optionsClientSelect.id)">
                              Ficha
                            </v-btn>
                          </v-col>
                          <v-col cols="6" sm="3">
                            <v-btn style="width: 100%;" text :disabled="optionsClientSelect.id ? false : true" color="primary" outlined @click="openObs(optionsClientSelect.id)">
                              Observações
                            </v-btn>
                          </v-col>
                          <v-col cols="6" sm="3">
                            <v-btn style="width: 100%;" text :disabled="optionsClientSelect.id ? false : true" color="primary" outlined @click="openAtta(optionsClientSelect.id)">
                              Anexos
                            </v-btn>
                          </v-col>
                          <v-col cols="6" sm="3" v-if="$store.state.configsms.configuracoessmsestado == true && event.id">
                            <v-btn style="width: 100%;" text :disabled="optionsClientSelect.id ? false : true" color="primary" outlined @click="sendSms(optionsClientSelect.id)">
                              ENVIAR SMS
                            </v-btn>
                          </v-col>
                          <v-col cols="6" sm="3" v-if="!bloquearEventoFaturado">
                            <v-btn style="width: 100%;" text :disabled="optionsClientSelect.id ? false : true" color="primary" outlined @click="openCovenants(optionsClientSelect.id)">
                              Avenças
                            </v-btn>
                          </v-col>
                          <v-col cols="12" v-if="event.id != null && clientevents.status != null" class="mt-10">
                            <v-chip v-for="statu in eventstatus" :key="statu.value" :color="statu.color" text-color="white"
                              class="ma-2" @click="selectStatus(statu.value)">
                                <v-icon v-if="statu.value === clientevents.status" left small>mdi-check</v-icon>
                                {{ statu.text }}
                            </v-chip>
                          </v-col>
                          <v-col cols="12" v-if="event.id != null && clientevents.status != null">
                            <v-textarea label="Detalhes do estado da marcação" v-model="clientevents.details"></v-textarea>
                          </v-col>
                          <v-col cols="12 text-right" v-if="event.id != null && clientevents.status != null">
                            <v-btn @click="editStatusEvent" text color="primary">Confirmar</v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
              <v-row no-gutters v-if="tab != 2">
                <v-col cols="10" sm="12">
                  <v-dialog v-model="groupsClient" scrollable persistent max-width="600">
                    <v-card>
                      <v-toolbar class="mb-2" color="primary" dark flat>
                        <v-toolbar-title>Grupos de {{clientTypeS}}</v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-toolbar-title><v-list-item @click="groupsClient = false" class="v-list-item--link"><v-icon>mdi-close</v-icon></v-list-item></v-toolbar-title>
                      </v-toolbar>
                      <v-card-text>
                        <v-row no-gutters align="center" justify="center">
                          <v-col cols="12">
                            <v-list dense>
                              <v-radio-group v-model="selectedGroup">
                                <v-list-item v-for="(group, index) in groups" :key="index">
                                  <v-list-item-content>
                                    <v-radio :label="group.name" :value="group.id"></v-radio>
                                  </v-list-item-content>
                                </v-list-item>
                              </v-radio-group>
                            </v-list>
                          </v-col>
                          <v-col cols="12 text-right">
                            <v-btn @click="grupoEscolhido" text color="primary">Confirmar</v-btn>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-container>
          </v-form>

        </v-card-text>
        <v-card-actions v-if="!bloquearEventoTodo">
          <v-btn v-if="event.id != undefined && !bloquearEventoFaturado" @click="DeleteSelectedEvent" text color="red" :disabled="processing">Eliminar</v-btn>
          <v-spacer></v-spacer>
          <v-btn v-if="selectedClientemarcacoesReplacement != ''" @click="createReplacementClients" text color="green">Criar reposição dos clientes escolhidos</v-btn>
          <v-btn @click="createEventChooser" text color="primary" :disabled="processing">Confirmar</v-btn>
        </v-card-actions>
      </v-card>

      <confirm-dialog ref="confirm" />
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import ServiceCalendars from "@/services/ServiceCalendars";
import ServiceSendSms from "@/services/ServiceSendSms";
import ServiceGroups from "@/services/ServiceGroups";
import ServiceClients from "@/services/ServiceClients";
import ServiceNotes from "@/services/ServiceNotes";
import ServiceClientevents from "@/services/ServiceClientevents";
import ServiceCovenants from "@/services/ServiceCovenants";
import ServiceTypeevents from "@/services/ServiceTypeevents";
import moment from "moment";
import Observations from "@/components/client/Observations.vue";
import Appointments from "@/components/client/ClientAppointments.vue";
import Covenants from "@/components/client/Covenants.vue";
import Attachments from "@/components/client/Attachments.vue";
import ConfirmDialog from "@/components/common/ConfirmDialog.vue";
import AddEditClient from "@/components/common/AddEditClient.vue";
import Invoices from "@/components/invoice/Invoices.vue";
// import backButton from '@/mixins/backButton';

export default {
  name: "Event-Dialog",
  components: {
    Observations,
    AddEditClient,
    ConfirmDialog,
    Attachments,
    Invoices,
    Covenants,
  },
  props: ["users", "rooms", "typeevents", "groups"],

  data() {
    return {

      tab: 0,
      showTab: false,

      generalRules: [v => !!v || "Campo obrigatório"],
      rules: {
        required: value => {
          let result = true;
          if (this.tab === 0) {
            if (!value) {
              result = "Campo Obrigatório.";
            }
          } else {
            result = true;
          }
          return result;
        }
      },
      rules2: {
        required: value => {
          let result = true;
          if (this.tab === 1) {
            if (!value) {
              result = "Campo Obrigatório.";
            }
          } else {
            result = true;
          }
          return result;
        }
      },
      showDayError: false,

      clienteSelecionadoOpcoesOlho: null,
      dialog: false,
      optionsClient: false,
      optionsClientSelect: {},
      groupsClient: false,
      selectedGroup: null,
      dayChangeDialog: false,
      search: "",
      formValid: true,
      filteredList: [],
      clients: [],
      eventstatus: [],
      covenants: [],
      clientevents: {
        status: null,
        details: null
      },
      clientesmarcacao: [],
      statusclient: {},
      toggleStartDate: false,
      toggleEndDate: false,
      toggleStartHour: false,
      toggleEndHour: false,

      bulkToggleStartDate: false,
      bulkToggleEndDate: false,
      bulkToggleStartHour: false,
      bulkToggleEndHour: false,

      weekDays: [
        {
          description: 'Domingo',
          id: 0
        },
        {
          description: 'Segunda',
          id: 1
        },
        {
          description: 'Terça',
          id: 2
        },
        {
          description: 'Quarta',
          id: 3
        },
        {
          description: 'Quinta',
          id: 4
        },
        {
          description: 'Sexta',
          id: 5
        },
        {
          description: 'Sábado',
          id: 6
        }
      ],

      oldEvent: {},
      event: {
        startDate: "",
        endDate: "",
        startHour: "",
        endHour: "",
        bulkStartDate: "",
        bulkEndDate: "",
        bulkStartHour: "",
        bulkEndHour: "",
        UserId: '',
        RoomId: '',
        TypeeventId: '',
        details: '',
        selectedDays: [],
        ClientEvents: [],
        ClientAvencas: [],
        invoiced: false,
        iseventnotebreak: null
      },
      defaultEvent: {
        startDate: "",
        endDate: "",
        startHour: "",
        endHour: "",
        bulkStartDate: "",
        bulkEndDate: "",
        bulkStartHour: "",
        bulkEndHour: "",
        UserId: '',
        RoomId: '',
        TypeeventId: '',
        details: '',
        selectedDays: [],
        ClientEvents: [],
        ClientAvencas: [],
        invoiced: false,
        iseventnotebreak: null
      },
      bloquearEventoTodo: true,
      bloquearUser: false,
      bloquearClientes: false,
      bloquearEventoFaturado: false,
      bloquearDetalhes: false,
      bloquearSala: false,
      bloquearTipoDeServico: false,
      bloquearOpcoesCliente: false,
      processing: false,
      deletingEvent: false,
      loadingClients: false,
      clientTypeS: '',
      clientTypeP: '',
      datediscontinued: [],
      clienteglobalid: '',
      clienteglobalname: '',
      selectedClient: null,
      checkboxesReplacement: [],
      selectedClientemarcacoesReplacement: [],
      replacementstatusevent: false
    };
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    saveStartDate(date) {
      if (this.event.endDate) {
        this.event.endDate = date;
      }
      this.$refs.toggleStartDate.save(date);
    },
    openClientInfo(idclinte) {
      this.$refs.addEditClient.open(idclinte);
    },
    openClientInfoEmpty() {
      this.$refs.addEditClient.open();
    },
    async openObs(idcliente) {
      this.clients = (await ServiceClients.listartodos()).data;
      this.$refs.observations.open(_.find(this.clients, { 'id': idcliente }));
    },
    async openAtta(idcliente) {
      this.clients = (await ServiceClients.listartodos()).data;
      this.$refs.attachments.open(_.find(this.clients, { 'id': idcliente }));
    },
    openInvoices(client, event) {
      this.$refs.invoices.open(null, client, event);
    },
    async openCovenants(idcliente) {
      this.clients = (await ServiceClients.listartodos()).data;
      this.$refs.covenants.open(_.find(this.clients, { 'id': idcliente }));
    },
    customFilter(item, queryText, itemText) {
      return true;
    },
    async DeleteSelectedEvent() {
      // se clicarmos no apagar de uma nota
      if (this.event != null && this.event.iseventnotebreak === 2) {
        try {
          if (await this.$refs.confirm.open("Confirmação",
            "Confirma a remoção deste elemento?", {
            color: "green lighten-1",
            width: 400,
            zIndex: 200,
            noconfirm: false,
            noagree: false,
            disagreeText: "Não",
            agreeText: "Sim",
          })) {
          } else {
            return;
          }

          this.processing = true
          await ServiceNotes.delete(this.event.id);
          this.$emit('handleDelete', this.event.id);
          this.dialog = false;
          this.$store.dispatch("setSnackbar", {});

        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: "Ocorreu um erro ao eliminar a nota"
          });

        }
      } else {

        // se for de uma marcação
        try {

          let bulkDelete = false;
          if (this.event.linkedId) {
            if (await this.$refs.confirm.open("Confirmação",
              `Esta marcação faz parte de um conjunto de marcações periódicas.
              <br><br> Pretende eliminar todas as marcações deste conjunto?`, {
              color: "green lighten-1",
              width: 400,
              zIndex: 200,
              noconfirm: false,
              noagree: false,
              disagreeText: "Não, apenas esta",
              agreeText: "Sim, eliminar todas",
            })) {
              bulkDelete = true;
            }
          } else {

            if (await this.$refs.confirm.open("Confirmação",
              "Confirma a remoção deste elemento?", {
              color: "green lighten-1",
              width: 400,
              zIndex: 200,
              noconfirm: false,
              noagree: false,
              disagreeText: "Não",
              agreeText: "Sim",
            })) {
            } else {
              return;
            }
          }

          this.processing = true;

          if (bulkDelete) {
            const result = await ServiceCalendars.deletebulk(this.event.linkedId, this.event.startDate);
            this.$emit('handleDelete', result.data);

          } else {

            await ServiceCalendars.delete(this.event.id, this.event.TypeeventId, this.event.ClientEvents);

            this.$emit('handleDelete', this.event.id);
          }

          this.dialog = false;
          this.$store.dispatch("setSnackbar", {});
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: "Ocorreu um erro ao eliminar o evento"
          });

        }
      }
      this.processing = false;
    },
    async open(event, clientId, clientName, novoeventoclick, novoeventoreposicaoclick) {
      // let query = _.clone(this.$route.query, true);
      // query.dialogSearch = true;
      // this.$router.push({
      //   query: query
      // });

      this.tab = 0;
      // se clicarmos numa nota, o iseventnotebreak está 2 e abre na tab 2
      if (event != null && event.iseventnotebreak === 2) {
        this.tab = 2;
      }

      this.bloquearEventoTodo = true;
      this.bloquearUser = false;
      this.bloquearClientes = false;
      this.bloquearTipoDeServico = false;
      this.bloquearEventoFaturado = true;
      this.bloquearSala = false;
      this.bloquearOpcoesCliente = false;
      this.bloquearDetalhes = false;

      this.clientesmarcacao = []
      // dar reset às coisas de repor marcação
      this.checkboxesReplacement = []
      this.selectedClientemarcacoesReplacement = []
      this.replacementstatusevent = false

      if (novoeventoreposicaoclick != null) {
        this.showTab = false;
        this.event.id = null
        this.event.UserId = novoeventoreposicaoclick.UserId;
        this.event.RoomId = novoeventoreposicaoclick.RoomId;
        this.event.invoiced = novoeventoreposicaoclick.invoiced;
        this.event.TypeeventId = novoeventoreposicaoclick.TypeeventId;
        this.event.details = novoeventoreposicaoclick.details;
        this.event.linkedId = novoeventoreposicaoclick.linkedId;
        this.event.startDate = novoeventoreposicaoclick.dataInicio
        this.event.startHour = novoeventoreposicaoclick.horaInicio
        this.event.endDate = novoeventoreposicaoclick.dataFim
        this.event.endHour = novoeventoreposicaoclick.horaFim
        this.event.iddoeventoanterior = novoeventoreposicaoclick.id
        this.clientesmarcacao = []
        this.event.ClientEvents = []
        this.event.ClientAvencas = []


        // sempre que é uma marcação de reposição bloqueia o tipo de serviço
        this.bloquearTipoDeServico = true
        this.bloquearClientes = true
        this.replacementstatusevent = true
        this.bloquearOpcoesCliente = true

        novoeventoreposicaoclick.arrayDeClientes.forEach(async (item, index) => {
          this.clientesmarcacao.push({id: item.id, name: item.name, covenantsclient: undefined, covenantcycletext: item.covenantcycletext});
          this.event.ClientEvents.push(item.id);
          this.event.ClientAvencas.push({ClientId: item.id, CovenantId: item.CovenantId, covenantcycleid: item.covenantcycleid});
        })

      } else if (novoeventoclick != null) {
        this.showTab = true;
        this.event = Object.assign({}, this.defaultEvent);

        if (this.rooms.length == 1) {
          this.event.RoomId = this.rooms[0].id;
        }
        if (this.typeevents.length == 1) {
          this.event.TypeeventId = this.typeevents[0].id;
        }

        this.event.startDate = novoeventoclick.dataInicio
        this.event.startHour = novoeventoclick.horaInicio
        this.event.endDate = novoeventoclick.dataFim
        this.event.endHour = novoeventoclick.horaFim

        this.event.bulkStartDate = novoeventoclick.dataInicio
        this.event.bulkStartHour = novoeventoclick.horaInicio
        this.event.bulkEndDate = novoeventoclick.dataFim
        this.event.bulkEndHour = novoeventoclick.horaFim
      } else {
        if (event) {
        //editar evento
        this.oldEvent = event;
        this.showTab = false;
        this.event.UserId = event.UserId;
        this.event.RoomId = event.RoomId;
        this.event.invoiced = event.invoiced;
        this.event.TypeeventId = event.TypeeventId;
        this.event.details = event.details;
        this.event.id = event.id;
        this.event.linkedId = event.linkedId;
        this.event.iseventnotebreak = event.iseventnotebreak;
        this.event.ClientEvents = event.ClientEvents.map(item => item.Client.id)

        this.event.startDate = event.start.split(' ')[0];
        this.event.startHour = event.start.split(' ')[1];
        this.event.endDate = event.end.split(' ')[0];
        this.event.endHour = event.end.split(' ')[1];

        // sempre que é uma marcação já criada (editar marcação) bloqueia o tipo de serviço
        this.bloquearTipoDeServico = true


        if (this.event.ClientEvents[0] != undefined) {
          this.clientesmarcacao = (await ServiceClients.listClientsMarcacao(this.event.ClientEvents, this.event.id)).data;
        }

        } else {
          //criar evento
          this.showTab = true;
          this.event = Object.assign({}, this.defaultEvent);
          if (this.rooms.length == 1) {
            this.event.RoomId = this.rooms[0].id;
          }
          if (this.typeevents.length == 1) {
            this.event.TypeeventId = this.typeevents[0].id;
          }
          this.bloquearTipoDeServico = false
        }
      }



      if (clientId && clientName && !event) {
        // se criar uma marcação a partir da ficha do cliente bloqueia o cliente para não dar para remover e não deixa adicionar mais
        this.clienteglobalid = clientId,
        this.clienteglobalname = clientName
        this.bloquearClientes = true;
      }

      if (this.event.invoiced === true) {
        // se a marcação tiver faturada o bloquearEventoFaturado fica true
        this.bloquearEventoTodo = null;
        this.bloquearUser = null;
        this.bloquearClientes = null;
        this.bloquearEventoFaturado = true;
      } else {
        this.bloquearEventoFaturado = false
      }



      // se tiver a criar uma marcação
      if (!this.event.UserId) {
        // desbloqueia os campos todos
        this.bloquearEventoTodo = false;
        // se a pessoa logada tiver permissões para criar para todos
        if (this.$store.state.user.create_everyones_appointments_module === true) {
          this.bloquearUser = true;
        } else {
          // se a pessoa logada não tiver permissões para criar para todos o user é logo o dela e não dá para mexer
          this.bloquearUser = false;
          this.event.UserId = this.$store.state.user.id;
        }
      } else {
        // se ele poder ver marcações dos outros
        if (this.$store.state.user.see_everyones_appointments_module === true) {
          this.bloquearEventoTodo = true;
          if (this.event.UserId === this.$store.state.user.id) {
            this.bloquearEventoTodo = false;
            // se a pessoa logada tiver permissões para criar para todos
            if (this.$store.state.user.create_everyones_appointments_module === true) {
              this.bloquearUser = true;
            } else {
              // se a pessoa logada não tiver permissões para criar para todos
              this.bloquearUser = false;
            }
          } else {
            // se a pessoa logada tiver permissões para criar para todos
            if (this.$store.state.user.create_everyones_appointments_module === true) {
              this.bloquearEventoTodo = false;
              this.bloquearUser = true;
            }
          }

        } else {
          // se ela não poder ver as marcações dos outros
          this.bloquearEventoTodo = true;
        }
      }

      // se a data do evento é menor que ontem
      if (this.event.id != null) {
        const datainicioevento = new Date(this.event.startDate);
        const datadehoje = new Date();
        datadehoje.setDate(datadehoje.getDate() - 1);
        if (datainicioevento < datadehoje) {
          this.bloquearUser = false;
          this.bloquearSala = true;
          this.bloquearDetalhes = true;
        }
      }


      this.dialog = true;
    },
    FilterItems() {
      this.filteredList = _.filter(this.itemList, item => {
        return (
          item[this.description]
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) > -1
        );
      });
    },
    formatarCiclos(item) {
      if (item.Plan != null) {
        return `${item.Typeevent.name} - ${item.Plan.name} - ${this.formatDate(item.start_date)} - ${this.formatDate(item.end_date)}`;
      } else {
        return `${item.Typeevent.name} - ${this.formatDate(item.start_date)} - ${this.formatDate(item.end_date)}`;
      }
    },
    async myFn(newVal) {
      if (newVal) {
        this.loadingClients = true;
        this.clients = (await ServiceClients.listDebounce(newVal)).data;
        this.$refs.cbClient.$el.focus();
        this.loadingClients = false;
      }
    },
    async selectClienteMarcacao (selectedClient) {
      //vais bsucar as avenças do tipo de serviço e cliente selecionado
      this.covenants = (await ServiceCovenants.getClientCovenants(selectedClient.id, false, this.event.TypeeventId)).data;
      //adiciona ao clientesmarcacao o cliente, com as suas avenças
      this.clientesmarcacao.push({id: selectedClient.id, name: selectedClient.name, covenantsclient: this.covenants});
      //isto é o que vai para o backoffice, ou seja, os clientes novos selecionados, e avenças se escolheram
      this.event.ClientEvents.push(selectedClient.id);
      this.event.ClientAvencas.push({ClientId: selectedClient.id, CovenantId: null});
      //resetar o select de clientes
      this.selectedClient = null;
    },
    async handleRemoverClientes () {
      // se o bloquearClientes é true, quando eu faço o handleRemoverClientes (ou seja, quando crio avença pelas opções do cliente) faz o typeeventChange com o tipo de serviço que estava selecionado
      if (this.bloquearClientes == true) {
        this.typeeventChange(this.event.TypeeventId)
      } else {
        // vai remover o cliente que clicamos no olho destes arrays
        this.event.ClientEvents = this.event.ClientEvents.filter(clientId => clientId !== this.clienteSelecionadoOpcoesOlho);
        this.clientesmarcacao = this.clientesmarcacao.filter(cliente => cliente.id !== this.clienteSelecionadoOpcoesOlho);
        this.event.ClientAvencas = this.event.ClientAvencas.filter(cliente => cliente.ClientId !== this.clienteSelecionadoOpcoesOlho);
      }
    },
    async selectClienteAvenca(item) {

      const datadeinicioevento = new Date(this.event.endDate);

      // Verifica se há ciclos válidos
      const hasValidCycle = item.CovenantCycles?.some(cycle => {
        const startDate = new Date(cycle.start_date);
        const endDate = new Date(cycle.end_date);
        return datadeinicioevento >= startDate && datadeinicioevento <= endDate;
      });

      // Atualiza o CovenantId no array ClientAvencas
      for (const clientavenc of this.event.ClientAvencas) {
        if (item.ClientId === clientavenc.ClientId) {
          clientavenc.CovenantId = item.id;
        }
      }

      // Define o valor de temciclo (1 se não houver ciclos válidos, 0 caso contrário)
      let temciclo = 0;
      if (!hasValidCycle) {
        temciclo = 1;
      }

      // Atualiza a linha correspondente no array clientesmarcacao
      const cliente = this.clientesmarcacao.find(c => c.id === item.ClientId);
      if (cliente) {
        cliente.temciclo = temciclo; // Adiciona ou atualiza a propriedade temciclo
      }
    },
    async removeClienteMarcacao (selectedClient) {
      this.clientesmarcacao = this.clientesmarcacao.filter(client => client.id !== selectedClient.id);
      this.event.ClientEvents = this.event.ClientEvents.filter(client => client !== selectedClient.id);
    },
    async abrirGrupos () {
      this.groupsClient = true
    },
    async grupoEscolhido () {
      this.groupsClient = false
      const grupoescolhido = (await ServiceGroups.show(this.selectedGroup, false)).data;
      for (const client of grupoescolhido.Clients) {
        this.covenants = (await ServiceCovenants.getClientCovenants(client.id, false, this.event.TypeeventId)).data;
        this.clientesmarcacao.push({id: client.id, name: client.name, covenantsclient: this.covenants});
        this.event.ClientEvents.push(client.id);
        this.event.ClientAvencas.push({ClientId: client.id, CovenantId: null});
      }
    },
    pickItem(item) {
      //   this.selectedItem = item[this.description];
      if (this.fullReturn) {
        this.$emit("selection", item);
      } else {
        this.$emit("selection", item[this.id]);
      }
      this.dialog = false;
    },
    addTime(time) {
      let currentDate;

      if (this.event.endDate && this.event.endHour) {
        currentDate = moment(this.event.endDate + 'T' + this.event.endHour);
      } else {
        currentDate = moment(this.event.startDate + 'T' + this.event.startHour);
      }

      currentDate = moment(currentDate)
        .add(time, "H")
        .format("YYYY-MM-DD HH:mm");

      this.event.endDate = currentDate.split(' ')[0];
      this.event.endHour = currentDate.split(' ')[1];
    },
    async typeeventChange(item) {
        let horainicio = ''

        if (this.tab === 0) {
          horainicio = this.event.startHour
        } else {
          horainicio = this.event.bulkStartHour
        }

        const typeevent = (await ServiceTypeevents.show(item)).data;
        if (typeevent.duration != null && horainicio != '') {
          let [hoursStarteDate, minutesStarteDate] = horainicio.split(':').map(Number);
          let [hoursDuration, minutesDuration] = typeevent.duration.split(':').map(Number);

          let totalMinutes = minutesStarteDate + minutesDuration;
          let totalHours = hoursStarteDate + hoursDuration + Math.floor(totalMinutes / 60);

          totalMinutes = totalMinutes % 60;
          totalHours = totalHours % 24;
          let result = `${String(totalHours).padStart(2, '0')}:${String(totalMinutes).padStart(2, '0')}`;

          if (this.tab === 0) {
            this.event.endHour = result
          } else {
            this.event.bulkEndHour = result
          }
        }


        //se o bloquearClientes for true, ele limpa os arrays e volta a fazer push das coisas do cliente global ou seja do que escolhemos na lista de clientes
        if (this.bloquearClientes == true) {
          this.covenants = []
          this.defaultEvent.ClientEvents = []
          this.event.ClientEvents = []
          this.defaultEvent.ClientAvencas = []
          this.event.ClientAvencas = []
          this.clientesmarcacao = []

          this.covenants = (await ServiceCovenants.getClientCovenants(this.clienteglobalid, false, item)).data;
          this.clientesmarcacao.push({id: this.clienteglobalid, name: this.clienteglobalname, covenantsclient: this.covenants});
          this.event.ClientEvents.push(this.clienteglobalid);
          this.event.ClientAvencas.push({ClientId: this.clienteglobalid, CovenantId: null});
        } else {
          // sempre que estamos a criar marcação e trocamos o tipo de serviço limpa tudo
          this.covenants = []
          this.defaultEvent.ClientEvents = []
          this.event.ClientEvents = []
          this.defaultEvent.ClientAvencas = []
          this.event.ClientAvencas = []
          this.clientesmarcacao = []
        }
    },
    async submitedData(item) {
      //quando editamos a ficha do cliente
      this.clients = (await ServiceClients.list(null, null, null)).data;
      this.event.ClientEvents.push(item.id);
    },

    async createEventChooser() {
      if (this.tab === 0) {
        if (this.event.linkedId && this.replacementstatusevent === false) {

          const oldStartDate = new Date(this.oldEvent.start);
          let weekDay = '';
          switch (oldStartDate.getDay()) {
            case 0:
              weekDay = 'Domingo';
              break;
            case 1:
              weekDay = 'Segunda-Feira';
              break;
            case 2:
              weekDay = 'Terça-Feira';
              break;
            case 3:
              weekDay = 'Quarta-Feira';
              break;
            case 4:
              weekDay = 'Quinta-Feira';
              break;
            case 5:
              weekDay = 'Sexta-Feira';
              break;
            case 6:
              weekDay = 'Sábado';
              break;
            default:
              break;
          }

          if (await this.$refs.confirm.open("Confirmação",
            `Esta marcação faz parte de um conjunto de marcações periódicas.<br><br> Pretende aplicar esta alteração a todas as marcações de ${weekDay} deste conjunto?`, {
            color: "green lighten-1",
            width: 400,
            zIndex: 200,
            noconfirm: false,
            noagree: false,
            disagreeText: "Não, apenas esta",
            agreeText: "Sim",
          })) {
            this.bulkEdit();
          } else {
            this.addNewEvent();
          }
        } else {
          this.addNewEvent();
        }
      } else if (this.tab === 1) {
        this.bulkCreateEvents();
      } else if (this.tab === 2) {
        this.addEditNewNote();
      }
      await ServiceClientevents.darmarcacoescomofaturadas()
    },
    async sendSms(idcliente) {
      try {
        this.processing = true;
        const results = await ServiceSendSms.sendMsgIndividual(idcliente, this.event.id);
        this.processing = false;

        this.$store.dispatch("setSnackbar", {});

        this.$emit('handleStatus');

        //atualizar clientes da marcação
        if (this.event.ClientEvents[0] != undefined) {
          this.clientesmarcacao = (await ServiceClients.listClientsMarcacao(this.event.ClientEvents, this.event.id)).data;
        }

      } catch (error) {
        this.processing = false;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }

    },
    async bulkEdit() {
      try {
        if (this.$refs.newEventForm.validate()) {
          this.processing = true;

          const oldStartDate = new Date(this.oldEvent.start);
          const oldEndDate = new Date(this.oldEvent.end);

          const newStartDate = new Date(this.event.startDate + " " + this.event.startHour);
          const newEndDate = new Date(this.event.endDate + " " + this.event.endHour);

          let startDiference = newStartDate.getTime() - oldStartDate.getTime();
          let endDiference = newEndDate.getTime() - oldEndDate.getTime();

          const results = await ServiceCalendars.bulkValidateForEdit(
            this.event.linkedId,
            this.event.RoomId,
            this.event.UserId,
            startDiference,
            endDiference,
            oldStartDate,
            this.event.TypeeventId,
          );

          /*erro908*/
          //Verificação se o funcionário tem pausas nos intervalos de tempo selecionados
          const itensuserbreak = results.data.haveuserbreakitems.map(item => `${item}<br>`).join("");
          if (results.data.haveuserbreak == true) {
            if (!await this.$refs.confirm.open("Confirmação",
              `O funcionário que escolheu tem a <br>${itensuserbreak}<br>No intervalo de tempo seleccionado. Prentende continuar com a operação?`, {
              color: "green lighten-1",
              width: 400,
              zIndex: 200,
              noconfirm: false,
              noagree: false,
              disagreeText: "Cancelar",
              agreeText: "Continuar",
            })) {
              this.processing = false;
              return;
            }
          }


          /*erro907*/
          //Verificação quando o tipo de serviço é de uma vez por cliente
          //se for marcação periódica e o tipo de serviço for de uma vez por cliente dá logo erro
          if (results.data.typeeventonetime == true) {
            if (!await this.$refs.confirm.open("ERRO",
              "Não pode editar a marcação periódica pois esse tipo de serviço so pode ter uma marcação", {
              color: "red lighten-1",
              width: 400,
              zIndex: 200,
              noconfirm: false,
              noagree: false,
              disagreeText: "Cancelar",
              agreeText: "Continuar",
            })) {
              this.processing = false;
              return;
            }
            this.processing = false;
          } else {

            /*erro909*/
            //se o funcionário e a sala tiveram já ocupados
            if (results.data.busyEmployee.length > 0 && results.data.busyRoom.length > 0) {
              if (!await this.$refs.confirm.open("Confirmação",
                `Funcionário tem conflito de marcações nos dias:
                  <br>${results.data.busyEmployee}
                  <br><br>Sala tem conflito de marcações nos dias:
                  <br>${results.data.busyRoom}
                  <br><br>Prentende continuar com a operação?`, {
                color: "green lighten-1",
                width: 400,
                zIndex: 200,
                noconfirm: false,
                noagree: false,
                disagreeText: "Cancelar",
                agreeText: "Continuar",
              })) {
                this.processing = false;
                return;
              }

            }

            //se o funcionário já tiver ocupado
            if (results.data.busyEmployee.length > 0 && results.data.busyRoom.length == 0) {
              if (!await this.$refs.confirm.open("Confirmação",
                `Funcionário tem conflito de marcações nos dias:
                  <br>${results.data.busyEmployee}
                  <br><br>Prentende continuar com a operação?`, {
                color: "green lighten-1",
                width: 400,
                zIndex: 200,
                noconfirm: false,
                noagree: false,
                disagreeText: "Cancelar",
                agreeText: "Continuar",
              })) {
                this.processing = false;
                return;
              }

            }

            //se a sala já tiver ocupada
            if (results.data.busyEmployee.length == 0 && results.data.busyRoom.length > 0) {
              if (!await this.$refs.confirm.open("Confirmação",
                `Sala tem conflito de marcações nos dias:
                  <br>${results.data.busyRoom}
                  <br><br>Prentende continuar com a operação?`, {
                color: "green lighten-1",
                width: 400,
                zIndex: 200,
                noconfirm: false,
                noagree: false,
                disagreeText: "Cancelar",
                agreeText: "Continuar",
              })) {
                this.processing = false;
                return;
              }

            }


            //editar marcação periódica
            const response = await ServiceCalendars.bulkEditEvent(this.event.ClientEvents,this.event.linkedId, this.event.RoomId, this.event.TypeeventId, this.event.UserId,
              startDiference, endDiference, oldStartDate, this.event.details, this.event.ClientAvencas);
            this.processing = false;

            this.$emit('handleEvent', response.data);

            this.$store.dispatch("setSnackbar", {});

            this.dialog = false;
          }

        }
      } catch (error) {
        this.processing = false;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    async addNewEvent() {
      try {
        if (this.$refs.newEventForm.validate()) {
          this.processing = true;

          const results = await ServiceCalendars.validateNewEvent(
            this.event.UserId,
            this.event.RoomId,
            this.event.startDate + " " + this.event.startHour,
            this.event.endDate + " " + this.event.endHour,
            this.event.id,
            this.event.ClientEvents,
            this.event.TypeeventId,
            this.event.ClientAvencas
          );


          /*erro903*/
          //Verificação se o funcionário tem pausas nos intervalos escolhidos
          if (results.data.haveuserbreak == true) {
            if (!await this.$refs.confirm.open("Confirmação",
              "O funcionário que escolheu tem " + results.data.haveuserbreakitems.details + " no intervalo de tempo seleccionado. Prentende continuar com a operação?", {
              color: "green lighten-1",
              width: 400,
              zIndex: 200,
              noconfirm: false,
              noagree: false,
              disagreeText: "Cancelar",
              agreeText: "Continuar",
            })) {
              this.processing = false;
              return;
            }
          }

          /*erro904*/
          //se o funcionário e a sala tiveram já ocupados
          if (results.data.busyEmployee && results.data.busyRoom) {
            if (!await this.$refs.confirm.open("Confirmação",
              "Funcionário e Sala já têm marcações no intervalo de tempo seleccionado. Prentende continuar com a operação?", {
              color: "green lighten-1",
              width: 400,
              zIndex: 200,
              noconfirm: false,
              noagree: false,
              disagreeText: "Cancelar",
              agreeText: "Continuar",
            })) {
              this.processing = false;
              return;
            }
          }

          //se o funcionário já tiver ocupado
          if (results.data.busyEmployee && !results.data.busyRoom) {
            if (!await this.$refs.confirm.open("Confirmação",
              "Funcionário já tem marcação no intervalo de tempo seleccionado. Prentende continuar com a operação?", {
              color: "green lighten-1",
              width: 400,
              zIndex: 200,
              noconfirm: false,
              noagree: false,
              disagreeText: "Cancelar",
              agreeText: "Continuar",
            })) {
              this.processing = false;
              return;
            }
          }

          //se a sala já tiver ocupada
          if (!results.data.busyEmployee && results.data.busyRoom) {
            if (!await this.$refs.confirm.open("Confirmação",
              "Sala já tem marcação no intervalo de tempo seleccionado. Prentende continuar com a operação?", {
              color: "green lighten-1",
              width: 400,
              zIndex: 200,
              noconfirm: false,
              noagree: false,
              disagreeText: "Cancelar",
              agreeText: "Continuar",
            })) {
              this.processing = false;
              return;
            }
          }




          //so verifica se o cliente tem já marcação com este tipo de serviço se não for reposição
          if (this.replacementstatusevent != true) {
            /*erro905*/
            //Verificação quando o tipo de serviço é de uma vez por cliente (Quando edita uma marcação e quando cria um nova)
            //se for a editar uma marcação vai ver se algum dos clientes já fez aquele serviço
            if (results.data.typeeventonetime == true && this.event.linkedId == null && this.event.id != undefined) {
              if (!await this.$refs.confirm.open("Erro",
                "Um dos clientes já utilizou esse serviço uma vez!!! <br><br>Se está a adicionar um cliente que ainda não existia na marcação clique em continuar. <br><br>Atenção se o novo cliente que está a adicionar já tiver marcação com este tipo de serviço não vai ser adicionado", {
                color: "red lighten-1",
                width: 400,
                zIndex: 200,
                noconfirm: false,
                noagree: false,
                disagreeText: "Cancelar",
                agreeText: "Continuar",
              })) {
                this.processing = false;
                return;
              }
            //se for a criar uma marcação vai ver se algum dos clientes já fez aquele serviço, se já fez tem de o remover
            } else if (results.data.typeeventonetime == true) {
              if (!await this.$refs.confirm.open("Erro",
                "Um dos clientes já utilizou esse serviço uma vez, tem de o remover!", {
                color: "red lighten-1",
                width: 400,
                zIndex: 200,
                disagreeText: "Cancelar",
                noconfirm: false,
                noagree: true,
              })) {
                this.processing = false;
                return;
              } else {
                this.processing = false;
                return;
              }
            }
          }






          this.event.start = this.event.startDate + " " + this.event.startHour;
          this.event.end = this.event.endDate + " " + this.event.endHour;


          /*erro906*/
          if (this.replacementstatusevent == true) {
            // se for reposição
            //validar se existe algum cliente já com reposições excedidas
            const results = await ServiceCalendars.validateNewEventReplacement(this.event);
            if (results.data.exceeded_replacement === true) {
              if (!await this.$refs.confirm.open("Confirmação",
                `O cliente já excedeu o número de reposições desse ciclo. Quer criar na mesma a marcação de reposição?`, {
                color: "red lighten-1",
                width: 400,
                zIndex: 200,
                noconfirm: false,
                noagree: false,
                disagreeText: "Cancelar",
                agreeText: "Continuar",
              })) {
                this.processing = false;
                return;
              }
            }
            const response = await ServiceCalendars.addEventReplacement(this.event);
            this.event.id = response.data.id;
            this.$emit('handleEvent', response.data);
          } else {
            // se não for reposição
            const response = await ServiceCalendars.addEvent(this.event);
            this.event.id = response.data.id;
            this.$emit('handleEvent', response.data);
          }


          // if (_.find(this.users, ["id", this.selectedUser.id]).valid) {
          //   this.events = this.events.concat(newEvent.data);
          // }

          // this.fullEvents = this.fullEvents.concat(newEvent.data);

          this.processing = false;


          this.$emit('handleReplacementEventClose');

          // enviar sms
          /*
          if (this.$store.state.user.settings.sendSms === true) {
            if (
              await this.$refs.confirm.open(
                "Confirmação",
                "Operação efectuada com sucesso.<br>Deseja enviar SMS de confirmação?",
                {
                  color: "green lighten-1",
                  width: 400,
                  zIndex: 200,
                  noconfirm: false,
                  noagree: false,
                  disagreeText: "Não",
                  agreeText: "Enviar",
                }
              )
            ) {
              for (const cliente of this.clientesmarcacao) {
                await this.sendSms(cliente.id);
              }
            }
          }
          */

          this.covenants = []
          this.defaultEvent.ClientEvents = []
          this.event.ClientEvents = []
          this.defaultEvent.ClientAvencas = []
          this.event.ClientAvencas = []
          this.clientesmarcacao = []

          this.dialog = false;

        }
      } catch (error) {
        this.processing = false;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    async addEditNewNote () {
      try {
        this.processing = true;

        const novanota = {
          id: this.event.id,
          start: this.event.startDate + " " + this.event.startHour,
          end: this.event.endDate + " " + this.event.endHour,
          details: this.event.details,
          RoomId: 1,
          TypeeventId: 1,
          UserId: this.$store.state.user.id,
        }

        const response = await ServiceNotes.addEditNote(novanota);
        this.processing = false;

        this.$emit('handleEvent', response.data);
        this.$store.dispatch("setSnackbar", {});

        this.dialog = false

      } catch (error) {
        this.processing = false;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }

    },
    async bulkCreateEvents() {
      try {

        let hasError = false;
        if (this.event.selectedDays.length == 0) {
          this.showDayError = true;
          hasError = true;
        } else {
          this.showDayError = false;
        }

        if (!this.$refs.newEventForm.validate()) {
          hasError = true;
        }

        if (hasError) {
          return;
        }

        this.processing = true;

        const results = await ServiceCalendars.bulkValidate(
          this.event.UserId,
          this.event.RoomId,
          this.event.bulkStartDate,
          this.event.bulkEndDate,
          this.event.bulkStartHour,
          this.event.bulkEndHour,
          this.event.selectedDays,
          this.event.TypeeventId,
        );
        console.log("2")
        /*erro901*/
        //Verificação se o funcionário tem pausas nos intervalos de tempo selecionados
        if (results.data.haveuserbreak == true) {
          const itensuserbreak = results.data.haveuserbreakitems.map(item => `${item}<br>`).join("");
          if (!await this.$refs.confirm.open("Confirmação",
            `O funcionário que escolheu tem a <br>${itensuserbreak}<br>No intervalo de tempo seleccionado. Prentende continuar com a operação?`, {
            color: "green lighten-1",
            width: 400,
            zIndex: 200,
            noconfirm: false,
            noagree: false,
            disagreeText: "Cancelar",
            agreeText: "Continuar",
          })) {
            this.processing = false;
            return;
          }
        }
        console.log("3")
        /*erro900*/
        //Verificação quando o tipo de serviço é de uma vez por cliente
        //se for marcação periódica e o tipo de serviço for de uma vez por cliente dá logo erro
        if (results.data.typeeventonetime == true) {
          if (!await this.$refs.confirm.open("ERRO",
            "Tipo de serviço só pode ter uma marcação. Não pode criar uma marcação periódica com esse tipo de serviço", {
            color: "red lighten-1",
            width: 400,
            zIndex: 200,
            disagreeText: "Cancelar",
            noconfirm: false,
            noagree: true,
          })) {
            this.processing = false;
            return;
          }
          this.processing = false;
        } else {
          console.log("4")
          /*erro902*/
          //se o funcionário e a sala tiveram já ocupados
            if (results.data.busyEmployee.length > 0 && results.data.busyRoom.length > 0) {
              if (!await this.$refs.confirm.open("Confirmação",
                `Funcionário tem conflito de marcações nos dias:
                  <br>${results.data.busyEmployee}
                    <br><br>Sala tem conflito de marcações nos dias:
                    <br>${results.data.busyRoom}
                    <br><br>Prentende continuar com a operação?`, {
                color: "green lighten-1",
                width: 400,
                zIndex: 200,
                noconfirm: false,
                noagree: false,
                disagreeText: "Cancelar",
                agreeText: "Continuar",
              })) {
                this.processing = false;
                return;
              }

            }

            //se o funcionário já tiver ocupado
            if (results.data.busyEmployee.length > 0 && results.data.busyRoom.length == 0) {
              if (!await this.$refs.confirm.open("Confirmação",
                `Funcionário tem conflito de marcações nos dias:
                  <br>${results.data.busyEmployee}
                    <br><br>Prentende continuar com a operação?`, {
                color: "green lighten-1",
                width: 400,
                zIndex: 200,
                noconfirm: false,
                noagree: false,
                disagreeText: "Cancelar",
                agreeText: "Continuar",
              })) {
                this.processing = false;
                return;
              }

            }

            //se a sala já tiver ocupada
            if (results.data.busyEmployee.length == 0 && results.data.busyRoom.length > 0) {
              if (!await this.$refs.confirm.open("Confirmação",
                `Sala tem conflito de marcações nos dias:
                    <br>${results.data.busyRoom}
                    <br><br>Prentende continuar com a operação?`, {
                color: "green lighten-1",
                width: 400,
                zIndex: 200,
                noconfirm: false,
                noagree: false,
                disagreeText: "Cancelar",
                agreeText: "Continuar",
              })) {
                this.processing = false;
                return;
              }
            }
            console.log("1")
            //Criar marcações periódicas
            const response = await ServiceCalendars.bulkAddEvent(this.event.ClientEvents, this.event.RoomId, this.event.TypeeventId, this.event.UserId,
              this.event.bulkStartDate, this.event.bulkEndDate, this.event.bulkStartHour, this.event.bulkEndHour, this.event.selectedDays, this.event.details, this.event.ClientAvencas);

            this.covenants = []
            this.defaultEvent.ClientEvents = []
            this.event.ClientEvents = []
            this.defaultEvent.ClientAvencas = []
            this.event.ClientAvencas = []
            this.clientesmarcacao = []


            this.processing = false;

            this.$store.dispatch("setSnackbar", {});

            this.$emit('handleEvent', response.data);

            this.dialog = false;
        }
      } catch (error) {
        this.processing = false;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    close() {
      this.dialog = false;
    },
    async chipClickClient(item) {
      this.clienteSelecionadoOpcoesOlho = item.id
      this.optionsClientSelect = item
      this.optionsClient = true
      if (this.event.id != undefined) {
        this.clientevents = (await ServiceClientevents.marcacaocliente(item.id, this.event.id)).data;
      }
    },
    async editStatusEvent () {
      try{
          await ServiceClientevents.update(this.clientevents)
          this.$store.dispatch("setSnackbar", {});
          this.$emit('handleStatus');

          // atualizar calendario
          //const response = await ServiceCalendars.addEvent(this.event);
          //this.$emit('handleEvent', response.data);

          if (this.event.ClientEvents[0] != undefined) {
            this.clientesmarcacao = (await ServiceClients.listClientsMarcacao(this.event.ClientEvents, this.event.id)).data;
          }
          this.optionsClient = false
      } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
      }
    },
    selectStatus(value) {
      this.clientevents.status = value;
    },
    onCheckboxChangeReplacement (clientemarcacao) {
      if (this.checkboxesReplacement[clientemarcacao.id]) {
        this.selectedClientemarcacoesReplacement.push(clientemarcacao);
      } else {
        const indexToRemove = this.selectedClientemarcacoesReplacement.findIndex(
          (item) => item.id === clientemarcacao.id
        );
        if (indexToRemove !== -1) {
          this.selectedClientemarcacoesReplacement.splice(indexToRemove, 1);
        }
      }
    },
    async createReplacementClients () {
      if (await this.$refs.confirm.open("Confirmação",
        "Escolha um dia do calendário para a reposição?", {
        color: "green lighten-1",
        width: 400,
        zIndex: 200,
        noconfirm: false,
        noagree: false,
        disagreeText: "Não",
        agreeText: "Sim",
      }))
      {
        this.dialog = false;
        this.$emit('handleReplacementEvent', this.event, this.selectedClientemarcacoesReplacement);
      } else {
        return;
      }

    },
    async sairdareposicao () {
      if (await this.$refs.confirm.open("Confirmação",
        "Que mesmo sair da reposição?", {
        color: "green lighten-1",
        width: 400,
        zIndex: 200,
        noconfirm: false,
        noagree: false,
        disagreeText: "Não",
        agreeText: "Sim",
      }))
      {
        this.dialog = false;
        this.$emit('handleReplacementEventClose');
      } else {
        return;
      }
    },
    statusColor(value) {
      for (let statu of this.eventstatus) {
        if (statu.value === value) {
          return statu.color;
        }
      }
    },
    statusReplacement(value) {
      for (let statu of this.eventstatus) {
        if (statu.value === value) {
          return statu.replacement;
        }
      }
    },
    navGuards() {
      if (this.$refs.addEditClient) {
        if (this.$refs.addEditClient.show) {
          this.$refs.addEditClient.show = false;
          return true;
        }
      }

      if (this.$refs.observations) {
        if (this.$refs.observations.editDialog) {
          this.$refs.observations.editDialog = false;
          return true;
        }

        if (this.$refs.observations.show) {
          this.$refs.observations.show = false;
          return true;
        }
      }

      if (this.$refs.attachments) {
        if (this.$refs.attachments.editDialog) {
          this.$refs.attachments.editDialog = false;
          return true;
        }

        if (this.$refs.attachments.show) {
          this.$refs.attachments.show = false;
          return true;
        }
      }

      if (this.$refs.invoices) {
        if (this.$refs.invoices.editDialog) {
          this.$refs.invoices.editDialog = false;
          return true;
        }

        if (this.$refs.invoices.show) {
          this.$refs.invoices.show = false;
          return true;
        }
      }


      if (this.$refs.covenants) {
        if (this.$refs.covenants.editDialog) {
          this.$refs.covenants.editDialog = false;
          return true;
        }

        if (this.$refs.covenants.show) {
          this.$refs.covenants.show = false;
          return true;
        }
      }


      if (this.toggleStartDate || this.toggleEndDate || this.toggleStartHour || this.toggleEndHour || this.bulkToggleStartDate || this.bulkToggleEndDate || this.bulkToggleStartHour || this.bulkToggleEndHour) {
        this.toggleStartDate = this.toggleEndDate = this.toggleStartHour = this.toggleEndHour = this.bulkToggleStartDate = this.bulkToggleEndDate = this.bulkToggleStartHour = this.bulkToggleEndHour = false;
        return true;
      }

      if (this.dialog) {
        this.dialog = false;
        return true;
      }

    }
  },
  created() {



    this.clientTypeP = config.CLIENT_TYPE_PLURAL;
    this.clientTypeS = config.CLIENT_TYPE_SINGULAR;
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {

      // if (this.$refs.addEditClient) {
      //   if (this.$refs.addEditClient.show) {
      //     this.$refs.addEditClient.show = false;
      //     next(false);
      //     return;
      //   }
      // }

      // if (this.$refs.observations) {
      //   if (this.$refs.observations.editDialog) {
      //     this.$refs.observations.editDialog = false;
      //     next(false);
      //     return;
      //   }

      //   if (this.$refs.observations.show) {
      //     this.$refs.observations.show = false;
      //     next(false);
      //     return;
      //   }
      // }


      // if (this.toggleStartDate || this.toggleEndDate || this.toggleStartHour || this.toggleEndHour || this.bulkToggleStartDate || this.bulkToggleEndDate || this.bulkToggleStartHour || this.bulkToggleEndHour) {
      //   this.toggleStartDate = this.toggleEndDate = this.toggleStartHour = this.toggleEndHour = this.bulkToggleStartDate = this.bulkToggleEndDate = this.bulkToggleStartHour = this.bulkToggleEndHour = false;
      //   next(false);
      //   return;
      // }

      // if (this.dialog) {
      //   this.dialog = false;
      //   next(false);
      //   return;
      // }

      if (this.navGuards()) {
        next(false);
        return;
      }

      next();
    });

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    });
  },
  async mounted() {
    this.eventstatus = config.EVENT_STATUS;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}

@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@-o-keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@keyframes loader {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
