import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import sharedMutations from 'vuex-shared-mutations';
import Cookies from 'js-cookie';

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [
    createPersistedState(
      {
        storage: {
          getItem: key => Cookies.get(key),
          setItem: (key, value) => Cookies.set(key, value, { secure: true }),
          removeItem: key => Cookies.remove(key)
        }
      }
    ),
    sharedMutations({ predicate: ['setToken', 'setTokenGgest', 'setUser', 'setConfiguerp', 'setConfigusms', 'setLogTime', 'setSnackBar','setTitle'] })
  ],
  state: {
    token: null,
    tokenggest: null,
    logTime: null,
    user: null,
    configerp: null,
    configsms: null,
    isUserLoggedIn: false,
    snackbar: {
      showing: false,
      color: 'success'
    },
    title: '',
  },
  mutations: {
    setToken(state, token) {
      state.token = token
      state.isUserLoggedIn = !!(token)
    },
    setUser(state, user) {
      state.user = user
    },
    setLogTime(state, logTime) {
      state.logTime = logTime
    },
    setSnackBar(state, snackbar) {
      state.snackbar = snackbar
    },
    setTitle(state, title) {
      state.title = title
    },
    setConfiguerp(state, configerp) {
      state.configerp = configerp
    },
    setConfigusms(state, configsms) {
      state.configsms = configsms
    },
    setTokenGgest(state, tokenggest) {
      state.tokenggest = tokenggest
    },
  },
  actions: {
    setToken({ commit }, token) {
      commit('setToken', token)
    },
    setUser({ commit }, user) {
      commit('setUser', user)
    },
    setConfiguerp({ commit }, configerp) {
      commit('setConfiguerp', configerp)
    },
    setConfigusms({ commit }, configsms) {
      commit('setConfigusms', configsms)
    },
    setLogTime({ commit }, logTime) {
      commit('setLogTime', logTime)
    },
    setTitle({ commit }, title) {
      commit('setTitle', title)
    },
    setTokenGgest({ commit }, tokenggest) {
      commit('setTokenGgest', tokenggest)
    },
    setSnackbar({
      commit
    }, snackbar) {
      if (typeof snackbar.showing == "undefined") {
        snackbar.showing = true;
      }
      if (snackbar.color === 'error') {
        snackbar.text = snackbar.text || 'Ocorreu um erro. Por favor tente novamente.';
      } else {
        snackbar.text = snackbar.text || 'Operação efectuada com sucesso.';
      }
      snackbar.color = snackbar.color || 'success';
      commit('setSnackBar', snackbar);
    }
  }
})
