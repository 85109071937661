import Api from '@/serviceGgest/ServiceBase'
const path = 'listarClientes'

export default {
  listarClientes (codigo, descricao, morada, contribuinte, telefone, telemovel, email) {
    return Api().get(path,{
      params: {
        codigo,
        descricao,
        morada,
        contribuinte,
        telefone,
        telemovel,
        email
      }
    })
  },
  criarCliente(clienteCriado) {
    return Api().post('criarCliente', {
      descricao: clienteCriado.nome,
      morada: clienteCriado.road,
      morada2: clienteCriado.address,
      codpostal: clienteCriado.codigopostal,
      pais: clienteCriado.pais,
      prefixo: clienteCriado.prefixo,
      contribuinte: clienteCriado.nif,
      telemovel1: clienteCriado.telemovel,
      email1: clienteCriado.email,
      obs: clienteCriado.obs,
      inactivo: clienteCriado.inactivo,
      espaco_fiscal: clienteCriado.espacofiscal,
      prazo_pagamento: clienteCriado.prazopagamento,
      expedicao: clienteCriado.expedicao,
      vendedor: clienteCriado.vendedor,
      moeda: clienteCriado.moeda,
      preco: clienteCriado.preco
    })
  },
  modificarCliente(clienteCriado) {
    return Api().put('modificarCliente' + `/${clienteCriado.codigo}`, {
      descricao: clienteCriado.nome,
      morada: clienteCriado.road,
      morada2: clienteCriado.address,
      codpostal: clienteCriado.codigopostal,
      pais: clienteCriado.pais,
      prefixo: clienteCriado.prefixo,
      contribuinte: clienteCriado.nif,
      telemovel1: clienteCriado.telemovel,
      email1: clienteCriado.email,
      obs: clienteCriado.obs,
      inactivo: clienteCriado.inactivo,
      espaco_fiscal: clienteCriado.espacofiscal,
      prazo_pagamento: clienteCriado.prazopagamento,
      expedicao: clienteCriado.expedicao,
      vendedor: clienteCriado.vendedor,
      moeda: clienteCriado.moeda,
      preco: clienteCriado.preco
    })
  },
}
