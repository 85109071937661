import Vue from "vue";
import Router from "vue-router";
import ListClient from "@/components/client/ListClient";
import ListUsers from "@/components/user/ListUsers";
import ListGroups from "@/components/group/ListGroups";
import ListRegulations from "@/components/regulation/ListRegulations";
import ListPlans from "@/components/plan/ListPlans";
import Crms from "@/components/crm/ListCrms";
import Waitinglists from "@/components/waitinglist/ListWaitinglists";
import EditPass from "@/components/user/EditPass";
import Dashboard from "@/components/dashboard/Dashboard";
import DashEmployeeBilling from "@/components/dashboard/EmployeeBilling";
import DashEvents from "@/components/dashboard/Events";
import DashClients from "@/components/dashboard/Clients"
import DashBilling from "@/components/dashboard/Billing"
import ArticlesInvoiced from "@/components/invoice/ListArticlesInvoiced";
import LogsInvoices from "@/components/invoice/ListLogsInvoices";
import LogsReceipts from "@/components/receipt/ListLogsReceipts";
import ArticlesNotInvoiced from "@/components/invoice/ListArticlesNotInvoiced";
import Home from "@/components/Home";
import CalendarPeopleOverview from "@/components/calendars/CalendarPeopleOverview";
import CalendarFull from "@/components/calendars/CalendarFull";
import ListRooms from "@/components/room/ListRooms";
import ListTypeevents from "@/components/typeevent/ListTypeevents";
import Login from "@/components/Login";
import Denied from "@/components/Denied";
import SettingsTemplates from "@/components/settings/SettingsTemplates";
import SettingsErp from "@/components/settings/SettingsErp";
import SettingsSms from "@/components/settings/SettingsSms";
import store from "@/store/store";

Vue.use(Router);

export let router = new Router({
  routes: [
    {
      path: "/home",
      name: "home",
      component: Home,
      meta: {
        auth: true,
        title: "O meu Calendário",
      },
    },
    {
      path: "/",
      name: "empty-path",
      component: Home,
      meta: {
        auth: true,
        title: "O meu Calendário",
      },
    },
    {
      path: "/clients",
      name: "clients",
      component: ListClient,
      meta: {
        auth: true,
        title: "client_type",
      },
    },
    {
      path: "/clients/:clientid",
      name: "clients-detail",
      component: ListClient,
      meta: {
        auth: true,
      },
    },
    {
      path: "/users",
      name: "users",
      component: ListUsers,
      meta: {
        auth: true,
        title: "Funcionários",
      },
    },
    {
      path: "/edit-pass",
      name: "edit-pass",
      component: EditPass,
      meta: {
        auth: true,
        title: "Editar Password",
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/denied",
      name: "denied",
      component: Denied,
    },
    {
      path: "/users/:userid",
      name: "users-detail",
      component: ListUsers,
      meta: {
        auth: true,
        title: "Funcionários",
      },
    },
    {
      path: "/CalendarPeopleOverview",
      name: "calendarPeopleOverview",
      component: CalendarPeopleOverview,
      meta: {
        auth: true,
        title: "Calendário Funcionários",
      },
    },
    {
      path: "/CalendarFull",
      name: "calendarFull",
      component: CalendarFull,
      meta: {
        auth: true,
        title: "Calendário Completo",
      },
    },
    {
      path: "/rooms",
      name: "rooms",
      component: ListRooms,
      meta: {
        auth: true,
        title: "Salas",
      },
    },
    {
      path: "/typeevents",
      name: "typeevents",
      component: ListTypeevents,
      meta: {
        auth: true,
        title: "Tipos de Serviço",
      },
    },
    {
      path: "/groups",
      name: "groups",
      component: ListGroups,
      meta: {
        auth: true,
        title: "Grupos",
      },
    },
    {
      path: "/regulations",
      name: "regulations",
      component: ListRegulations,
      meta: {
        auth: true,
        title: "Documentos/Emails",
      },
    },
    {
      path: "/dashboard",
      name: "dashboard",
      component: Dashboard,
      meta: {
        auth: true,
        title: "Dashboard",
      },
    },
    {
      path: "/dashboard/employee-billing",
      name: "dashemployeebilling",
      component: DashEmployeeBilling,
      meta: {
        auth: true,
        title: "Faturação de cada funcionários",
      },
    },
    {
      path: "/dashboard/events",
      name: "dashevents",
      component: DashEvents,
      meta: {
        auth: true,
        title: "Marcações",
      },
    },
    {
      path: "/dashboard/clients",
      name: "dashclients",
      component: DashClients,
      meta: {
        auth: true,
        title: "Clientes",
      },
    },
    {
      path: "/dashboard/billing",
      name: "dashbilling",
      component: DashBilling,
      meta: {
        auth: true,
        title: "Faturação",
      },
    },
    {
      path: "/settingssms",
      name: "settingssms",
      component: SettingsSms,
      meta: {
        auth: true,
        title: "Configurações SMS",
      },
    },
    {
      path: "/settingserp",
      name: "settingserp",
      component: SettingsErp,
      meta: {
        auth: true,
        title: "Configurações ERP",
      },
    },
    {
      path: "/settingstemplates",
      name: "settingstemplates",
      component: SettingsTemplates,
      meta: {
        auth: true,
        title: "Configurações",
      },
    },
    {
      path: "/articlesinvoiced",
      name: "articlesinvoiced",
      component: ArticlesInvoiced,
      meta: {
        auth: true,
        title: "Faturação",
      },
    },
    {
      path: "/logsinvoices",
      name: "logsinvoices",
      component: LogsInvoices,
      meta: {
        auth: true,
        title: "Logs faturação",
      },
    },
    {
      path: "/articlesnotinvoiced",
      name: "articlesnotinvoiced",
      component: ArticlesNotInvoiced,
      meta: {
        auth: true,
        title: "Artigos por faturar",
      },
    },
    {
      path: "/logsreceipts",
      name: "logsreceipts",
      component: LogsReceipts,
      meta: {
        auth: true,
        title: "Logs recibos",
      },
    },
    {
      path: "/plans",
      name: "plans",
      component: ListPlans,
      meta: {
        auth: true,
        title: "Planos",
      },
    },
    {
      path: "/crms",
      name: "crms",
      component: Crms,
      meta: {
        auth: true,
        title: "CRM - prospeção",
      },
    },
    {
      path: "/waitinglists",
      name: "waitinglists",
      component: Waitinglists,
      meta: {
        auth: true,
        title: "Lista de espera",
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !store.state.isUserLoggedIn) {
    next({
      path: "/login",
      query: {
        redirect: to.fullPath,
      },
    });
  } else {
    if (to.meta.auth && store.state.logTime == null) {
      next({
        path: "/login",
        query: {
          redirect: to.fullPath,
        },
      });
    } else {
      if (to.meta.auth && Date.now() - store.state.logTime >= 43200000) {
        next({
          path: "/login",
          query: {
            redirect: to.fullPath,
          },
        });
      } else {
        next();
      }
    }
  }
});
