<template>
  <v-dialog v-model="show" scrollable width="1500" transition="dialog-bottom-transition">
    <v-card class="basicBackGround">
      <v-toolbar dark color="light-blue">
        <v-toolbar-title>Planos</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" icon @click="toggleDialog()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Novo Registo</span>
        </v-tooltip>
      </v-toolbar>
      <v-card-text>
          <v-card class="elevation-0" color="transparent" v-if="typeeventplans.length != 0">
            <v-list color="transparent">
              <v-list-item-group>
                <v-list-item>
                  <v-list-item-content>
                    <v-data-table infinite-scroll-disabled="busy"
                      :headers="headers" :items="typeeventplans" :loading="loading" hide-default-footer
                      :items-per-page="typeeventplans.length + 10" class="mTable">
                      <template v-slot:[`item.price`]="{ item }">
                        {{ item.price }} €
                      </template>
                      <template v-slot:[`item.action`]="{ item }">
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn icon class="mx-0" @click="edittItem(item)">
                                  <v-icon v-on="on" color="teal">mdi-pencil</v-icon>
                                </v-btn>
                              </template>
                              <span>Editar</span>
                            </v-tooltip>

                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <v-btn icon class="mx-0" @click="deleteItem(item)">
                                  <v-icon v-on="on" color="pink">mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <span>Eliminar</span>
                            </v-tooltip>
                          </template>
                    </v-data-table>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-card v-else class="elevation-0" align="center" justify="center" color="transparent">
            <v-icon size="200" color="red">
              mdi-file
            </v-icon>
            <v-card-title class="justify-center">Não há planos a apresentar.</v-card-title>
          </v-card>
      </v-card-text>
    </v-card>

    <v-dialog v-model="createDialog" scrollable width="600" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Adicionar</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-container>

              <v-row align="center" justify="center">
                <v-col cols="12">
                  <v-autocomplete label="Plano" v-model="editItem.PlanId" :items="plans" item-text="name" item-value="id" :rules="[rules.required]"></v-autocomplete>
                </v-col>
              </v-row>

              <v-row align="center" justify="center">
                <v-col cols="12" md="6">
                  <v-text-field type="number" v-model="editItem.price" label="Preço (€)" :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field type="number" v-model="editItem.number_replacement_event" :rules="[rules.required]" :counter="250" label="Número de reposições"></v-text-field>
                </v-col>
              </v-row>

            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="red" outlined @click="clear">Limpar</v-btn>
          <v-spacer></v-spacer>
          <v-btn text color="primary" outlined @click="submit">Submeter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="editDialog" scrollable width="600" transition="dialog-bottom-transition">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Editar</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-form ref="form2" v-model="valid2" lazy-validation>
            <v-container>
              <v-row align="center" justify="center">
                <v-col cols="12" md="6">
                  <v-text-field type="number" v-model="editItem.price" label="Preço (€)" :rules="[rules.required]"></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field type="number" v-model="editItem.number_replacement_event" :rules="[rules.required]" :counter="250" label="Número de reposições"></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="primary" outlined @click="submitEditItem">Submeter</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
import ServicePlans from "@/services/ServicePlans";
import ServicePlantypeevents from "@/services/ServicePlantypeevents";
export default {
  name: "PlanTypeevents",
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_URL,
      show: false,
      loading: false,
      typeevent: {},
      typeeventplans: [],
      plans: [],
      createDialog: false,
      editDialog: false,
      valid: true,
      valid2: true,
      editItem: {},
      headers: [
        { text: "Plano", sortable: true, value: "Plan.name" },
        { text: "Preço (€)", sortable: true, value: "price" },
        { text: "Número de reposições", sortable: true, value: "number_replacement_event" },
        { text: "Ações", value: "action", sortable: true, align: "center" }
      ],
      rules: {
        required: value => !!value || "Campo Obrigatório.",
        counter: value =>
          (value && value.length <= 250) ||
          "Campo tem de ter menos de 250 caracteres",
        isValidNumber: value =>
          /^([0-9]+[.])?[0-9]+$/.test(value) || "Valor inválido"
      }
    };
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    close() {
      this.show = false;
    },
    async open(typeevent) {
      this.loading = true;
      this.typeevent = typeevent;
      this.typeeventplans = (await ServicePlantypeevents.getTypeeventPlans(null,this.typeevent.id)).data;
      this.show = true;
      this.loading = false;
    },
    async toggleDialog () {
      this.clear()
      this.createDialog = true
      this.plans = (await ServicePlans.list()).data;
    },
    async submit() {
      try {
        if (this.$refs.form.validate()) {

          this.editItem.TypeeventId = this.typeevent.id;

          await ServicePlantypeevents.createTypeeventPlans(this.editItem);

          this.typeeventplans = (await ServicePlantypeevents.getTypeeventPlans(null,this.typeevent.id)).data;

          this.$store.dispatch("setSnackbar", {});
          this.createDialog = false
          this.clear();
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    clear () {
      this.editItem.PlanId = null;
      this.editItem.price = null;
      this.editItem.number_replacement_event = null;
    },
    async deleteItem(item) {
      if (confirm("Confirma a eliminação deste elemento?")) {
        try {
          await ServicePlantypeevents.deleteTypeeventPlans(item.id);
          this.$store.dispatch("setSnackbar", {});
          this.typeeventplans = (await ServicePlantypeevents.getTypeeventPlans(null,this.typeevent.id)).data;
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    async edittItem (item) {
      this.editItem = item
      this.editItem.price = item.price
      this.editDialog = true
    },
    async submitEditItem () {
      try {
        if (this.$refs.form2.validate()) {
          await ServicePlantypeevents.updateTypeeventPlans(this.editItem);
          this.typeeventplans = (await ServicePlantypeevents.getTypeeventPlans(null,this.typeevent.id)).data;
          this.$store.dispatch("setSnackbar", {});
          this.editDialog = false
        }
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
  },
  computed: {
  },
  async mounted() {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
