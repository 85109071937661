<template>
  <v-dialog v-model="show" scrollable width="1500" transition="dialog-bottom-transition">
    <v-card class="basicBackGround">
      <v-toolbar dark color="light-blue">
        <v-toolbar-title>Marcações : {{ client.name }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn v-if="client.discontinued != true" icon @click="$refs.eventDialog.open(null, client.id, client.name)">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <div v-if="loading" align="center" justify="center">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </div>
        <v-col v-else cols="12">
          <v-data-table v-if="appointments.length != 0" :headers="headers" :items="appointments" hide-default-footer
            class="mTable" :items-per-page="appointments.length + 10" ref="mTimeLine" :item-class="getRowClass">

            <template v-slot:[`item.userName`]="{ item }">
              <v-avatar size="32px" tile>
                <img :src="baseUrl + '/' + item.img" alt="Infordio" />
              </v-avatar>
              {{ item.userName }}
            </template>

            <template v-slot:[`item.start`]="{ item }">
              <tr v-if="item === nextAppointment" ref="nextAppointmentRow"></tr>
              {{ formatDate(item.start) }}
            </template>

            <template v-slot:[`item.end`]="{ item }">
              {{ formatDate(item.end) }}
            </template>

            <template v-slot:[`item.room`]="{ item }">
              {{ item.roomIdentification + ' - ' +  item.roomName}}
            </template>

            <template v-slot:[`item.status`]="{ item }">
                <div v-for="statusevent in item.ClientEvents" :key="statusevent.status">
                  <v-icon :color="getStatus(statusevent.status).color" small class="pr-2">mdi-circle</v-icon>
                  {{ getStatus(statusevent.status).text }}
                </div>
            </template>

            <template v-slot:[`item.action`]="{ item }">


                <v-tooltip top>
                  <template v-slot:activator="{ on }">
                    <v-btn icon class="mx-0">
                      <v-icon v-on="on" color="blue" @click="$refs.eventDialog.open(item, client.id, client.name)">mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Ver marcação</span>
                </v-tooltip>

            </template>
          </v-data-table>
          <v-card v-else class="elevation-0" align="center" justify="center" color="transparent">
            <v-icon size="200" color="red">
              mdi-file
            </v-icon>
            <v-card-title class="justify-center">Não há registos a apresentar.</v-card-title>
          </v-card>
        </v-col>
      </v-card-text>
      <event-dialog ref="eventDialog" :rooms="rooms" :users="users" :typeevents="typeevents" :groups="groups" @handleEvent="handleEvent"
      @handleDelete="handleDelete" @handleStatus="handleStatus"></event-dialog>
    </v-card>

    </v-dialog>
</template>

<script>
import ServiceCalendars from "@/services/ServiceCalendars";
import EventDialog from "@/components/common/EventDialog.vue";
export default {
  name: "ClientAppointments",
  props: ["users", "rooms", "typeevents", "groups"],
  components: {
    EventDialog
  },
  data() {
    return {
      baseUrl: process.env.VUE_APP_API_URL,
      show: false,
      loading: false,
      client: {},
      appointments: [],
      nextAppointment: null,
      status: [],
      headers: [
        { text: 'Data Início', value: 'start', sortable: true },
        { text: 'Data Fim', value: 'end', sortable: true },
        { text: 'Funcionário', value: 'userName', sortable: true },
        { text: 'Status', value: 'status', sortable: true },
        { text: 'Tipo de serviço', value: 'typeeventName', sortable: true },
        { text: 'Sala', value: 'room', sortable: true },
        { text: 'Ações', value: 'action', sortable: true },
      ],
    };
  },
  methods: {
    formatDate(date) {
      const d = new Date(date);

      const day = String(d.getDate()).padStart(2, '0');
      const month = String(d.getMonth() + 1).padStart(2, '0');
      const year = d.getFullYear();
      const hours = String(d.getHours()).padStart(2, '0');
      const minutes = String(d.getMinutes()).padStart(2, '0');

      return `${day}-${month}-${year} ${hours}:${minutes}`;
    },
    getStatus(statusValue) {
      const foundStatus = this.status.find(statu => statu.value === statusValue);
      return foundStatus || null;
    },
    async handleDelete() {
      if (this.$store.state.user.see_everyones_appointments_module === true) {
        this.appointments = (await ServiceCalendars.getCalendarByClient(this.client.id, null)).data;
      } else {
        this.appointments = (await ServiceCalendars.getCalendarByClient(this.client.id, this.$store.state.user.id)).data;
      }
      this.findNextAppointment();
    },
    async handleEvent() {
      if (this.$store.state.user.see_everyones_appointments_module === true) {
        this.appointments = (await ServiceCalendars.getCalendarByClient(this.client.id, null)).data;
      } else {
        this.appointments = (await ServiceCalendars.getCalendarByClient(this.client.id, this.$store.state.user.id)).data;
      }
      this.findNextAppointment();
    },
    async handleStatus() {
      if (this.$store.state.user.see_everyones_appointments_module === true) {
        this.appointments = (await ServiceCalendars.getCalendarByClient(this.client.id, null)).data;
      } else {
        this.appointments = (await ServiceCalendars.getCalendarByClient(this.client.id, this.$store.state.user.id)).data;
      }
      this.findNextAppointment();
    },
    findNextAppointment() {
      const now = new Date();
      const futureAppointments = this.appointments.filter(appointment => new Date(appointment.start) > now);
      futureAppointments.sort((a, b) => new Date(a.start) - new Date(b.start));
      if (futureAppointments.length > 0) {
        this.nextAppointment = futureAppointments[0];
      }
    },
    getRowClass(item) {
      return this.nextAppointment && item === this.nextAppointment ? 'next-appointment' : '';
    },
    scrollToNextAppointment() {
      this.$nextTick(() => {
        if (this.$refs.nextAppointmentRow) {
          this.$refs.nextAppointmentRow.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      });
    },
    close() {
      this.show = false;
    },
    async open(client) {
      this.client = client;
      this.appointments = [];
      this.show = true;

      try {
        this.loading = true;

        if (this.$store.state.user.see_everyones_appointments_module === true) {
          this.appointments = (await ServiceCalendars.getCalendarByClient(this.client.id, null)).data;
        } else {
          this.appointments = (await ServiceCalendars.getCalendarByClient(this.client.id, this.$store.state.user.id)).data;
        }

        this.findNextAppointment();

      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
      this.loading = false;
    },
  },
  computed: {
  },
  async mounted() {
    this.status = config.EVENT_STATUS;
  },
  updated() {
    this.scrollToNextAppointment();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
