<template>
  <v-container v-if="$store.state.user.settings_edit === true">
    <v-row align="center" justify="center">
      <v-col cols="12" md="5">
        <h3>{{templateSmsEvent.description}}</h3>
        <br>
        <v-textarea v-model="templateSmsEvent.text" outlined label="Texto"
        hint="tipo de serviço = {typeeventName} / data = {date} / funcionário = {userName} / sala = {roomName}" counter maxlength="250" persistent-hint
          ></v-textarea>
        <v-btn color="primary" @click="updateSettingText(templateSmsEvent)">Guardar</v-btn>
      </v-col>
      <v-col cols="12" md="5">
        <h3>{{templateSmsBirthday.description}}</h3>
        <br>
        <v-textarea v-model="templateSmsBirthday.text" outlined label="Texto"
          hint="cliente = {clientName}" counter maxlength="250" persistent-hint
          ></v-textarea>
        <v-btn color="primary" @click="updateSettingText(templateSmsBirthday)">Guardar</v-btn>
      </v-col>
      <v-col cols="12" md="5">
        <h3>{{templateFaturaEmail.description}}</h3>
        <br>
        <v-text-field v-model="templateFaturaEmail.subject" outlined label="Assunto"></v-text-field>
        <v-textarea v-model="templateFaturaEmail.text" outlined label="Texto"
          hint="cliente = {clientName} / Nome documento = {documentName}" counter maxlength="500" persistent-hint
          ></v-textarea>
        <v-btn color="primary" @click="updateSettingText(templateFaturaEmail)">Guardar</v-btn>
      </v-col>
      <v-col cols="12" md="5">
        <h3>{{templateReciboEmail.description}}</h3>
        <br>
        <v-text-field v-model="templateReciboEmail.subject" outlined label="Assunto"></v-text-field>
        <v-textarea v-model="templateReciboEmail.text" outlined label="Texto"
          hint="cliente = {clientName} / Nome documento = {documentName}" counter maxlength="500" persistent-hint
          ></v-textarea>
        <v-btn color="primary" @click="updateSettingText(templateReciboEmail)">Guardar</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ServiceSettingsTemplates from "@/services/ServiceSettingsTemplates";
export default {
  name: "Settings-Edit",
  data: () => ({
    valid: true,
    loading: false,
    templateFaturaEmail: {},
    templateReciboEmail: {},
    templateSmsEvent: {},
    templateSmsBirthday: {}
  }),
  methods: {
    async updateSettingText(setting) {
      try {
        this.loading = true;

        await ServiceSettingsTemplates.update({name: setting.name, subject: setting.subject, text: setting.text})

        this.$store.dispatch("setSnackbar", {});

        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
  },
  async mounted() {
    try {
      /*Se o funcionário não tiver permissões de entrar nos settings edit */
      if (this.$store.state.user.settings_edit != true) {
        this.$router.push(this.$route.query.redirect || "home");
      }

      this.loading = true;

      //SMS
      this.templateSmsEvent = (await ServiceSettingsTemplates.show('templateSmsEvent')).data;
      this.templateSmsBirthday = (await ServiceSettingsTemplates.show('templateSmsBirthday')).data;

      //email de faturas e recibos
      this.templateFaturaEmail = (await ServiceSettingsTemplates.show('templateFaturaEmail')).data;
      this.templateReciboEmail = (await ServiceSettingsTemplates.show('templateReciboEmail')).data;

      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.$store.dispatch("setSnackbar", {
        color: "error",
        text: error
      });
    }
  }
};
</script>
<style></style>
