<template>
  <v-container v-if="$store.state.user.dashboard_module === true">
    <v-row>
      <v-col cols="12">
          <h3>Dados fixos</h3>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <h4 class="text-center" style="padding: 5px;">{{ clientTypeP }}</h4>
          <pie-chart class="graficosTamanho" style="padding: 10px;" :colors="['#1565C0', '#0d2149']" :data="[['Ativos', clientes_ativos], ['Inativos', clientes_inativos]]"></pie-chart>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <h4 class="text-center" style="padding: 5px;">{{ clientTypeP }} (Onde nos conheceram)</h4>
          <column-chart class="graficosTamanho" style="padding: 10px;" :data="chartOndeNosConheceu" :colors="['#1565C0']"></column-chart>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <h4 class="text-center" style="padding: 5px;">{{ clientTypeP }} (género)</h4>
          <column-chart class="graficosTamanho" style="padding: 10px;" :data="chartGenero" :colors="['#1565C0']"></column-chart>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-container>
          <v-row>
            <v-col cols="12" md="2">
              <v-dialog ref="toggleDataInicio" v-model="toggleDataInicio" :return-value.sync="data_inicio_select"
                persistent width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field clearable v-model="data_inicio_select" label="Data Inicio (desde)"
                    prepend-icon="mdi-clock-outline" v-on="on"></v-text-field>
                </template>
                <v-date-picker :max="data_fim_select" locale="pt-pt" v-model="data_inicio_select" scrollable>
                  <v-btn text color="primary" @click="toggleDataInicio = false">Cancelar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="updateDashboardDateInicio(data_inicio_select)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="12" md="2">
              <v-dialog ref="toggleDataFim" v-model="toggleDataFim" :return-value.sync="data_fim_select"
                persistent width="290px">
                <template v-slot:activator="{ on }">
                  <v-text-field clearable v-model="data_fim_select" label="Data Fim (até)"
                    prepend-icon="mdi-clock-outline" v-on="on"></v-text-field>
                </template>
                <v-date-picker :min="data_inicio_select" locale="pt-pt" v-model="data_fim_select" scrollable>
                  <v-btn text color="primary" @click="toggleDataFim = false">Cancelar</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="updateDashboardDateFim(data_fim_select)">OK</v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
          <h3>Pesquisa</h3>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <h4 class="text-center" style="padding: 5px;">{{ clientTypeP }}</h4>
          <pie-chart class="graficosTamanho" style="padding: 10px;" :colors="['#1565C0', '#0d2149']" :data="[['Inscritos', clientes_inscitos_pesquisa.numero], ['Desistências', clientes_desistencias_pesquisa.numero]]"></pie-chart>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <h4 class="text-center" style="padding: 5px;">{{ clientTypeP }} inscritos (Onde nos conheceram)</h4>
          <column-chart class="graficosTamanho" style="padding: 10px;" :data="chartOndeNosConheceuPesquisa" :colors="['#1565C0']"></column-chart>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card>
          <h4 class="text-center" style="padding: 5px;">{{ clientTypeP }} inscritos (género)</h4>
          <column-chart class="graficosTamanho" style="padding: 10px;" :data="chartGeneroPesquisa" :colors="['#1565C0']"></column-chart>
        </v-card>
      </v-col>
      <v-col cols="6">
          <h3>Clientes que se inscreveram</h3>
          <v-data-table :headers="headersClientesPesquisa" :items="clientes_inscitos_pesquisa.clientesinscritos" hide-default-footer class="mTable full-width" :items-per-page="clientes_inscitos_pesquisa.clientesinscritos.length + 10">
            <template v-slot:[`item.find`]="{ item }">
              {{clientsFind(item.Client.find)}}
            </template>
          </v-data-table>
      </v-col>
      <v-col cols="6">
          <h3>Clientes que desistiram</h3>
          <v-data-table :headers="headersClientesPesquisa" :items="clientes_desistencias_pesquisa.clientesdesistencias" hide-default-footer class="mTable full-width" :items-per-page="clientes_inscitos_pesquisa.clientesinscritos.length + 10">
            <template v-slot:[`item.find`]="{ item }">
              {{clientsFind(item.Client.find)}}
            </template>
          </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ServiceDashboard from '@/services/ServiceDashboard'
import _ from "lodash";
export default {
  name: "Dashboard",
  data() {
    return {
      toggleDataInicio: false,
      toggleDataFim: false,
      clientTypeP: '',
      finds: [],
      loadingClients: false,
      currentYear: new Date().getFullYear(),

      // pesquisas
      data_inicio_select: new Date().toISOString().substr(0, 10),
      data_fim_select: new Date().toISOString().substr(0, 10),

      // dados clientes
      clientes_ativos: 0,
      clientes_inativos: 0,
      clientes_ondenosconheceu: [],
      clientes_genero: [],

      // dados com pesquisa
      clientes_inscitos_pesquisa: null,
      clientes_desistencias_pesquisa: null,
      clientes_ondenosconheceu_pesquisa: [],
      clientes_genero_pesquisa: [],

      headersClientesPesquisa: [
        { text: 'Nome', value: 'Client.name', sortable: true },
        { text: 'Onde nos conheceu', value: 'find', sortable: true }
      ],
    }
  },
  async mounted() {
    /*Se o funcionário não tiver permissões de entrar nas dashboards */
    if (this.$store.state.user.dashboard_module != true) {
      this.$router.push(this.$route.query.redirect || "home");
    }
    this.clientTypeP = config.CLIENT_TYPE_PLURAL;
    this.finds = config.CLIENT_FIND;

    // dados clientes
    this.clientes_ativos = (await ServiceDashboard.listclients(0)).data
    this.clientes_inativos = (await ServiceDashboard.listclients(1)).data
    this.clientes_ondenosconheceu = (await ServiceDashboard.listclientsondenosconheceu()).data
    this.clientes_genero = (await ServiceDashboard.listclientsgenero()).data

    // dados com pesquisa
    this.clientes_inscitos_pesquisa = (await ServiceDashboard.listclientsinscritospesquisa(this.data_inicio_select, this.data_fim_select)).data
    this.clientes_desistencias_pesquisa = (await ServiceDashboard.listclientsdesistenciaspesquisa(this.data_inicio_select, this.data_fim_select)).data
    this.clientes_ondenosconheceu_pesquisa = (await ServiceDashboard.listclientsondenosconheceupesquisa(this.data_inicio_select, this.data_fim_select)).data
    this.clientes_genero_pesquisa = (await ServiceDashboard.listclientsgeneropesquisa(this.data_inicio_select, this.data_fim_select)).data
  },
  computed: {
    chartOndeNosConheceu() {
      let data = [];
      this.finds.forEach(find => {
        this.clientes_ondenosconheceu.forEach(ondenosconheceu => {
          if (find.value === ondenosconheceu.text) {
            data.push([find.text, ondenosconheceu.cont]);
          }
        });
      });
      return data;
    },
    chartOndeNosConheceuPesquisa() {
      let data = [];
      this.finds.forEach(find => {
        this.clientes_ondenosconheceu_pesquisa.forEach(ondenosconheceupesquisa => {
          if (find.value === ondenosconheceupesquisa.text) {
            data.push([find.text, ondenosconheceupesquisa.cont]);
          }
        });
      });
      return data;
    },
    chartGenero() {
      let data = [];
      this.clientes_genero.forEach(genero => {
        data.push([genero.text, genero.cont]);
      });
      return data;
    },
    chartGeneroPesquisa() {
      let data = [];
      this.clientes_genero_pesquisa.forEach(generopesquisa => {
        data.push([generopesquisa.text, generopesquisa.cont]);
      });
      return data;
    },
  },
  methods: {
    async updateDashboardDateInicio(date) {
      this.$refs.toggleDataInicio.save(date);
      this.clientes_inscitos_pesquisa = (await ServiceDashboard.listclientsinscritospesquisa(this.data_inicio_select, this.data_fim_select)).data
      this.clientes_desistencias_pesquisa = (await ServiceDashboard.listclientsdesistenciaspesquisa(this.data_inicio_select, this.data_fim_select)).data
      this.clientes_ondenosconheceu_pesquisa = (await ServiceDashboard.listclientsondenosconheceupesquisa(this.data_inicio_select, this.data_fim_select)).data
      this.clientes_genero_pesquisa = (await ServiceDashboard.listclientsgeneropesquisa(this.data_inicio_select, this.data_fim_select)).data
    },
    async updateDashboardDateFim(date) {
      this.$refs.toggleDataFim.save(date);
      this.clientes_inscitos_pesquisa = (await ServiceDashboard.listclientsinscritospesquisa(this.data_inicio_select, this.data_fim_select)).data
      this.clientes_desistencias_pesquisa = (await ServiceDashboard.listclientsdesistenciaspesquisa(this.data_inicio_select, this.data_fim_select)).data
      this.clientes_ondenosconheceu_pesquisa = (await ServiceDashboard.listclientsondenosconheceupesquisa(this.data_inicio_select, this.data_fim_select)).data
      this.clientes_genero_pesquisa = (await ServiceDashboard.listclientsgeneropesquisa(this.data_inicio_select, this.data_fim_select)).data
    },
    clientsFind(value) {
      for (let find of this.finds) {
        if (find.value === value) {
          return find.text;
        }
      }
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.graficosTamanho{
  height: 160px !important;
  line-height: 160px !important;
}
</style>
