var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"scrollable":"","width":"1500","transition":"dialog-bottom-transition"},on:{"input":_vm.onDialogClose},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"basicBackGround"},[_c('v-toolbar',{attrs:{"dark":"","color":"light-blue"}},[_c('v-toolbar-title',[_vm._v("Artigos por faturar: "+_vm._s(_vm.client.name))]),_c('v-spacer')],1),_c('v-card-text',[(_vm.articlesnotinvoiced.length != 0)?_c('v-card',{staticClass:"elevation-0",attrs:{"color":"transparent"}},[_c('v-list',{attrs:{"color":"transparent"}},[_c('v-list-item-group',[_c('v-list-item',[_c('v-list-item-content',[_c('v-data-table',{staticClass:"mTable",attrs:{"infinite-scroll-disabled":"busy","headers":_vm.headers,"items":_vm.articlesnotinvoiced,"loading":_vm.loading,"hide-default-footer":"","items-per-page":_vm.articlesnotinvoiced.length + 10,"show-select":"","item-value":"id"},scopedSlots:_vm._u([{key:"item.Date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.Date))+" ")]}},{key:"item.Price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.Price)+" € ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.CovenantCycle != null)?_c('td',{staticClass:"justify-center layout px-0"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',{staticClass:"mx-0",attrs:{"icon":""}},[_c('v-icon',_vm._g({attrs:{"color":"teal"},on:{"click":function($event){return _vm.openCovenantcyclesItem(item)}}},on),[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar ciclos da avença")])])],1):_vm._e()]}}],null,true),model:{value:(_vm.selectedArticles),callback:function ($$v) {_vm.selectedArticles=$$v},expression:"selectedArticles"}})],1)],1),_c('v-list-item',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary","outlined":""},on:{"click":function($event){return _vm.openInvoices()}}},[_vm._v("Criar fatura")])],1)],1)],1)],1):_c('v-card',{staticClass:"elevation-0",attrs:{"align":"center","justify":"center","color":"transparent"}},[_c('v-icon',{attrs:{"size":"200","color":"red"}},[_vm._v(" mdi-file ")]),_c('v-card-title',{staticClass:"justify-center"},[_vm._v("Não há artigos por faturar a apresentar.")])],1)],1)],1),_c('invoices',{ref:"invoices",on:{"handleInvoice":_vm.handleInvoice}}),_c('covenantscycles',{ref:"covenantscycles",on:{"handleEditCovenantCycle":_vm.handleEditCovenantCycle}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }