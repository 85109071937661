<template>
  <v-container v-if="$store.state.user.articles_not_invoiced_module === true">
    <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false" class="d-md-none">
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark large fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark large color="green" @click.stop="filterDialog = true">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-speed-dial>
    <invoices @handleInvoice="handleInvoice" ref="invoices" />
    <covenantscycles @handleEditCovenantCycle="handleEditCovenantCycle" ref="covenantscycles" />
    <v-row>
      <v-col cols="1" class="d-none d-md-block"></v-col>
      <v-col cols="4" class="d-none d-md-block">
        <v-text-field prepend-icon="mdi-account-tie" label="Cliente" clearable v-model="client_select"></v-text-field>
      </v-col>
      <v-col cols="3" class="d-none d-md-block">
        <v-dialog ref="toggleDataInicio" v-model="toggleDataInicio" :return-value.sync="data_inicio_select"
          persistent width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field clearable v-model="data_inicio_select" label="Data Inicio (desde)"
              prepend-icon="mdi-clock-outline" v-on="on"></v-text-field>
          </template>
          <v-date-picker :max="data_fim_select" locale="pt-pt" v-model="data_inicio_select" scrollable>
            <v-btn text color="primary" @click="toggleDataInicio = false">Cancelar</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="updateDashboardDateInicio(data_inicio_select)">OK</v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="3" class="d-none d-md-block">
        <v-dialog ref="toggleDataFim" v-model="toggleDataFim" :return-value.sync="data_fim_select"
          persistent width="290px">
          <template v-slot:activator="{ on }">
            <v-text-field clearable v-model="data_fim_select" label="Data Fim (até)"
              prepend-icon="mdi-clock-outline" v-on="on"></v-text-field>
          </template>
          <v-date-picker :min="data_inicio_select" locale="pt-pt" v-model="data_fim_select" scrollable>
            <v-btn text color="primary" @click="toggleDataFim = false">Cancelar</v-btn>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="updateDashboardDateFim(data_fim_select)">OK</v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col cols="1" class="d-none d-md-block"></v-col>
    </v-row>
    <v-row>
      <v-col cols="1" class="d-none d-md-block"></v-col>
      <v-col cols="3" class="d-none d-md-block">
        <v-select prepend-icon="mdi-floor-plan" @change="Filter()" v-model="plans_select" :items="plans" multiple clearable label="Planos" item-text="name" item-value="id"></v-select>
      </v-col>
      <v-col cols="3" class="d-none d-md-block">
        <v-autocomplete prepend-icon="mdi-clipboard-list" @change="Filter()" v-model="typeevent_select" clearable :items="typeevents" label="Tipo de serviço" item-text="name" item-value="id"></v-autocomplete>
      </v-col>
      <v-col cols="2" class="d-none d-md-block"></v-col>
      <v-col cols="12" md="2" class="text-right">
        <v-btn text color="primary" outlined @click="openInvoices()">Criar fatura</v-btn>
      </v-col>
    </v-row>
    <v-row align="center" justify="center">
      <v-col cols="12" md="10">
        <v-data-table infinite-scroll-disabled="busy" v-infinite-scroll="getUsers"
          :headers="headers" :items="users" :loading="loading" hide-default-footer
          :items-per-page="users.length * 100" class="mTable" show-select item-value="id" v-model="selectedArticles">

          <template v-slot:[`item.Date`]="{ item }">
            {{ formatDate(item.Date) }}
          </template>

          <template v-slot:[`item.Price`]="{ item }">
            {{ item.Price }} €
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <td class="justify-center layout px-0" v-if="item.CovenantCycle != null">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0">
                    <v-icon v-if="item.CovenantCycle != null && $store.state.user.cycles_covenants_crud_module === true" v-on="on" color="teal" @click="openCovenantcyclesItem(item)">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar avença</span>
              </v-tooltip>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="filterDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Filtros :</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="filterDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-dialog ref="toggleDataInicio" v-model="toggleDataInicio" :return-value.sync="data_inicio_select"
                  persistent width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field clearable v-model="data_inicio_select" label="Data Inicio (desde)"
                      prepend-icon="mdi-clock-outline" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker :max="data_fim_select" locale="pt-pt" v-model="data_inicio_select" scrollable>
                    <v-btn text color="primary" @click="toggleDataInicio = false">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="updateDashboardDateInicio(data_inicio_select)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-dialog ref="toggleDataFim" v-model="toggleDataFim" :return-value.sync="data_fim_select"
                  persistent width="290px">
                  <template v-slot:activator="{ on }">
                    <v-text-field clearable v-model="data_fim_select" label="Data Fim (até)"
                      prepend-icon="mdi-clock-outline" v-on="on"></v-text-field>
                  </template>
                  <v-date-picker :min="data_inicio_select" locale="pt-pt" v-model="data_fim_select" scrollable>
                    <v-btn text color="primary" @click="toggleDataFim = false">Cancelar</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="updateDashboardDateFim(data_fim_select)">OK</v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete ref="cbClient" :label="clientTypeP" prepend-icon="mdi-account-tie"
                  :loading="loadingClients" v-debounce="newVal => myFn(newVal, 'v-text-field')" v-model="client_select"
                  :items="clients" item-text="name" item-value="name"
                  clearable :return-object="false"
                  hint="Nome, NIF ou Tlf." persistent-hint> <template v-slot:progress>
                    <span class="custom-loader">
                      <v-icon light>mdi-cached</v-icon>
                    </span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-select prepend-icon="mdi-floor-plan" v-model="plans_select" :items="plans" multiple clearable label="Planos" item-text="name" item-value="id"></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-autocomplete prepend-icon="mdi-clipboard-list" v-model="typeevent_select" clearable :items="typeevents" label="Tipo de serviço" item-text="name" item-value="id"></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="blue darken-1" text @click="Filter()">Procurar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";
import ServiceInvoices from "@/services/ServiceInvoices";
import ServiceClients from '@/services/ServiceClients'
import ServicePlans from '@/services/ServicePlans';
import ServiceTypeevents from '@/services/ServiceTypeevents';
import Invoices from "@/components/invoice/Invoices.vue";
import Covenantscycles from "@/components/client/CovenantsCycles.vue";
import _ from "lodash";
export default {
  name: "List-Articles-Invoiced",
  directives: {
    infiniteScroll
  },
  components: {
    Invoices,
    Covenantscycles
  },
  data() {
    return {
      clientTypeP: '',
      toggleDataInicio: false,
      toggleDataFim: false,
      loadingClients: false,
      filterDialog: false,

      // pesquisas
      client_select: null,
      data_inicio_select: null,
      data_fim_select: new Date().toISOString().substr(0, 10),
      typeevent_select: null,
      plans_select: [],

      fab: false,
      page: 1,
      headers: [
        { text: "Cliente", value: "ClientName", sortable: true },
        { text: "Data", sortable: true, value: "Date" },
        { text: "Descrição", sortable: true, value: "Cycle" },
        { text: "Tipo de serviço", sortable: true, value: "TypeeventName" },
        { text: "Plano", sortable: true, value: "PlanName" },
        { text: "Qnt", sortable: true, value: "Quantity" },
        { text: "Preço (€)", sortable: true, value: "Price" },
        { text: "Ações", value: "action", sortable: true, align: "center" }
      ],
      users: [],
      busy: false,
      loading: true,
      plans: [],
      clients: [],
      typeevents: [],
      selectedArticles: [],
      selectAll: false,
    };
  },
  watch: {
    selectedArticles(val) {
      this.selectAll = val.length === this.users.length;
    },
    client_select(newName) {
      clearTimeout(this.debounceTimeout);

      if (newName.trim() === "") {
        this.Filter();
      } else {
        this.debounceTimeout = setTimeout(() => {
          this.page = 1;
          this.users = [];
          this.getUsers();
        }, 500);
      }
    },
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    async getUsers() {
      this.busy = true;
      this.loading = true;
      const response = await ServiceInvoices.listarticlesnotinvoiced(
        this.page,
        null,
        this.data_inicio_select,
        this.data_fim_select,
        this.plans_select,
        this.typeevent_select,
        this.client_select
      );

      this.users = this.users.concat(response.data);

      this.page++;
      this.busy = false;
      if (response.data.length == 0) {
        this.busy = true;
      }
      this.loading = false;
    },
    openInvoices() {
      if (this.selectedArticles != '') {
        this.$refs.invoices.open(null, this.selectedArticles, 2);
      }
    },
    openCovenantcyclesItem(item) {
      this.$refs.covenantscycles.open({id: item.CovenantCycle.Covenant.id, ClientId: item.ClientId});
    },
    async handleEditCovenantCycle() {
      this.Filter()
    },
    async handleInvoice() {
      //reload porque não está a atualizar
      this.Filter()
      this.selectedArticles = []
      //window.location.reload();
    },
    async updateDashboardDateInicio(date) {
      this.$refs.toggleDataInicio.save(date);
      this.data_inicio_select = date
      this.Filter()
    },
    async updateDashboardDateFim(date) {
      this.$refs.toggleDataFim.save(date);
      this.data_fim_select = date
      this.Filter()
    },
    async myFn(newVal) {
      if (newVal) {
        this.loadingClients = true;
        this.clients = (await ServiceClients.listDebounce(newVal)).data;
        this.$refs.cbClient.$el.focus();
        this.loadingClients = false;
      }
    },
    Filter() {
      this.users = [];
      this.page = 1;
      this.filterDialog = false;
      this.getUsers();
    },
  },
  async mounted() {
    /*Se o funcionário não tiver permissões de entrar nos artigos por faturar */
    if (this.$store.state.user.articles_not_invoiced_module != true) {
      this.$router.push(this.$route.query.redirect || "home");
    }
    this.plans = (await ServicePlans.list(null)).data
    this.typeevents = (await ServiceTypeevents.listartodos(null)).data;
    this.clientTypeP = config.CLIENT_TYPE_PLURAL;
  },

  created() {

    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {

      if (this.$refs.invoices) {
        if (this.$refs.invoices.editDialog) {
          this.$refs.invoices.editDialog = false;
          next(false);
          return;
        }

        if (this.$refs.invoices.show) {
          this.$refs.invoices.show = false;
          next(false);
          return;
        }
      }

      if (this.$refs.covenantscycles) {
        if (this.$refs.covenantscycles.editDialog) {
          this.$refs.covenantscycles.editDialog = false;
          next(false);
          return;
        }

        if (this.$refs.covenantscycles.show) {
          this.$refs.covenantscycles.show = false;
          next(false);
          return;
        }
      }

      if (this.filterDialog) {
        this.filterDialog = false;
        next(false);
        return;
      }

      next();
    });

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
/*bush fix the icon was pushed to the top*/
.btn--floating .icon {
  height: 50%;
}
</style>
