<template>
  <v-container v-if="$store.state.user.typeevents_module === true">
    <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark large fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark large color="green" @click.stop="filterDialog = true">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn fab dark large color="indigo" @click="addEditItem()" v-if="$store.state.user.typeevents_crud_module === true">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <!--
      <v-btn fab dark large color="red" @click="importarArtigosErp" v-if="$store.state.user.import_artigos_erp_module === true && $store.state.configerp.configuracoeserpestado == true">
        <v-icon>mdi-database-import</v-icon>
      </v-btn>
      -->

    </v-speed-dial>

    <v-row align="center" justify="center">
      <v-col cols="12" md="10">
        <v-data-table class="mTable" id="mytable" v-infinite-scroll="getUsers" infinite-scroll-disabled="busy"
          infinite-scroll-distance="10" :headers="headers" :items="users" :loading="loading" hide-default-footer
          :items-per-page="users.length + 10">
          <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>

          <template ></template>
          <template v-slot:[`item.price`]="{ item }">
            {{ item.price }} €
          </template>

          <template v-slot:[`item.in_event`]="{ item }">
            <v-icon v-if="item.in_event" color="green darken-2">mdi-check</v-icon>
            <v-icon v-else color="red darken-2">mdi-close</v-icon>
          </template>

          <template v-slot:[`item.invoice`]="{ item }">
            <v-icon v-if="item.invoice" color="green darken-2">mdi-check</v-icon>
            <v-icon v-else color="red darken-2">mdi-close</v-icon>
          </template>

          <template v-slot:[`item.automatically_create_covenant_client`]="{ item }">
            <v-icon v-if="item.automatically_create_covenant_client" color="green darken-2">mdi-check</v-icon>
            <v-icon v-else color="red darken-2">mdi-close</v-icon>
          </template>

          <template v-slot:[`item.client_one_time_event`]="{ item }">
            <v-icon v-if="item.client_one_time_event" color="green darken-2">mdi-check</v-icon>
            <v-icon v-else color="red darken-2">mdi-close</v-icon>
          </template>

          <template v-slot:[`item.action`]="{ item }">
            <td class="justify-center layout px-0">

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="addEditItem(item)" v-if="$store.state.user.typeevents_crud_module === true">
                    <v-icon v-on="on" color="teal">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="deleteItem(item)" v-if="$store.state.user.typeevents_crud_module === true && item.externalID === null">
                    <v-icon v-on="on" color="pink">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="openPlans(item)" v-if="$store.state.user.typeevents_crud_module === true">
                    <v-icon v-on="on" color="orange">mdi-floor-plan</v-icon>
                  </v-btn>
                </template>
                <span>Adicionar plano</span>
              </v-tooltip>
            </td>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="editDialog" ref="test" scrollable width="600" transition="dialog-bottom-transition">
      <add-edit-user ref="qwerty" :erpativo="erpativo" :typeevent="editedItem" @submited="submitedData" @close="close"></add-edit-user>
    </v-dialog>

    <plans ref="plans" />

    <v-dialog v-model="filterDialog" persistent max-width="600px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Filtros :</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="filterDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field label="Nome" clearable v-model="name"></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="blue darken-1" text @click="Filter()">Procurar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";
import ServiceTypeevents from "@/services/ServiceTypeevents";
import AddEditUser from "@/components/typeevent/AddEditTypeevent.vue";
import Plans from "@/components/plan/Plans.vue";
import ServiceArtigos from "@/serviceGgest/ServiceArtigos";
import ServiceLogin from "@/serviceGgest/ServiceLogin";
import _ from "lodash";
export default {
  name: "List-Typeevents",
  directives: {
    infiniteScroll
  },
  components: {
    AddEditUser,
    Plans
  },
  data() {
    return {
      editDialog: false,
      editedIndex: -1,
      editedItem: {
      },
      defaultItem: {
      },
      value: "",
      fileName: "",
      filterDialog: false,
      fab: false,
      page: 1,
      headers: [
        { text: "Código GGest", value: "externalID", sortable: true },
        { text: "Descrição GGest", value: "article_description_erp", sortable: true },
        { text: "Referência", value: "reference", sortable: true },
        { text: "Nome", value: "name", sortable: true },
        { text: "Preço", value: "price", sortable: true },
        { text: "Duração", value: "duration", sortable: true, align: "center"  },
        { text: "Aparecer na marcação", value: "in_event", sortable: true, align: "center"  },
        { text: "Serviço para faturar", value: "invoice", sortable: true, align: "center"  },
        { text: "Criar avença na ficha automaticamente", value: "automatically_create_covenant_client", sortable: true, align: "center"  },
        { text: "Só existe uma marcação com este serviço por cliente", value: "client_one_time_event", sortable: true, align: "center"  },
        { text: "Ações", value: "action", sortable: true, align: "center" }
      ],
      users: [],
      test: [],
      busy: false,
      loading: true,
      name: null,
      login: null,
      externalID: null,
      artigosggest: [],
      erpativo: false
    };
  },
  methods: {
    async getUsers() {
      this.busy = true;
      this.loading = true;
      const response = await ServiceTypeevents.list(
        this.page,
        this.name
      );

      this.users = this.users.concat(response.data);

      this.page++;
      this.busy = false;
      if (response.data.length == 0) {
        this.busy = true;
      }
      this.loading = false;
    },
    async deleteItem(item) {
      if (confirm("Confirma a eliminação deste elemento?")) {
        try {
          const index = this.users.indexOf(item);
          await ServiceTypeevents.delete(item.id);
          this.users.splice(index, 1);
          this.$store.dispatch("setSnackbar", {});
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    async addEditItem(item) {
      try {
        if (this.$store.state.configerp.configuracoeserpestado == true && item != undefined) {
          this.erpativo = true
        } else {
          this.erpativo = false
        }

        if (item) {
          this.editedIndex = this.users.indexOf(item);
          this.editedItem = Object.assign({}, item);
        } else {
          this.editedIndex = -1;
          this.editedItem = Object.assign({}, this.defaultItem);
        }

        this.editDialog = true;
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    submitedData(item) {
      //quando é update
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], item);
      } else {
        //quando é a criar
        this.users.unshift(item);
      }
    },
    openPlans(item) {
      this.$refs.plans.open(item);
    },
    Filter() {
      this.users = [];
      this.page = 1;
      this.filterDialog = false;
      this.getUsers();
    },
    close() {
      this.editDialog = false;
    },
    async importarArtigosErp () {
      this.users = [];
      this.page = 1;
      this.loading = true
      if (this.$store.state.configerp.configuracoeserptipo == 'ggest') {
        const response = await ServiceLogin.login(process.env.VUE_APP_API_USER_GGEST, process.env.VUE_APP_API_PASS_GGEST);
        this.$store.dispatch("setTokenGgest", response.data.token);
        this.artigosggest = await ServiceArtigos.listarArtigos()
        for (let i = 0; i < this.artigosggest.data.dados.length; i++) {
          const tiposervicoexiste = (await ServiceTypeevents.showExternal(this.artigosggest.data.dados[i].codigo)).data;

          const criarTiposervico = {
            externalID: this.artigosggest.data.dados[i].codigo,
            article_description_erp: this.artigosggest.data.dados[i].descricao,
            reference: this.artigosggest.data.dados[i].codigo,
            name: this.artigosggest.data.dados[i].descricao,
            price: this.artigosggest.data.dados[i].pvpiva1,
            in_event: true,
            invoice: true,
            automatically_create_covenant_client: false,
            type_covenant: null,
            client_one_time_event: false,
            duration: null,
          }
          if (tiposervicoexiste.name === undefined) {
            await ServiceTypeevents.createUpdate(criarTiposervico);
          } else {
            criarTiposervico.id = tiposervicoexiste.id
            await ServiceTypeevents.createUpdate(criarTiposervico);
          }
          criarTiposervico.id = null
        }
      }

      this.getUsers();

    }
  },
  async mounted() {
    /*Se o funcionário não tiver permissões de entrar nos tipos de serviço */
    if (this.$store.state.user.typeevents_module != true) {
      this.$router.push(this.$route.query.redirect || "home");
    }
  },
  created() {
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {

      if (this.$refs.plans) {

        if (this.$refs.plans.$refs.eventDialog) {
          if (this.$refs.plans.$refs.eventDialog.dialog) {
            if (this.$refs.plans.$refs.eventDialog.navGuards()) {
              next(false);
              return;
            }
          }
        }

        if (this.$refs.plans.show) {
          this.$refs.plans.show = false;
          next(false);
          return;
        }
      }

      if (this.editDialog) {
        this.editDialog = false;
        next(false);
        return;
      }

      if (this.filterDialog) {
        this.filterDialog = false;
        next(false);
        return;
      }

      next();
    });

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*bush fix the icon was pushed to the top*/
.btn--floating .icon {
  height: 50%;
}
</style>
