<template>
  <v-container v-if="$store.state.user.rooms_module === true">
    <v-speed-dial fab fixed v-model="fab" :bottom="true" :right="true" direction="top" :open-on-hover="false">
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark large fab>
          <v-icon v-if="fab">mdi-close</v-icon>
          <v-icon v-else>mdi-filter-variant</v-icon>
        </v-btn>
      </template>
      <v-btn fab dark large color="green" @click.stop="filterDialog = true">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
      <v-btn fab dark large color="indigo" @click="addEditItem()" v-if="$store.state.user.crm_crud_module === true">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-speed-dial>

    <v-row align="center" justify="center">
      <v-col cols="12" md="10">
        <v-data-table class="mTable" id="mytable" v-infinite-scroll="getUsers" infinite-scroll-disabled="busy"
          infinite-scroll-distance="10" :headers="headers" :items="users" :loading="loading" hide-default-footer
          :items-per-page="users.length + 10">
          <v-progress-linear slot="progress" color="blue" indeterminate></v-progress-linear>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ formatDate(item.createdAt) }}
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <v-icon :color="getStatus(item.status).color" small class="pr-2">mdi-circle</v-icon>
            {{ getStatus(item.status).text + ' (' + item.status_User.name + ')' }}
          </template>

          <template v-slot:[`item.find`]="{ item }">
            <spa v-if="item.find != null">{{ getFinds(item.find).text }}</spa>
          </template>

          <template v-slot:[`item.action`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="addEditItem(item)" v-if="$store.state.user.crm_crud_module === true">
                    <v-icon v-on="on" color="teal">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Editar</span>
              </v-tooltip>

              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="deleteItem(item)" v-if="$store.state.user.crm_crud_module === true">
                    <v-icon v-on="on" color="pink">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>Eliminar</span>
              </v-tooltip>

              <v-tooltip top v-if="item.status == 'aguardandoinscricao'">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="mx-0" @click="adicionarCliente(item)" v-if="$store.state.user.crm_crud_module === true">
                    <v-icon v-on="on" color="orange">mdi-account-tie</v-icon>
                  </v-btn>
                </template>
                <span>Adicionar {{clientTypeS}}</span>
              </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-model="editDialog" ref="test" scrollable width="1500" transition="dialog-bottom-transition">
      <add-edit-user ref="qwerty" :crm="editedItem" @submited="submitedData" @close="close"></add-edit-user>
    </v-dialog>

    <add-edit-client ref="addEditClient" @submited="submitedData" />

    <v-dialog v-model="filterDialog" max-width="600px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Filtros :</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon dark @click="filterDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field label="Nome" clearable v-model="name"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field type="number" label="Telemóvel" clearable v-model="phone"></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-select v-model="status" :items="clientcrmstatus" clearable label="Estado" item-text="text" item-value="value"></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-select v-model="gender" :items="types_gender" clearable label="Género" item-text="text" item-value="value"></v-select>
              </v-col>
              <v-col cols="12" md="6">
                <v-autocomplete label="Tipo de serviço" clearable v-model="typeeventid" :items="typeevents" item-text="name" item-value="id"></v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div class="flex-grow-1"></div>
          <v-btn color="blue darken-1" text @click="Filter()">Procurar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import infiniteScroll from "vue-infinite-scroll";
import ServiceCrms from "@/services/ServiceCrms";
import ServiceTypeevents from "@/services/ServiceTypeevents";
import AddEditUser from "@/components/crm/AddEditCrm.vue";
import AddEditClient from "@/components/common/AddEditClient.vue";
import _ from "lodash";
export default {
  name: "List-Crms",
  directives: {
    infiniteScroll
  },
  components: {
    AddEditUser,
    AddEditClient
  },
  data() {
    return {
      editDialog: false,
      editedIndex: -1,
      editedItem: {
      },
      defaultItem: {
      },
      value: "",
      fileName: "",
      filterDialog: false,
      fab: false,
      clientTypeS: '',
      page: 1,
      headers: [
        { text: "Data", value: "createdAt", sortable: true },
        { text: "Responsável", value: "User.name", sortable: true },
        { text: "Nome", value: "name", sortable: true },
        { text: "Como nos conheceu", value: "find", sortable: true },
        { text: "Telemóvel", value: "phone", sortable: true },
        { text: "Tipo de serviço", value: "Typeevent.name", sortable: true },
        { text: "Plano", value: "Plan.name", sortable: true },
        { text: "Observações", value: "observations", sortable: true },
        { text: "Estado", value: "status", sortable: true },
        { text: "Motivo da não inscrição", value: "status_not_register", sortable: true },
        { text: "", value: "action", sortable: true, align: "center" }
      ],
      types_gender: [
        {text: 'Masculino', value: 'masculino'},
        {text: 'Feminino', value: 'feminino'},
        {text: 'Empresa', value: 'empresa'},
        {text: 'Sem género', value: 'semgenero'},
      ],
      users: [],
      swatches: [],
      clientcrmstatus: [],
      clientcrmsfinds: [],
      typeevents: [],
      test: [],
      busy: false,
      loading: true,
      name: null,
      status: null,
      gender: null,
      phone: null,
      typeeventid: null
    };
  },
  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();

      return `${day}-${month}-${year}`;
    },
    getStatus(statusValue) {
      const foundStatus = this.clientcrmstatus.find(statu => statu.value === statusValue);
      return foundStatus || null;
    },
    getFinds(findsValue) {
      const foundStatus = this.clientcrmsfinds.find(statu => statu.value === findsValue);
      return foundStatus || null;
    },
    async getUsers() {
      this.busy = true;
      this.loading = true;
      const response = await ServiceCrms.list(
        this.page,
        this.name,
        this.status,
        this.gender,
        this.phone,
        this.typeeventid
      );

      this.users = this.users.concat(response.data);

      this.page++;
      this.busy = false;
      if (response.data.length == 0) {
        this.busy = true;
      }
      this.loading = false;
    },
    async deleteItem(item) {
      if (confirm("Confirma a eliminação deste elemento?")) {
        try {
          const index = this.users.indexOf(item);
          await ServiceCrms.delete(item.id);
          this.users.splice(index, 1);
          this.$store.dispatch("setSnackbar", {});
        } catch (error) {
          this.$store.dispatch("setSnackbar", {
            color: "error",
            text: error
          });
        }
      }
    },
    async addEditItem(item) {
      try {

        if (item) {
          this.editedIndex = this.users.indexOf(item);
          this.editedItem = Object.assign({}, item);
        } else {
          this.editedIndex = -1;
          this.editedItem = Object.assign({}, this.defaultItem);
        }
        this.editDialog = true;

      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    submitedData(item) {
      if (this.editedIndex > -1) {
        Object.assign(this.users[this.editedIndex], item);
      } else {
        this.users.unshift(item);
      }

      for (let i = 0; i < this.swatches.length; i++) {

        const index = _.findIndex(this.swatches[i], function (o) {
          return o == item.color;
        });

        if (index != -1) {
          this.swatches[i].splice(index, 1);
          break;
        }
      }
      this.users = [];
      this.page = 1;
      this.getUsers();
    },
    adicionarCliente(item) {
      try {
        this.$refs.addEditClient.openCrm(item);
      } catch (error) {
        this.$store.dispatch("setSnackbar", {
          color: "error",
          text: error
        });
      }
    },
    Filter() {
      this.users = [];
      this.page = 1;
      this.filterDialog = false;
      this.getUsers();
    },
    close() {
      this.editDialog = false;
    },
  },
  async mounted() {
    /*Se o funcionário não tiver permissões de entrar no crm */
    if (this.$store.state.user.crm_module != true) {
      this.$router.push(this.$route.query.redirect || "home");
    }
    this.clientTypeS = config.CLIENT_TYPE_SINGULAR;
    this.clientcrmstatus = config.CLIENT_CRM_STATUS;
    this.clientcrmsfinds = config.CLIENT_FIND;
    this.typeevents = (await ServiceTypeevents.listartodos(1)).data;
  },
  created() {
    const unregisterRouterGuard = this.$router.beforeEach((to, from, next) => {

      if (this.$refs.addEditClient) {
        if (this.$refs.addEditClient.show) {
          this.$refs.addEditClient.show = false;
          next(false);
          return;
        }
      }

      if (this.editDialog) {
        this.editDialog = false;
        next(false);
        return;
      }

      if (this.filterDialog) {
        this.filterDialog = false;
        next(false);
        return;
      }

      next();
    });

    this.$once('hook:destroyed', () => {
      unregisterRouterGuard()
    });
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
/*bush fix the icon was pushed to the top*/
.btn--floating .icon {
  height: 50%;
}
</style>
